import {gql} from "apollo-boost";
import {Mutation} from "react-apollo";
import {linkAttachment, linkAttachmentVariables} from "./types/linkAttachment";

export default gql` 
  mutation linkAttachment($id: ID!, $ticketId: ID!) { 
    linkAttachment(id: $id, ticketId: $ticketId) {    
      id
    }
}
`

export class LinkAttachmentMutation extends Mutation<linkAttachment, linkAttachmentVariables> {}
