import * as React from "react";
import __ from "lodash";
import styled from "styled-components";
import { IBaseProps } from "../../../utils/BaseProps";
import { withTranslation } from "react-i18next";
import "url-search-params-polyfill";
import { FieldTextStateless } from "@atlaskit/field-text";
import Button from "@atlaskit/button";
import SearchIcon from "@atlaskit/icon/glyph/search";
import { CardFrame } from "@atlaskit/media-ui";
import { Grid, GridColumn } from "@atlaskit/page";
import {
  IOenormOption,
  IMaterialOption,
  IDivisionOption,
} from "../../../api/graphql/interfaces/OptionsInterfaces";
import { PartnerFragment as IPartner } from "../../../api/graphql/fragments/types/PartnerFragment";
import EditSelect, { IEditSelectOption } from "../tickets/fields/EditSelect";
import { toSelectOption as oenormsToSelectOption } from "../../../api/graphql/fragments/Oenorm";
import { toSelectOption as materialsToSelectOption } from "../../../api/graphql/fragments/Material";
import { Field } from "@atlaskit/form";
import Select from "@atlaskit/select";
import searchTicketsQuery, {
  SearchTicketsQuery,
} from "../../../api/graphql/queries/ticketSearch";
import Spinner from "@atlaskit/spinner";
import { toast } from "react-toastify";
import { Tickets_tickets } from "../../../api/graphql/queries/types/Tickets";
import { TicketList } from "../tickets/partials/TicketList";
import PartnerSelect from "../tickets/fields/PartnerSelect";
import { ButtonGroup } from "@atlaskit/button";
import PageHeader from "@atlaskit/page-header";
import { UnwrapGrid } from "../create-ticket/CreateTicketForm";

interface ITicketSearchFormProps extends IBaseProps {
  oenorms: IOenormOption[];
  materials: IMaterialOption[];
  divisions: IDivisionOption[];
}

interface ITicketSearchFormState {
  freeText: string | undefined;
  selectedOenorm?: IOenormOption | null;
  selectedMaterial?: IMaterialOption | null;
  selectedDivision?: IDivisionOption | null;
  selectedPartner?: IPartner | null;
  searchTermVariables: string[];
  searchMaterialId?: string | null;
  searchOenormId?: string | null;
  searchPartnerId?: string | null;
  searchDivisionId?: string | null;
}

type ITicketSavedSearchState = Pick<
  ITicketSearchFormState,
  | "searchTermVariables"
  | "searchMaterialId"
  | "searchOenormId"
  | "selectedPartner"
  | "searchDivisionId"
  | "freeText"
>;

const FreeTextField = styled.div`
  margin-top: -10px;

  & input {
    height: 45px;
  }
`;

class TicketSearchForm extends React.Component<
  ITicketSearchFormProps,
  ITicketSearchFormState
> {
  constructor(props: ITicketSearchFormProps) {
    super(props);
    this.state = {
      freeText: "",
      searchTermVariables: [],
    };
  }

  private selectOenorm = (name: string, selected?: IEditSelectOption) => {
    const oenorm = selected
      ? this.props.oenorms.find((o) => o.value === selected.value)
      : null;
    const { selectedMaterial } = this.state;

    this.setState({
      selectedOenorm: oenorm,
      selectedMaterial:
        selectedMaterial &&
        oenorm &&
        oenorm.id &&
        selectedMaterial.oenormId === oenorm.id
          ? selectedMaterial
          : null,
    });
  };

  private selectMaterial = (name: string, selected?: IEditSelectOption) => {
    const material = selected
      ? this.props.materials.find((m) => m.value === selected.value)
      : null;

    this.setState({
      selectedOenorm: material
        ? this.props.oenorms.find((o) => o.value === material.oenormId)
        : this.state.selectedOenorm,
      selectedMaterial: material,
    });
  };

  private selectDivision = (d: IDivisionOption) => {
    this.setState({
      selectedDivision: d,
    });
  };

  private selectPartner = (p: IPartner | null) => {
    console.log("selectedPartner", p);
    this.setState({
      selectedPartner: p,
    });
  };

  private generateSearchTermVariables = () => {
    const {
      freeText,
      selectedMaterial,
      selectedOenorm,
      selectedDivision,
      selectedPartner,
    } = this.state;
    const searchState = {
      searchTermVariables: freeText ? [freeText] : [],
      searchMaterialId: selectedMaterial && selectedMaterial.id,
      searchOenormId: selectedOenorm && selectedOenorm.id,
      searchDivisionId: selectedDivision && selectedDivision.id,
      searchPartnerId: selectedPartner && selectedPartner.id,
      selectedPartner,
      freeText,
    };
    localStorage.setItem("SEARCH_STATE", JSON.stringify(searchState));
    this.setState(searchState);
  };

  componentDidMount() {
    const searchState = JSON.parse(
      localStorage.getItem("SEARCH_STATE") || "{}"
    ) as ITicketSavedSearchState;
    const { searchMaterialId, searchOenormId, searchDivisionId } = searchState;

    // restore state after navigation
    this.setState({
      ...searchState,
      selectedOenorm: searchOenormId
        ? this.props.oenorms.find((o) => o.id === searchOenormId)
        : null,
      selectedMaterial: searchMaterialId
        ? this.props.materials.find((m) => m.id === searchMaterialId)
        : null,
      selectedDivision: searchDivisionId
        ? this.props.divisions.find((d) => d.id === searchDivisionId)
        : null,
    });
  }

  componentDidUpdate(
    prevProps: Readonly<ITicketSearchFormProps>,
    prevState: Readonly<ITicketSearchFormState>,
    snapshot?: any
  ) {
    // restore state after page reload
    if (
      this.props.oenorms.length > 0 &&
      prevProps.oenorms.length === 0 &&
      this.state.searchOenormId &&
      !this.state.selectedOenorm
    ) {
      this.setState({
        selectedOenorm: this.state.searchOenormId
          ? this.props.oenorms.find((o) => o.id === this.state.searchOenormId)
          : null,
      });
    }

    if (
      this.props.materials.length > 0 &&
      prevProps.materials.length === 0 &&
      this.state.searchMaterialId &&
      !this.state.selectedMaterial
    ) {
      this.setState({
        selectedMaterial: this.state.searchMaterialId
          ? this.props.materials.find(
              (m) => m.id === this.state.searchMaterialId
            )
          : null,
      });
    }

    if (
      this.props.divisions.length > 0 &&
      prevProps.divisions.length === 0 &&
      this.state.searchDivisionId &&
      !this.state.selectedDivision
    ) {
      this.setState({
        selectedDivision: this.state.searchDivisionId
          ? this.props.divisions.find(
              (d) => d.id === this.state.searchDivisionId
            )
          : null,
      });
    }
  }

  public render() {
    const { t, materials, oenorms, divisions } = this.props;
    const {
      selectedMaterial,
      selectedOenorm,
      selectedDivision,
      selectedPartner,
      searchTermVariables,
      freeText,
    } = this.state;

    const isSearchButtonDisabled = !(
      freeText !== "" ||
      !__.isNil(selectedMaterial) ||
      !__.isNil(selectedOenorm) ||
      !__.isNil(selectedDivision) ||
      !__.isNil(selectedPartner)
    );

    return (
      <div>
        <PageHeader
          actions={
            <ButtonGroup>
              <Button
                appearance={"primary"}
                iconBefore={<SearchIcon label="Ticketsuche" size="medium" />}
                onClick={this.generateSearchTermVariables}
                isDisabled={isSearchButtonDisabled}
              >
                Suche
              </Button>
            </ButtonGroup>
          }
        >
          {t("tickets.search.title")}
        </PageHeader>
        <GridColumn medium={12}>
          <CardFrame text={t("filter")}>
            <div style={{ margin: "0px 10px 10px 10px" }}>
              <UnwrapGrid>
                <Grid layout="max-width">
                  <GridColumn medium={8}>
                    <FreeTextField>
                      <FieldTextStateless
                        label={t("freetext")}
                        shouldFitContainer={true}
                        onChange={(event: any) =>
                          this.setState({ freeText: event.target.value })
                        }
                        onKeyUp={(event: any) => {
                          if (event.keyCode === 13) {
                            this.generateSearchTermVariables();
                          }
                        }}
                        value={freeText}
                      />
                    </FreeTextField>
                  </GridColumn>
                  <GridColumn medium={4}>
                    <PartnerSelect
                      value={selectedPartner}
                      onChange={(p: IPartner | null) => {
                        this.selectPartner(p);
                        setTimeout(
                          () => this.generateSearchTermVariables(),
                          150
                        );
                      }}
                      isClearable
                    />
                  </GridColumn>
                  <GridColumn medium={4}>
                    <EditSelect
                      name="oenorm"
                      translation="tickets.create.fields.oenorm"
                      clearable={true}
                      options={oenormsToSelectOption(oenorms)}
                      onChange={(
                        name: string,
                        selected?: IEditSelectOption
                      ) => {
                        this.selectOenorm(name, selected);
                        setTimeout(
                          () => this.generateSearchTermVariables(),
                          150
                        );
                      }}
                      selected={
                        selectedOenorm
                          ? {
                              ...selectedOenorm,
                              title: `${selectedOenorm.code}${
                                selectedOenorm.spezifikation
                                  ? ` ${selectedOenorm.spezifikation}`
                                  : ""
                              }`,
                              additional: selectedOenorm.title || "Unbekannt",
                            }
                          : null
                      }
                    />
                  </GridColumn>
                  <GridColumn medium={4}>
                    <EditSelect
                      name="material"
                      translation="tickets.create.fields.material"
                      clearable={true}
                      options={
                        materialsToSelectOption(materials, oenorms) || []
                      }
                      onChange={(
                        name: string,
                        selected?: IEditSelectOption
                      ) => {
                        this.selectMaterial(name, selected);
                        setTimeout(
                          () => this.generateSearchTermVariables(),
                          150
                        );
                      }}
                      selected={
                        selectedMaterial
                          ? {
                              ...selectedMaterial,
                              title: selectedMaterial.matnr,
                              additional: selectedMaterial.title || "Unbekannt",
                              subtitle: null,
                            }
                          : null
                      }
                    />
                  </GridColumn>
                  <GridColumn medium={4}>
                    <Field
                      name="division"
                      label={t("tickets.create.fields.division.title")}
                    >
                      {(props: any) => (
                        <Select
                          isSearchable={true}
                          options={divisions}
                          isClearable={true}
                          placeholder={t(
                            "tickets.create.fields.division.placeholder"
                          )}
                          name="material"
                          styles={{
                            control: (styles: any) => ({
                              ...styles,
                              height: "53px",
                            }),
                            valueContainer: (styles: any) => ({
                              ...styles,
                              height: "50px",
                            }),
                          }}
                          onChange={(d: IDivisionOption) => {
                            this.selectDivision(d);
                            setTimeout(
                              () => this.generateSearchTermVariables(),
                              150
                            );
                          }}
                          value={selectedDivision}
                        />
                      )}
                    </Field>
                  </GridColumn>
                </Grid>
              </UnwrapGrid>
            </div>
          </CardFrame>
        </GridColumn>

        {((searchTermVariables && searchTermVariables.length > 0) ||
          this.state.searchMaterialId ||
          this.state.searchOenormId ||
          this.state.searchPartnerId ||
          this.state.searchDivisionId) && (
          <SearchTicketsQuery
            query={searchTicketsQuery}
            variables={{
              searchTerms: searchTermVariables,
              materialId: this.state.searchMaterialId,
              oenormId: this.state.searchOenormId,
              partnerId: this.state.searchPartnerId,
              divisionId: this.state.searchDivisionId,
            }}
          >
            {({ data, loading, error }) => {
              if (loading) {
                return (
                  <GridColumn medium={12}>
                    <div
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        display: "flex",
                        marginTop: "4em",
                      }}
                    >
                      <Spinner size={"large"} />
                    </div>
                  </GridColumn>
                );
              } else if (error) {
                toast.error(t("tickets.search.error.failed_to_fetch"));
                this.setState({ searchTermVariables: [] });
                return (
                  <GridColumn medium={12}>
                    <div
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <h2>{t("tickets.search.error.failed_to_fetch")}</h2>
                    </div>
                  </GridColumn>
                );
              } else if (
                data &&
                data.searchTickets &&
                data.searchTickets.length > 0
              ) {
                return (
                  <div>
                    <GridColumn medium={12}>
                      <h1>{t("tickets.search.results")}</h1>
                    </GridColumn>
                    <TicketList
                      tickets={data.searchTickets as Tickets_tickets[]}
                      error={!!error}
                      loading={loading}
                    />
                  </div>
                );
              } else {
                return (
                  <GridColumn medium={12}>
                    <div
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <h2>{t("tickets.search.error.no_tickets_found")}</h2>
                    </div>
                  </GridColumn>
                );
              }
            }}
          </SearchTicketsQuery>
        )}
      </div>
    );
  }
}

export default withTranslation()(TicketSearchForm);
