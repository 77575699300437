import { useTranslation } from "react-i18next";

interface IOrderTableHeadingProps {
  name: string;
}

export const OrderTableHeading: React.VFC<IOrderTableHeadingProps> = (
  props
) => {
  const { name } = props;
  const { t } = useTranslation();

  return <>{t(name)}</>;
};
