import * as React from "react"
import {IBaseProps} from "../../../utils/BaseProps"
import {withTranslation} from "react-i18next";
import loadMasterData, {MasterDataQuery} from "../../../api/graphql/queries/loadMasterData";
import CreateTicketForm from "./CreateTicketForm";
import {isNotNullOrUndefined} from "../../../utils/isNotNullOrUndefined";
import {MasterData} from "../../../api/graphql/queries/types/MasterData";



interface ICreateTicketProps extends IBaseProps {

}

interface ICreateTicketState {

}


class CreateTicket extends React.Component<ICreateTicketProps, ICreateTicketState> {

  constructor(props: ICreateTicketProps) {
    super(props);

    this.state = {}
  }


  private renderForm = (data: MasterData) => {
    const oenorms = data.oenorms && data.oenorms.filter(isNotNullOrUndefined) || []
    const materials = data.materials && data.materials.filter(isNotNullOrUndefined) || []
    const divisions = data.divisions && data.divisions.filter(isNotNullOrUndefined) || []

    const oenormOptions = oenorms.map(o => {
      return {
        ...o,
        label: `${o.code} - ${o.title}`,
        value: o.id
      }
    })

    const materialOptions = materials.map(m => {
      const oenorm = oenorms.find(o => o.id === m.oenormId)
      return {
        ...m,
        label: `${m.matnr} - ${m.title}`,
        value: m.id,
        subtitle: oenorm ? `${oenorm.code}${oenorm.spezifikation ? ` ${oenorm.spezifikation}` : ""} - ${oenorm.title}` : null
      }
    })

    const divisionOptions = divisions.map(d => {
      return {
        ...d,
        label: `${d.title}`,
        value: d.id
      }
    })

    return <CreateTicketForm
      oenorms={oenormOptions}
      materials={materialOptions}
      divisions={divisionOptions}
    />
  }

  public render() {

    return (
      <MasterDataQuery
        query={loadMasterData}
        fetchPolicy={'cache-first'}
      >
        {({data}) => (data && this.renderForm(data))}
      </MasterDataQuery>
    )
  }
}

export default withTranslation()(CreateTicket)
