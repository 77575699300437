import * as React from "react"
import __ from "lodash"
import { withTranslation } from "react-i18next"
import { Grid, GridColumn } from "@atlaskit/page"
import { CardFrame } from "@atlaskit/media-ui"
import CardFrameContent from "../../../../partials/CardFrameContent"
import { IBaseProps } from "../../../../../utils/BaseProps"
import { Ticket_ticket_biogenWaste as Specifics } from "../../../../../api/graphql/queries/types/Ticket"
import Headline from "../../partials/Headline"
import ShowText from "../../fields/ShowText"
import ShowBoolean from "../../fields/ShowBoolean"

interface IBiogenWasteProps extends IBaseProps {
  specifics: Specifics
}

interface IBiogenWasteState {

}

class BiogenWaste extends React.Component<IBiogenWasteProps, IBiogenWasteState> {
  state: IBiogenWasteState

  constructor(props: IBiogenWasteProps) {
    super(props)

    this.state = {
    }
  }

  private renderMaterialDescription() {
    const { t, specifics } = this.props
    return (
      <React.Fragment>
        <GridColumn medium={12}>
          <Headline>{t('material_description')}</Headline>
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="consistency" translation="tickets.fields.material_description.consistency" value={specifics.consistency} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="consistency_additional" translation="tickets.fields.material_description.consistency_additional" value={specifics.consistencyAdditional} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="dry_substance" translation="tickets.fields.material_description.dry_substance" value={specifics.drySubstance} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowBoolean name="analysis" translation="tickets.fields.material_description.analysis" value={specifics.isAnalyticsAvailable} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="pickup" translation="tickets.fields.material_description.pickup" value={t(`pickup.code.${specifics.pickup}`)} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="quantity_determination" translation="tickets.fields.material_description.quantity_determination" value={t(`quantity_determination.code.${specifics.quantityDetermination}`)} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="quality_control" translation="tickets.fields.material_description.quality_control" value={t(`quality_control.code.${specifics.qualityControl}`)} />
        </GridColumn>
      </React.Fragment>
    )
  }

  public render() {
    const { t, specifics } = this.props
    console.log(specifics)
    return (
      <CardFrame text={t('tickets.create.fields.deponierbare_abfaelle.title')}>
        <CardFrameContent>
          <Grid layout="max-width">
            <GridColumn medium={12}>
              <Headline>{t('generic')}</Headline>
            </GridColumn>
            <GridColumn medium={6}>
              <ShowText name="waste_description" translation="tickets.create.fields.waste_description" value={specifics.description} />
            </GridColumn>
            {this.renderMaterialDescription()}
          </Grid>
        </CardFrameContent>
      </CardFrame>
    )
  }
}

export default withTranslation()(BiogenWaste)
