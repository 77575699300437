import {gql} from "apollo-boost";
import {Mutation} from "react-apollo";
import {linkAttachment, linkAttachmentVariables} from "./types/linkAttachment";

export default gql` 
  mutation sendPasswordChangeMail($email: String!) { 
    sendPasswordChangeMail(email:$email) {    
      error
      message
    }
}
`

export class SendPasswordChangeMailMutation extends Mutation<linkAttachment, linkAttachmentVariables> {}