import * as React from "react"
import __ from "lodash"
import { withTranslation } from "react-i18next"
import { Grid, GridColumn } from "@atlaskit/page"
import { CardFrame } from "@atlaskit/media-ui"
import CardFrameContent from "../../../../partials/CardFrameContent"
import { IBaseProps } from "../../../../../utils/BaseProps"
import { Ticket_ticket_metalWaste as Specifics } from "../../../../../api/graphql/queries/types/Ticket"
import Headline from "../../partials/Headline"
import ShowText from "../../fields/ShowText"
import ShowBoolean from "../../fields/ShowBoolean"

interface IMetalWasteProps extends IBaseProps {
  specifics: Specifics
}
  
interface IMetalWasteState {
    
}
  
class MetalWaste extends React.Component<IMetalWasteProps, IMetalWasteState> {
  state: IMetalWasteState
  
  constructor(props: IMetalWasteProps) {
    super(props)

    this.state = {
    }
  }
  
  private renderCompanyAndProcess() {
    const { t, specifics } = this.props
    return (
      <React.Fragment>
        <GridColumn medium={12}>
          <Headline>{t('company_process')}</Headline>
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="production" translation="tickets.fields.company_process.production" value={specifics.production} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="process" translation="tickets.fields.company_process.process" value={specifics.process} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="usage" translation="tickets.fields.company_process.usage" value={specifics.usage} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowBoolean name="container_available" translation="tickets.fields.company_process.container_available" value={specifics.isContainerAvailable} />
        </GridColumn>
      </React.Fragment>
    )
  }
  
  private renderMaterialDescription() {
    const { t, specifics } = this.props
    return (
      <React.Fragment>
        <GridColumn medium={12}>
          <Headline>{t('material_description')}</Headline>
        </GridColumn>
        <GridColumn medium={6}>
          <ShowBoolean name="isAnalyticsAvailable" translation="tickets.fields.material_description.analysis" value={specifics.isAnalyticsAvailable} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="isAnalyticsAvailableAdditional" translation="tickets.fields.material_description.analysis_additional" value={specifics.isAnalyticsAvailableAdditional} />
        </GridColumn>
        <GridColumn medium={12}>
          <ShowText name="size" translation="tickets.fields.material_description.size" value={specifics.size} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowBoolean name="foreign_substance" translation="tickets.fields.material_description.foreign_substance" value={specifics.hasForeignSubstance} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="foreign_substance_additional" translation="tickets.fields.material_description.foreign_substance_additional" value={specifics.hasForeignSubstanceAdditional} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowBoolean name="licensed" translation="tickets.fields.material_description.licensed" value={specifics.isLicensed} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="licensed_additional" translation="tickets.fields.material_description.licensed_additional" value={specifics.isLicensedAdditional} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowBoolean name="is_indexed" translation="tickets.fields.material_description.is_indexed" value={specifics.isIndexed} />
        </GridColumn>
      </React.Fragment>
    )
  }

  private renderAdditional() {
    const { t, specifics } = this.props
    return (
      <React.Fragment>
        <GridColumn medium={12}>
          <Headline>{t('additional')}</Headline>
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="comment" translation="tickets.fields.material_description.comment" value={specifics.comment} />
        </GridColumn>
      </React.Fragment>
    )
  }

  public render() {
    const { t, specifics } = this.props
    console.log(specifics)
    return (
      <CardFrame text={t('tickets.create.fields.metalle.title')}>
        <CardFrameContent>
          <Grid layout="max-width">
            <GridColumn medium={12}>
              <Headline>{t('generic')}</Headline>
            </GridColumn>
            <GridColumn medium={6}>
              <ShowText name="waste_description" translation="tickets.create.fields.waste_description" value={specifics.description} />
            </GridColumn>
            {this.renderCompanyAndProcess()}
            {this.renderMaterialDescription()}
            {this.renderAdditional()}
          </Grid>
        </CardFrameContent>
      </CardFrame>
    )
  }
}

export default withTranslation()(MetalWaste)
