import { gql } from "apollo-boost";
import { Mutation } from "react-apollo";
import { assignRole, assignRoleVariables } from "./types/assignRole";

export default gql` 
  mutation assignRole($ticketId: ID!, $role: Role!, $shouldRequestMaterialAnalysis: Boolean, $statusMaterialProbeAnalysis: MaterialProbeStatus, $userId: ID) {
    assignRole(ticketId: $ticketId, role: $role, shouldRequestMaterialAnalysis: $shouldRequestMaterialAnalysis, statusMaterialProbeAnalysis: $statusMaterialProbeAnalysis, userId: $userId) {
      id
    }
  }
`

export class AssignRoleMutation extends Mutation<assignRole, assignRoleVariables> {}
