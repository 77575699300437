import { gql } from "apollo-boost"
import { Query } from "react-apollo"
import {Ticket, Ticket_ticket_comments} from "./types/Ticket"
import FullTicketFragment from "../fragments/FullTicket"
import PlantFragment from "../fragments/Plant"

export default gql`
  query Ticket($id: ID!) { 
    ticket(id: $id) {
      ...FullTicketFragment
    }
    plants {
      ...PlantFragment
    }
  }

  ${FullTicketFragment}
  ${PlantFragment}
`


export type Comment = Ticket_ticket_comments
export class TicketQuery extends Query<Ticket> {
}
