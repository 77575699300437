import { gql } from "apollo-boost"
import { Query } from "react-apollo"
import { MetaData } from "./types/MetaData"
import DivisionFragment from "../fragments/Division"
import OenormFragment from "../fragments/Oenorm"
import { MaterialFragmentWithPks as MaterialFragment } from "../fragments/Material"

export default gql`
  query MetaData { 
    materials { 
      ...MaterialFragment
    }
    
    oenorms { 
      ...OenormFragment
    }
    
    divisions {
      ...DivisionFragment
    }
  }

  ${MaterialFragment}
  ${OenormFragment}
  ${DivisionFragment}
`

export class MetaDataQuery extends Query<MetaData> {
}
