import React, { FunctionComponent } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "../utils/i18n";
import PageLayout from "./layout/PageLayout";
import { AuthConsumer } from "../context/AuthContext";
import { LoginPage } from "./pages/auth/Login";
import LogoutPage from "./pages/auth/Logout";
import CreateTicketPage from "./pages/create-ticket/CreateTicket";
import TicketsSearchPage from "./pages/search-ticket/TicketSearch";
import { TicketListPage } from "./pages/tickets/TicketListPage";
import { TicketDetailContent } from "./pages/tickets/TicketDetailContent";
import { TicketAssignToMePage } from "./pages/tickets/TicketAssignToMe";
import { RequestPasswordChangePage } from "./pages/auth/RequestPasswordChangePage";
import { Dashboard } from "./pages/dashboard/Dashboard";
import queryString from "querystring";
import { CreateClaimPage } from "./pages/create-claim/CreateClaimPage";
import { ClaimListPage } from "./pages/claims/ClaimListPage";
import { EditClaimPage } from "./pages/edit-claim/EditClaimPage";
import { ToastContainer } from "react-toastify";

interface IRouterProps {}

export const Router: FunctionComponent<IRouterProps> = (props) => {
  const parsed = queryString.parse(window.location.search);
  const location = useLocation();

  const { pathname } = location;
  const isTicketFrom = pathname.startsWith("/tickets/open");
  const isLoginPage = pathname.startsWith("/login");
  const isChangePasswordPage = pathname.startsWith("/change-password");
  const isStatusPage = pathname.startsWith("/status");

  return (
    <I18nextProvider i18n={i18n}>
      <ToastContainer />
      <PageLayout>
        <AuthConsumer>
          {({ isAuthenticated }) => (
            <Switch>
              {!isAuthenticated() &&
                !isLoginPage &&
                !isChangePasswordPage &&
                !isStatusPage && (
                  <Redirect
                    exact
                    to={
                      "/login" +
                      (`${location.pathname}${location.search}` && isTicketFrom
                        ? `?pushUrl=${encodeURI(
                            location.pathname + location.search
                          )}`
                        : ``)
                    }
                  />
                )}
              <Route path={"/login"} component={LoginPage} />
              <Route path={"/logout"} component={LogoutPage} />
              <Route path={"/tickets/create"} component={CreateTicketPage} />
              <Route path={"/tickets/search"} component={TicketsSearchPage} />
              <Route path={"/tickets"} exact component={TicketListPage} />
              <Route
                path={`/tickets/open?mat=${parsed[0]}&cust=${parsed[1]}`}
                exact
                component={TicketDetailContent}
              />
              <Route
                path={"/tickets/:id/assignToMe"}
                component={TicketAssignToMePage}
              />
              <Route path={"/tickets/:id"} component={TicketDetailContent} />
              <Route path={"/role/:role"} component={TicketListPage} />
              <Route
                path={"/change-password"}
                render={() => <RequestPasswordChangePage />}
              />
              <Route
                path={"/claims/create"}
                exact
                component={CreateClaimPage}
              />
              <Route path={"/claims/create/:id"} component={CreateClaimPage} />
              <Route path={"/claims/:id"} component={EditClaimPage} />
              <Route path={"/claims"} component={ClaimListPage} />
              <Route path={"/dashboard"} render={() => <Dashboard />} />
              <Redirect to={"/dashboard"} />
            </Switch>
          )}
        </AuthConsumer>
      </PageLayout>
    </I18nextProvider>
  );
};
