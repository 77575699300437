import { gql } from "apollo-boost"
import { Mutation } from "react-apollo"
import { Signin, SigninVariables } from "./types/Signin"

export default gql`
mutation Signin($email: String!, $password: String!) {
  signin(email: $email, password: $password) {
    token
    valid
  }
}
`

export class SigninMutation extends Mutation<Signin, SigninVariables> {}
