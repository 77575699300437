import React, { useState, VFC } from "react";
import { GridColumn } from "@atlaskit/page";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { PhysicalQualityDeviationType } from "../../../api/graphql/graphql-global-types";
import EditSelect, {
  IEditSelectOption,
} from "../../pages/tickets/fields/EditSelect";
import { CustomTextArea } from "../CustomTextArea";
import { CustomTextfield } from "../CustomTextField";
import { FormSection } from "../FormSection";
import { IPhysicalQualitySection } from "./types/claim-types";

interface IPhysicalQualityFormSectionProps {
  section: IPhysicalQualitySection;
  onChange: (section: IPhysicalQualitySection) => void;
  disabled?: boolean;
}

export const PhysicalQualityFormSection: VFC<
  IPhysicalQualityFormSectionProps
> = ({ section, onChange, disabled }) => {
  const { t } = useTranslation();

  const deviationOptions = getDeviationOptions(t);

  const [deviationOption, setDeviationOption] =
    useState<IDeviationOption | null>(
      deviationOptions.find(
        (option) => option.value === section.deviationType
      ) ?? null
    );

  const onChangeDeviationType = (
    name: string,
    selected: IEditSelectOption | null
  ) => {
    const selectedDeviation = selected
      ? {
          label: selected.label,
          value: selected.value,
        }
      : null;
    setDeviationOption(selectedDeviation);

    const value = selected?.value || null;
    const shouldResetDescription = !value || value === "STAUBEND";

    const changedPhysicalQuality: IPhysicalQualitySection = {
      ...section,
      deviationType: value,
      description: shouldResetDescription ? "" : section.description,
    };
    onChange(changedPhysicalQuality);
  };

  const onChangeDescription = (event: any) => {
    const changedPhysicalQuality: IPhysicalQualitySection = {
      ...section,
      description: event?.target?.value ?? "",
    };
    onChange(changedPhysicalQuality);
  };

  const onChangeComment = (event: any) => {
    const changedPhysicalQuality: IPhysicalQualitySection = {
      ...section,
      comment: event?.target?.value ?? "",
    };
    onChange(changedPhysicalQuality);
  };

  const showDescription = () => {
    return section.deviationType !== "STAUBEND" && !!section.deviationType;
  };

  return (
    <FormSection title={t("create_claim.form.physical_quality_section.title")}>
      <GridColumn medium={6}>
        <EditSelect
          name="deviation_type"
          translation="create_claim.form.physical_quality_section.deviation_type"
          options={deviationOptions}
          onChange={onChangeDeviationType}
          selected={deviationOption}
          clearable
          disabled={disabled}
        />
      </GridColumn>
      {showDescription() && (
        <GridColumn medium={6}>
          <CustomTextfield
            name="description"
            translation="create_claim.form.physical_quality_section.description"
            value={section.description}
            onChange={onChangeDescription}
            disabled={disabled}
            required={true}
          />
        </GridColumn>
      )}
      <GridColumn medium={12}>
        <CustomTextArea
          name="comment"
          translation="create_claim.form.physical_quality_section.comment"
          value={section.comment}
          onChange={onChangeComment}
          disabled={disabled}
        />
      </GridColumn>
    </FormSection>
  );
};

interface IDeviationOption {
  label: string;
  value: PhysicalQualityDeviationType;
  subtitle?: string | null;
}

const getDeviationOptions = (t: i18next.TFunction): IDeviationOption[] => {
  return [
    {
      label: t(
        `create_claim.form.physical_quality_section.deviation_options.STOERSTOFFE`
      ),
      value: PhysicalQualityDeviationType.STOERSTOFFE,
    },
    {
      label: t(
        "create_claim.form.physical_quality_section.deviation_options.FEUCHTE"
      ),
      value: PhysicalQualityDeviationType.FEUCHTE,
    },
    {
      label: t(
        "create_claim.form.physical_quality_section.deviation_options.FARBE"
      ),
      value: PhysicalQualityDeviationType.FARBE,
    },
    {
      label: t(
        "create_claim.form.physical_quality_section.deviation_options.STAUBEND"
      ),
      value: PhysicalQualityDeviationType.STAUBEND,
    },
    {
      label: t(
        "create_claim.form.physical_quality_section.deviation_options.KORNGROESSE"
      ),
      value: PhysicalQualityDeviationType.KORNGROESSE,
    },
    {
      label: t(
        "create_claim.form.physical_quality_section.deviation_options.GEWICHT"
      ),
      value: PhysicalQualityDeviationType.GEWICHT,
    },
    {
      label: t(
        "create_claim.form.physical_quality_section.deviation_options.SONSTIGES"
      ),
      value: PhysicalQualityDeviationType.SONSTIGES,
    },
  ];
};
