import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import { claimHistory } from "./types/claimHistory";

export default gql`
  query claimHistory($claimId: ID!) {
    claimHistory(claimId: $claimId) {
      user {
        name
        email
      }
      oldValue
      newValue
      entryType
      createdAt
    }
  }
`;

export class ClaimHistoryQuery extends Query<claimHistory> {}
