import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "./Select";

export interface IUser {
  id: string;
  email: string;
  name: string | null;
}

interface IUserSelectProps {
  value: IUser | undefined;
  options: IUser[];
  onChange: (user: IUser | undefined) => void;
  placeholder?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
}

export const UserSelect: VFC<IUserSelectProps> = (props) => {
  const { t } = useTranslation();
  const { value, options, onChange, placeholder, isDisabled, isClearable } =
    props;

  const userToOption = (user: IUser) => ({
    value: user.id,
    label: user.name || user.email,
    key: user.id,
  });

  return (
    <Select
      value={value}
      options={options}
      toOption={userToOption}
      isClearable={isClearable}
      isDisabled={isDisabled}
      placeholder={placeholder || t("components.user_select.placeholder")}
      onChange={onChange}
    />
  );
};
