import { CardFrame } from "@atlaskit/media-ui";
import { Grid, GridColumn } from "@atlaskit/page";
import styled from "styled-components";

interface IFormSectionProps {
  title: string;
}

const UnwrapGrid = styled.div`
  margin: 0 -8px;
  padding: 0 0 16px 0;

  & > [spacing="cosy"] {
    padding: 0;
    margin: 0;
  }
`;

export const FormSection: React.FC<IFormSectionProps> = (props) => {
  const { children, title } = props;

  return (
    <UnwrapGrid>
      <GridColumn medium={12}>
        <CardFrame text={title}>
          <div style={{ margin: "0px 10px 10px 10px" }}>
            <UnwrapGrid>
              <Grid layout="max-width">{children}</Grid>
            </UnwrapGrid>
          </div>
        </CardFrame>
      </GridColumn>
    </UnwrapGrid>
  );
};
