import {gql} from 'apollo-boost'
import {Query} from "react-apollo";
import {MasterData} from "./types/MasterData";
import DivisionFragment from "../fragments/Division"
import OenormFragment from "../fragments/Oenorm"
import MaterialFragment from "../fragments/Material"

export default gql`
  ${MaterialFragment}
  ${OenormFragment}
  ${DivisionFragment}

  query MasterData{ 
    materials { 
      ...MaterialFragment
    }
    
    oenorms { 
      ...OenormFragment
    }
    
    divisions {
      ...DivisionFragment
    }
  }
`

export class MasterDataQuery extends Query<MasterData> {
}
