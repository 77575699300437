import * as React from "react";
import gql from "graphql-tag";
import ApolloClient from "apollo-client";
import { ApolloConsumer } from "react-apollo";
import { withTranslation } from "react-i18next";
import { Field } from "@atlaskit/form";
import AsyncPaginate from "react-select-async-paginate";
import { IBaseProps } from "../../../../utils/BaseProps";
import { formatPartnerOption } from "../../../../utils/form-helper";
import { PartnerFragment as IPartner } from "../../../../api/graphql/fragments/types/PartnerFragment";

interface IPartnerSelectProps extends IBaseProps {
  value?: IPartner | null;
  onChange: (partner: IPartner | null) => void;
  hideField?: Boolean;
  isClearable?: Boolean;
}

interface IFieldWrapperProps {
  name: String;
  label: String;
  children: any;
}

const loadOptions =
  (client: ApolloClient<any>) =>
  async (inputValue: string, loadedOptions: any) => {
    try {
      const result = await client.query({
        query: gql(`query searchPartners($search: String, $offset: Int) {
        searchPartners(search: $search, offset: $offset) {
          options {
            id
            code
            name
            street
            number
            postal
            city
          }
          hasMore
        }
      }`),
        variables: { search: inputValue, offset: loadedOptions.length },
      });

      return result.data.searchPartners;
    } catch (e) {
      console.log("ERROR", e);
    }
    return;
  };

const FieldWrapper = (props: IFieldWrapperProps) => (
  <Field name={props.name} label={props.label}>
    {() => props.children}
  </Field>
);

export default withTranslation()((props: IPartnerSelectProps) => {
  const [selectedPartner, setSelectedPartner] = React.useState<IPartner | null>(
    null
  );
  const select = (
    <ApolloConsumer>
      {(client) => (
        <AsyncPaginate
          debounceTimeout={250}
          value={props.value || selectedPartner}
          loadOptions={loadOptions(client)}
          placeholder="Suche nach Geschäftspartner ..."
          formatOptionLabel={formatPartnerOption}
          isClearable={!!props.isClearable}
          styles={{
            container: (styles: any) => ({
              ...styles,
              backgroundColor: "#F4F5F7",
              borderColor: "#F4F5F7",
            }),
            control: (styles: any) => ({
              ...styles,
              height: "60px",
              backgroundColor: "#F4F5F7",
              borderColor: "#F4F5F7",
            }),
            placeholder: (styles: any) => ({ ...styles, color: "#7A869A" }),
            valueContainer: (styles: any) => ({ ...styles, height: "60px" }),
            singleValue: (styles: any) => ({
              ...styles,
              width: "calc(100% - 20px)",
            }),
            option: (styles: any, state: any) => {
              return {
                ...styles,
                backgroundColor: state.isFocused
                  ? "rgb(244, 245, 247)"
                  : "inherit",
              };
            },
          }}
          onChange={(partner: IPartner | null) => {
            if (props.onChange) props.onChange(partner);
            setSelectedPartner(partner);
          }}
        />
      )}
    </ApolloConsumer>
  );

  return props.hideField ? (
    select
  ) : (
    <FieldWrapper
      name="partner"
      label={props.t("tickets.create.fields.partner.title")}
    >
      {select}
    </FieldWrapper>
  );
});
