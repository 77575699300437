import { GridColumn } from "@atlaskit/page";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import "url-search-params-polyfill";
//import { Link } from 'react-router-dom';
import Button from "@atlaskit/button";
import { Field } from "@atlaskit/form";
import WatchIcon from "@atlaskit/icon/glyph/watch";
import Lozenge from "@atlaskit/lozenge";
import { Link } from "react-router-dom";
import { Tickets_tickets } from "../../../../api/graphql/queries/types/Tickets";
import Ellipsis from "../../../partials/Ellipsis";
import { ListItem } from "../../../partials/list/ListItem";
import FieldValue from "./FieldValue";

interface ITicketListItemProps {
  ticket: Tickets_tickets;
}

export const TicketListItem: FC<ITicketListItemProps> = (props) => {
  const { t } = useTranslation();

  const { ticket } = props;

  const assignedUser =
    ticket.assignedUser && ticket.assignedUser.name
      ? ` • ${ticket.assignedUser.name}`
      : "";

  return (
    <ListItem
      title={
        t("tickets.detail.sections.ticket.title") +
        " #" +
        ticket.number +
        assignedUser
      }
      variant="white"
    >
      <GridColumn medium={2}>
        <Field
          name="status"
          label={t("tickets.detail.sections.ticket.status.title")}
          helperText={t("tickets.detail.sections.ticket.status.helper")}
        >
          {(props: any) => (
            <FieldValue>
              <Lozenge>
                {t(
                  `tickets.detail.sections.ticket.status.mapping.${ticket.status.toLowerCase()}.title`
                )}
              </Lozenge>
            </FieldValue>
          )}
        </Field>
      </GridColumn>

      <GridColumn medium={2}>
        <Field
          name="partner"
          label={t("tickets.detail.sections.ticket.partner.title")}
          helperText={t("tickets.detail.sections.ticket.partner.helper")}
        >
          {(props: any) => (
            <FieldValue>
              {ticket.general.partner
                ? ticket.general.partner.name
                : ticket.general.partnerName || "-"}
            </FieldValue>
          )}
        </Field>
      </GridColumn>

      <GridColumn medium={2}>
        <Field
          name="oenorm"
          label={t("tickets.detail.sections.ticket.oenorm.title")}
          helperText={t("tickets.detail.sections.ticket.oenorm.helper")}
        >
          {(props: any) => (
            <div style={{ textOverflow: "ellipsis" }}>
              <FieldValue>
                <strong>
                  {ticket.general.oenorm
                    ? `${ticket.general.oenorm.code}${
                        ticket.general.oenorm.spezifikation
                          ? ` ${ticket.general.oenorm.spezifikation}`
                          : ""
                      }`
                    : ""}
                </strong>
              </FieldValue>
              <FieldValue>
                <Ellipsis>
                  {ticket.general.oenorm ? ticket.general.oenorm.title : "-"}
                </Ellipsis>
              </FieldValue>
            </div>
          )}
        </Field>
      </GridColumn>

      <GridColumn medium={2}>
        <Field
          name="material"
          label={t("tickets.detail.sections.ticket.material.title")}
          helperText={t("tickets.detail.sections.ticket.material.helper")}
        >
          {(props: any) => (
            <div>
              <FieldValue>
                <strong>
                  {ticket.general.material ? ticket.general.material.matnr : ""}
                </strong>
              </FieldValue>
              <FieldValue>
                <Ellipsis>
                  {ticket.general.material
                    ? ticket.general.material.title
                    : "-"}
                </Ellipsis>
              </FieldValue>
            </div>
          )}
        </Field>
      </GridColumn>

      <GridColumn medium={2}>
        <Field
          name="division"
          label={t("tickets.detail.sections.ticket.division.title")}
          helperText={t("tickets.detail.sections.ticket.division.helper")}
        >
          {(props: any) => (
            <FieldValue>
              {ticket.general.division ? ticket.general.division.title : ""}
            </FieldValue>
          )}
        </Field>
      </GridColumn>

      <GridColumn medium={2}>
        <Field
          name="link"
          label={" "}
          helperText={t("tickets.detail.sections.ticket.link.helper")}
        >
          {(props: any) => (
            <FieldValue>
              <div style={{ justifyContent: "flex-end", display: "flex" }}>
                <Link to={`/tickets/${ticket.id}`}>
                  <Button>
                    <WatchIcon
                      size={"small"}
                      label={t("tickets.detail.sections.ticket.link.title")}
                    />
                  </Button>
                </Link>
              </div>
            </FieldValue>
          )}
        </Field>
      </GridColumn>
    </ListItem>
  );
};
