import { gql } from "apollo-boost";
import { Mutation } from "react-apollo";
import { cancelTicket, cancelTicketVariables } from "./types/cancelTicket";

export default gql` 
  mutation cancelTicket($id: ID!) {
    cancelTicket(id: $id) {
      id
    }
  }
`

export class CancelTicketMutation extends Mutation<cancelTicket, cancelTicketVariables> {}
