import React, { VFC } from "react";
import TextArea from "@atlaskit/textarea";
import { Field } from "@atlaskit/form";
import { useTranslation } from "react-i18next";

interface ICommentInputProps {
  value: string;
  onChange: (comment: string) => void;
  disabled?: boolean;
}

export const CommentInput: VFC<ICommentInputProps> = (props) => {
  const { t } = useTranslation();
  const { value: comment, onChange, disabled } = props;

  return (
    <Field name="comment" label={t("comment")}>
      {({ fieldProps }: { fieldProps: any }) => (
        <TextArea
          {...fieldProps}
          value={comment}
          onChange={(e) => onChange(e.target.value)}
          isDisabled={disabled}
        />
      )}
    </Field>
  );
};
