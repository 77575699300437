import * as React from "react"
import { IBaseProps } from "../../../../utils/BaseProps"
import { withTranslation } from "react-i18next";
import { Grid, GridColumn } from "@atlaskit/page";
import DescriptionImage, { IDescriptionImageInfoHandler } from "./description-image";
import {UnwrapGrid} from "../CreateTicketForm"

interface IBasicStructureProps extends IBaseProps {
  companyAndProcess?: React.ReactNode
  materialDescription?: React.ReactNode
  additional?: React.ReactNode
  infoHandler: IDescriptionImageInfoHandler
  descriptionRequired: boolean
  imageRequired: boolean
}

interface IBasicStructureState {

}


class BasicStructure extends React.Component<IBasicStructureProps, IBasicStructureState> {

  constructor(props: IBasicStructureProps) {
    super(props);

    this.state = {}
  }

  public render() {
    const { t } = this.props

    return (
      <UnwrapGrid>
        <Grid layout="max-width">
          <GridColumn medium={12}>
            <h3>{t('generic')}</h3>
          </GridColumn>
          <GridColumn medium={12}>
            <DescriptionImage infoHandler={this.props.infoHandler} imageRequired={this.props.imageRequired} descriptionRequired={this.props.descriptionRequired} />
          </GridColumn>

          {this.props.companyAndProcess && (
            <>
              <GridColumn medium={12}>
                <h3>{t('company_process')}</h3>
              </GridColumn>
              <GridColumn medium={12}>
                {
                  this.props.companyAndProcess
                }
              </GridColumn>
            </>
          )}

          {this.props.materialDescription && (
            <>
              <GridColumn medium={12}>
                <h3>{t('material_description')}</h3>
              </GridColumn>
              <GridColumn medium={12}>
                {
                  this.props.materialDescription
                }
              </GridColumn>
            </>
          )}

          {this.props.additional && (
            <>
              <GridColumn medium={12}>
                <h3>{t('additional')}</h3>
              </GridColumn>
              <GridColumn medium={12}>
                {
                  this.props.additional
                }
              </GridColumn>
            </>
          )}
        </Grid>
      </UnwrapGrid>
    )

  }
}

export default withTranslation()(BasicStructure)
