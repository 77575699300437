import { ButtonGroup } from "@atlaskit/button";
import PageHeader from "@atlaskit/page-header";
import Spinner from "@atlaskit/spinner";
import React, { useState } from "react";
import { useQuery } from "react-apollo-hooks";
import { useTranslation } from "react-i18next";
import { Role } from "../../../../../api/graphql/graphql-global-types";
import { PLANTS_QUERY } from "../../../../../api/graphql/queries/plants";
import { AuthConsumer } from "../../../../../context/AuthContext";
import { AssignRoleDialog } from "../../../../partials/assign-role/AssignRoleDialog";
import { AssignRoleDropdown } from "../../../../partials/AssignRoleDropdown";
import {
  EditClaimConsumer,
  EditClaimProvider,
} from "../../../../partials/claim/context/edit-claim-context";
import Highlight from "../../../tickets/partials/Highlight";
import { EditClaimForm } from "./EditClaimForm";
import __ from "lodash";
import { Abilities } from "../../../../../domain/models/abilities";

interface IEditClaimFormPageProps {
  claimNumber: number;
}

export const EditClaimFormPage: React.FC<IEditClaimFormPageProps> = (props) => {
  const { t } = useTranslation();
  const { claimNumber } = props;
  const [isAssignRoleDialogOpen, setIsAssignRoleDialogOpen] = useState(false);

  const { data: plants } = useQuery(PLANTS_QUERY);

  const closeAssignDialog = () => {
    setIsAssignRoleDialogOpen(false);
  };

  return (
    <AuthConsumer>
      {({ hasAbility }) => (
        <EditClaimProvider claimNumber={claimNumber} hasAbility={hasAbility}>
          <EditClaimConsumer>
            {({
              claim,
              assignedRole,
              setAssigneeRole,
              getExcludedAssignRoles,
              isValidUser,
              assigneeRole,
              onSubmitUpdateClaimAndAssign,
              submitLoading,
              createdBy,
            }) => (
              <>
                <AssignRoleDialog
                  isOpen={isAssignRoleDialogOpen}
                  assignTo={assigneeRole}
                  isLoading={submitLoading}
                  onClose={() => setIsAssignRoleDialogOpen(false)}
                  onSubmit={(role, comment, assigneeId) =>
                    onSubmitUpdateClaimAndAssign(
                      role,
                      comment,
                      assigneeId,
                      closeAssignDialog
                    )
                  }
                  isClaim={true}
                  assigneeId={createdBy?.id ?? undefined}
                />
                <PageHeader
                  actions={
                    <div style={{ paddingRight: "32px" }}>
                      <ButtonGroup>
                        <AssignRoleDropdown
                          title={t(
                            `assignee.${
                              assignedRole === Role.LABOR
                                ? "production"
                                : assignedRole?.toLowerCase() || "unassigned"
                            }.title`
                          )}
                          onAssign={(role: Role) => {
                            setAssigneeRole(role);
                            setIsAssignRoleDialogOpen(true);
                          }}
                          isDisabled={
                            claim && !__.isNil(claim.closedAt)
                              ? true
                              : hasAbility(Abilities.ADMIN)
                              ? false
                              : !isValidUser()
                          }
                          excludedRoles={[...getExcludedAssignRoles()]}
                          claim={claim === null ? undefined : claim}
                        />
                      </ButtonGroup>
                    </div>
                  }
                >
                  {__.isNil(claim?.closedAt)
                    ? t("edit_claim.form.title")
                    : t("edit_claim.form.title_closed")}{" "}
                  <Highlight>R#{claimNumber}</Highlight>
                </PageHeader>
              </>
            )}
          </EditClaimConsumer>

          {plants?.plants ? (
            <EditClaimForm plants={plants.plants} />
          ) : (
            <div
              style={{
                flex: 1,
                justifyContent: "center",
                display: "flex",
                marginTop: "4em",
              }}
            >
              <Spinner size={"large"} />
            </div>
          )}
        </EditClaimProvider>
      )}
    </AuthConsumer>
  );
};
