import React from "react"
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import {useTranslation} from "react-i18next";
import {TicketCommentEditor} from "./CommentEditor";
import {titleForRole} from "../../../../utils/presentation/role";
import {Role} from "../../../../api/graphql/graphql-global-types";
import {Abilities} from "../../../../domain/models/abilities";

interface IAssignRoleToTicketDialogProps {
  assignTo: Role
  isOpen: boolean
  ticketId: string
  onCloseDialog: () => void
  onDidSave: (shouldRequestMaterialAnalysis?: Boolean, statusMaterialProbeAnalysis?: String, userId?: String) => Promise<any>
}

export const AssignRoleToTicketDialog: React.FunctionComponent<IAssignRoleToTicketDialogProps> = (props) => {

  const {assignTo, isOpen, ticketId, onCloseDialog, onDidSave} = props
  const {t} = useTranslation()


  return (
    <ModalTransition>
      {isOpen && (
        <Modal heading={`${t('tickets.detail.sections.assignDialog.title')}${titleForRole(assignTo, t)}`}>
          <TicketCommentEditor
            ticketId={ticketId}
            onDidSave={onDidSave}
            saveButtonText={t('tickets.detail.sections.assignDialog.assign')}
            onDidCancel={onCloseDialog}
            shouldCommentOnSubmit
            showMaterialAnalyis={assignTo === Role.VERTRIEB}
            showMaterialProbe={assignTo === Role.LABOR}
            showUserSelection={assignTo === Role.LABOR || assignTo === Role.STOFFSTROM}
            isUserSelectionRequired={assignTo !== Role.STOFFSTROM}
            showUserAbility={assignTo === Role.STOFFSTROM ? Abilities.STOFFSTROM_MANAGER : Abilities.LABOR}
          />
        </Modal>
      )}
    </ModalTransition>
  )
}
