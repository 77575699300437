import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Role } from "../../../api/graphql/graphql-global-types";
import { useUsersQuery } from "../../../api/graphql/queries/users";
import { roleToAbility, titleForRole } from "../../../utils/presentation/role";
import { CommentInput } from "../comment/CommentInput";
import { ConfirmDialog } from "../dialog/ConfirmDialog";
import { IUser, UserSelect } from "../select/UserSelect";
import Spinner from "@atlaskit/spinner";

interface IAssignRoleDialogProps {
  assignTo: Role;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    role: Role,
    comment: string,
    assigneeId: string | undefined
  ) => void;
  isLoading: boolean;
  isClaim?: boolean;
  assigneeId?: string | undefined;
}

export const AssignRoleDialog: React.FunctionComponent<
  IAssignRoleDialogProps
> = (props) => {
  const { t } = useTranslation();
  const {
    assignTo,
    isOpen,
    onSubmit,
    onClose,
    isLoading,
    isClaim,
    assigneeId,
  } = props;
  const [comment, setComment] = useState("");
  const [assignee, setAssignee] = useState<IUser>();

  const { data: users, loading } = useUsersQuery({
    variables: {
      ability: roleToAbility(assignTo),
    },
  });

  useEffect(() => {
    setAssignee(undefined);
  }, [assignTo]);

  useEffect(() => {
    if (users?.users && assignTo === Role.PARTNER_SERVICE) {
      const user = users.users[0];
      if (user) {
        setAssignee(user);
      }
    }
    if (users?.users && assignTo === Role.STOFFSTROM && assigneeId) {
      const user = users.users.find((user) => user?.id === assigneeId);
      if (user) {
        setAssignee(user);
      }
    }
  }, [users]);

  return (
    <ConfirmDialog
      isOpen={isOpen}
      title={t("components.assign_role_dialog.title", {
        role: titleForRole(assignTo, t, isClaim),
      })}
      onClose={onClose}
      onSubmit={() => onSubmit(assignTo, comment, assignee?.id)}
      isLoading={loading || isLoading}
      isDisabled={loading || isLoading}
    >
      <CommentInput value={comment} onChange={setComment} disabled={false} />

      <div style={{ marginBottom: "10px" }}> </div>

      {!loading && users?.users && (
        <UserSelect
          onChange={setAssignee}
          value={assignee}
          options={users.users?.filter((user) => user?.active)}
          placeholder={t(
            "components.assign_role_dialog.user_select_placeholder"
          )}
        />
      )}
    </ConfirmDialog>
  );
};
