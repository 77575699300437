import { gql } from "apollo-boost";
import { QueryHookOptions, useQuery } from "react-apollo-hooks";
import { getClaimByOrderId, getClaimByOrderIdVariables } from "./types/getClaimByOrderId";

export const GET_CLAIM_BY_ORDER_ID_QUERY = gql`
    query getClaimByOrderId($orderId: String!) {
        getClaimByOrderId(orderId: $orderId) {
            number
        }
    }
`;

export const useGetClaimByOrderIdQuery = (
  options: QueryHookOptions<getClaimByOrderIdVariables>
) => useQuery<getClaimByOrderId, getClaimByOrderIdVariables>(
  GET_CLAIM_BY_ORDER_ID_QUERY,
  options
);
