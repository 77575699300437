import styled from "styled-components"

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    min-height: 500px;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    width: 400px;
    color: white;

    &::before {
        content: "";
        flex-shrink: 0;
        height: 48px;
    }
`

export const Background = styled.div`
    background-color: rgb(227, 0, 27);
    overflow: auto;
    height: 100%;

    &::after {
        content: "";
        clear: both;
        display: table;
    }
`

export const Header = styled.header`
    text-align: center;

    h1 {
        margin: 0;
        font-weight: 200;
        font-size: 2em;

        em {
            font-weight: 600;
            font-style: normal;
        }
    }

    img {
        width: 96%;
    }
`

export const Section = styled.section`
    margin-top: 48px;
    margin-right: 0px;
    margin-bottom: 48px;
    margin-left: 0px;

    > div:first-child > h1:first-child {
        text-align: center;
        margin-right: 0;
        font-size: 1em;
    }
`

export const Footer = styled.footer`
    margin-top: auto;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-bottom: 32px;
    text-align: center;
`

export const Card = styled.div`
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.0980392) 0px 10px 10px;
    box-sizing: border-box;
    color: rgb(23, 43, 77);
    padding-top: 38px;
    padding-right: 48px;
    padding-bottom: 48px;
    padding-left: 48px;

    > button {
        margin-top: 24px;
        height: 39px;
        line-height: 39px;
    }
`
