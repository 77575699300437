import { gql } from "apollo-boost";
import { Mutation } from "react-apollo";
import { completeTicket, completeTicketVariables } from "./types/completeTicket";

export default gql` 
  mutation completeTicket($id: ID!, $hasReceivedOrder: Boolean, $explanation: String) {
    completeTicket(id: $id, hasReceivedOrder: $hasReceivedOrder, explanation: $explanation) {
      id
    }
  }
`

export class CompleteTicketMutation extends Mutation<completeTicket, completeTicketVariables> {}
