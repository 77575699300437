/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AssignToMeResult {
  ALREADY_ASSIGNED = "ALREADY_ASSIGNED",
  CANNOT_ASSIGN = "CANNOT_ASSIGN",
  ERROR = "ERROR",
  SUCCESSFULLY_ASSIGNED = "SUCCESSFULLY_ASSIGNED",
}

export enum ChemicalQualityDeviationType {
  ANLIEFERTEMPERATUR = "ANLIEFERTEMPERATUR",
  CHLOR = "CHLOR",
  FLAMMPUNKT = "FLAMMPUNKT",
  HALOGENE = "HALOGENE",
  HEIZWERT = "HEIZWERT",
  LEITFAEHIGKEIT = "LEITFAEHIGKEIT",
  PCB = "PCB",
  PHWERT = "PHWERT",
  SCHWEFEL = "SCHWEFEL",
  SCHWERMETALLE = "SCHWERMETALLE",
  SEDIMENTE = "SEDIMENTE",
  SONSTIGES = "SONSTIGES",
  VISKOSITAT = "VISKOSITAT",
  WASSERGEHALT = "WASSERGEHALT",
}

export enum ClaimType {
  PLANNED = "PLANNED",
  UNPLANNED = "UNPLANNED",
}

export enum CtrlLevel {
  GEF = "GEF",
  NGEF = "NGEF",
}

export enum DeviationPositionType {
  ANDERES_MATERIAL = "ANDERES_MATERIAL",
  MANIPULATION = "MANIPULATION",
  REGIE = "REGIE",
  SORTIERKOSTEN = "SORTIERKOSTEN",
}

export enum InvoicePriceType {
  FIXED_PRICE = "FIXED_PRICE",
  SPECIAL_PRICE = "SPECIAL_PRICE",
  SURCHARGE = "SURCHARGE",
}

export enum LogisticQualityDeviationType {
  WRONG_CONTAINER = "WRONG_CONTAINER",
  WRONG_LABEL = "WRONG_LABEL",
  WRONG_PACKAGE = "WRONG_PACKAGE",
}

export enum MaterialProbeStatus {
  AVAILABLE = "AVAILABLE",
  IN_TRANSIT = "IN_TRANSIT",
  NOT_AVAILABLE = "NOT_AVAILABLE",
}

export enum MaterialUnit {
  KG = "KG",
  PERCENTAGE = "PERCENTAGE",
  TO = "TO",
}

export enum Mixture {
  GEMISCH = "GEMISCH",
  SORTENREIN = "SORTENREIN",
}

export enum NotPossibleDisposalReason {
  NO_OUTPUT_SOLUTION = "NO_OUTPUT_SOLUTION",
  NO_PERMIT = "NO_PERMIT",
}

export enum PhysicalQualityDeviationType {
  FARBE = "FARBE",
  FEUCHTE = "FEUCHTE",
  GEWICHT = "GEWICHT",
  KORNGROESSE = "KORNGROESSE",
  SONSTIGES = "SONSTIGES",
  STAUBEND = "STAUBEND",
  STOERSTOFFE = "STOERSTOFFE",
}

export enum Role {
  LABOR = "LABOR",
  PARTNER_SERVICE = "PARTNER_SERVICE",
  STOFFSTROM = "STOFFSTROM",
  VERTRIEB = "VERTRIEB",
}

export enum TicketStatus {
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  REQUESTED = "REQUESTED",
}

export enum Transportation {
  ABHOLUNG = "ABHOLUNG",
  ANLIEFERUNG = "ANLIEFERUNG",
}

export enum Unit {
  PIECES = "PIECES",
  QM = "QM",
  TONS = "TONS",
}

export enum WasteFrequency {
  CONTINUOUS = "CONTINUOUS",
  MONTHLY = "MONTHLY",
  SINGLE = "SINGLE",
  YEARLY = "YEARLY",
}

export interface BatteryWasteInput {
  description: string;
  photoAttachmentId?: string | null;
  production?: string | null;
  process: string;
  usage?: string | null;
  isContainerAvailable?: boolean | null;
  hasForeignSubstance?: boolean | null;
  hasForeignSubstanceAdditional?: string | null;
  wasteFrom?: string | null;
  comment?: string | null;
}

export interface BiogenWasteInput {
  description: string;
  photoAttachmentId?: string | null;
  consistency: string;
  consistencyAdditional?: string | null;
  drySubstance?: string | null;
  isAnalyticsAvailable?: boolean | null;
  pickup?: string | null;
  quantityDetermination?: string | null;
  qualityControl?: string | null;
  comment?: string | null;
}

export interface ChemicalQualitySectionInput {
  deviationType?: ChemicalQualityDeviationType | null;
  description: string;
  comment: string;
}

export interface CreateClaimInput {
  orderId: string;
  claimType?: ClaimType | null;
  orderSection: OrderSectionInput;
  materialSection: MaterialSectionInput[];
  physicalQualitySection: PhysicalQualitySectionInput;
  chemicalQualitySection: ChemicalQualitySectionInput;
  logisticQualitySection: LogisticQualitySectionInput;
  files: FileInput[];
  productionSection?: ProductionSectionInput | null;
  partnerServiceSection: PartnerServiceSectionInput;
}

export interface DeviationPositionInput {
  name: DeviationPositionType;
  isSelected: boolean;
  value?: string | null;
}

export interface DisposalWasteInput {
  description: string;
  photoAttachmentId?: string | null;
  production?: string | null;
  process: string;
  usage?: string | null;
  isSampleAvailable: boolean;
  isIncinerator?: boolean | null;
  size?: string | null;
  consistency: string;
  consistencyAdditional?: string | null;
  isCharacterizationAvailable: boolean;
  isAssessmentAvailable?: boolean | null;
  isAnalyticsAvailable?: boolean | null;
  phValue?: string | null;
  phValueSelection?: string | null;
}

export interface EagWasteInput {
  description: string;
  photoAttachmentId?: string | null;
  production?: string | null;
  process: string;
  usage?: string | null;
  isContainerAvailable?: boolean | null;
  hasForeignSubstance?: boolean | null;
  hasForeignSubstanceAdditional?: string | null;
  wasteFrom?: string | null;
  comment?: string | null;
}

export interface FileInput {
  title: string;
  base64: string;
}

export interface GeneralInput {
  oenormId: string;
  materialId?: string | null;
  divisionId: string;
  quantity: number;
  unit: string;
  frequency: WasteFrequency;
  pack: string;
  transport?: string | null;
  partnerId?: string | null;
  partnerCustomer?: string | null;
  partnerName?: string | null;
  partnerIndustry?: string | null;
  partnerStreet?: string | null;
  partnerNumber?: string | null;
  partnerPostal?: string | null;
  partnerCity?: string | null;
  partnerContact?: string | null;
  partnerLocation?: string | null;
}

export interface HazardousWasteInput {
  description: string;
  photoAttachmentId?: string | null;
  production?: string | null;
  process?: string | null;
  processSelection?: string | null;
  isSampleAvailable: boolean;
  sampleCreator?: string | null;
  sampleRecipient?: string | null;
  sampleReceivedAt?: string | null;
  smell?: string | null;
  consistency: string;
  isAnalyticsAvailable: boolean;
  comment?: string | null;
}

export interface HouseWasteInput {
  description: string;
  photoAttachmentId: string;
  process: string;
  comment?: string | null;
}

export interface InvoicePriceInput {
  name: InvoicePriceType;
  isSelected: boolean;
  value?: number | null;
}

export interface LogisticQualitySectionInput {
  deviationType?: LogisticQualityDeviationType | null;
  comment: string;
}

export interface MaterialSectionInput {
  materialId?: string | null;
  comment: string;
  quantity?: number | null;
  unit?: MaterialUnit | null;
}

export interface MetalWasteInput {
  description: string;
  photoAttachmentId: string;
  production: string;
  process: string;
  usage?: string | null;
  isContainerAvailable?: boolean | null;
  isAnalyticsAvailable?: boolean | null;
  isAnalyticsAvailableAdditional?: string | null;
  size: string;
  hasForeignSubstance?: boolean | null;
  hasForeignSubstanceAdditional?: string | null;
  isLicensed: boolean;
  isLicensedAdditional?: string | null;
  isIndexed?: boolean | null;
  comment?: string | null;
}

export interface OrderSectionInput {
  orderDate: string;
  orderNr: string;
  materialNumber?: string | null;
  materialTitle?: string | null;
  materialWeight?: string | null;
  materialUnit: MaterialUnit;
  carrierId?: string | null;
  deliveryNumber?: string | null;
  consignmentNoteNumber?: string | null;
  sourcePlant?: string | null;
  targetPlant?: string | null;
}

export interface PaperWasteInput {
  description: string;
  photoAttachmentId: string;
  production: string;
  process: string;
  usage?: string | null;
  isAnalyticsAvailable?: boolean | null;
  isAnalyticsAvailableAdditional?: string | null;
  size: string;
  hasForeignSubstance?: boolean | null;
  hasForeignSubstanceAdditional?: string | null;
  isLicensed: boolean;
  isLicensedAdditional?: string | null;
  isIndexed?: boolean | null;
  hasCoating?: boolean | null;
  hasCoatingAdditional?: string | null;
  comment?: string | null;
}

export interface PartnerServiceSectionInput {
  comment: string;
  commentDeviationPosition: string;
  deviationPosition: (DeviationPositionInput | null)[];
  invoicePrice: InvoicePriceInput[];
  missingBilledMaterial: boolean;
  priceMismatch: boolean;
}

export interface PhysicalQualitySectionInput {
  deviationType?: PhysicalQualityDeviationType | null;
  description: string;
  comment: string;
}

export interface PlasticWasteInput {
  description: string;
  photoAttachmentId: string;
  production: string;
  process: string;
  usage?: string | null;
  isAnalyticsAvailable?: boolean | null;
  isAnalyticsAvailableAdditional?: string | null;
  size: string;
  hasForeignSubstance?: boolean | null;
  hasForeignSubstanceAdditional?: string | null;
  isLicensed: boolean;
  isLicensedAdditional?: string | null;
  mixture?: Mixture | null;
  kind?: string | null;
  hasBulking?: boolean | null;
  hasBulkingAdditional?: string | null;
  hasFiber?: boolean | null;
  hasFiberAdditional?: string | null;
  flameRetardant?: string | null;
  additives?: string | null;
  color?: string | null;
  hasCoating?: boolean | null;
  comment?: string | null;
}

export interface ProductionSectionInput {
  plantId?: string | null;
  repeatedError: boolean;
  reason: string;
  recommendedAction: string;
  comment: string;
}

export interface UpdateClaimInput {
  orderId: string;
  claimType?: ClaimType | null;
  materialSection: MaterialSectionInput[];
  physicalQualitySection: PhysicalQualitySectionInput;
  chemicalQualitySection: ChemicalQualitySectionInput;
  logisticQualitySection: LogisticQualitySectionInput;
  files: FileInput[];
  productionSection?: ProductionSectionInput | null;
  partnerServiceSection: PartnerServiceSectionInput;
}

export interface UtilWasteInput {
  description: string;
  photoAttachmentId: string;
  production: string;
  process: string;
  usage?: string | null;
  isAnalyticsAvailable?: boolean | null;
  isAnalyticsAvailableAdditional?: string | null;
  size: string;
  hasForeignSubstance: boolean;
  hasForeignSubstanceAdditional?: string | null;
  isLicensed: boolean;
  isLicensedAdditional?: string | null;
  mixture?: Mixture | null;
  comment?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
