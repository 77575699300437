import { gql } from "apollo-boost";

export default gql`
  fragment FullTicketFragment on Ticket {
    id
    sourceId
    number
    status
    assignedRole
    assignedUser {
      id
      name
      email
    }
    createdAt
    updatedAt
    womNoticeId

    requestMaterialAnalysis
    statusMaterialProbeAnalysis

    explanation
    hasReceivedOrder

    createdBy {
      id
      name
      email
    }

    general {
      pack
      transport
      unit
      frequency
      quantity
      oenorm {
        id
        code
        title
        spezifikation
      }
      material {
        id
        matnr
        title
        prdha
        pks {
          ktext
          cost
          compensation
        }
      }
      division {
        id
        title
      }
      partner {
        id
        code
        name
        street
        number
        postal
        city
      }
      partnerName
      partnerCustomer
      partnerCity
      partnerStreet
      partnerNumber
      partnerPostal
      partnerContact
      partnerIndustry
      partnerLocation
    }

    attachments {
      id
      url
      mimeType
      title
      createdAt
      updatedAt
    }

    disposalWaste {
      description
      photoAttachmentId

      production
      process
      usage
      isSampleAvailable
      isIncinerator

      size
      consistency
      consistencyAdditional
      isCharacterizationAvailable
      isAssessmentAvailable
      isAnalyticsAvailable
      phValue
    }

    plasticWaste {
      description
      photoAttachmentId

      production
      process
      usage

      isAnalyticsAvailable
      isAnalyticsAvailableAdditional
      size
      hasForeignSubstance
      hasForeignSubstanceAdditional
      isLicensed
      isLicensedAdditional
      mixture
      kind
      hasBulking
      hasBulkingAdditional
      hasFiber
      hasFiberAdditional
      flameRetardant
      additives
      color
      hasCoating
      comment
    }

    metalWaste {
      description
      photoAttachmentId

      production
      process
      usage
      isContainerAvailable

      isAnalyticsAvailable
      isAnalyticsAvailableAdditional
      size
      hasForeignSubstance
      hasForeignSubstanceAdditional
      isLicensed
      isLicensedAdditional
      isIndexed

      comment
    }

    paperWaste {
      description
      photoAttachmentId

      production
      process
      usage

      isAnalyticsAvailable
      isAnalyticsAvailableAdditional
      size
      hasForeignSubstance
      hasForeignSubstanceAdditional
      isLicensed
      isLicensedAdditional
      isIndexed
      hasCoating
      hasCoatingAdditional
      comment
    }

    utilWaste {
      description
      photoAttachmentId

      production
      process
      usage

      isAnalyticsAvailable
      isAnalyticsAvailableAdditional
      size
      hasForeignSubstance
      hasForeignSubstanceAdditional
      isLicensed
      isLicensedAdditional
      mixture

      comment
    }

    hazardousWaste {
      description
      photoAttachmentId

      production
      process
      processSelection
      isSampleAvailable
      sampleCreator
      sampleRecipient
      sampleReceivedAt

      smell
      consistency
      isAnalyticsAvailable

      comment
    }

    houseWaste {
      description
      photoAttachmentId
      process
      comment
    }

    biogenWaste {
      description
      photoAttachmentId
      consistency
      consistencyAdditional
      drySubstance
      isAnalyticsAvailable
      pickup
      quantityDetermination
      qualityControl
      comment
    }

    eagWaste {
      description
      photoAttachmentId
      production
      process
      usage
      isContainerAvailable
      hasForeignSubstance
      hasForeignSubstanceAdditional
      wasteFrom
      comment
    }

    batteryWaste {
      description
      photoAttachmentId
      production
      process
      usage
      isContainerAvailable
      hasForeignSubstance
      hasForeignSubstanceAdditional
      wasteFrom
      comment
    }

    utilizations {
      id
      compensation
      costs
      logisticCosts
      material {
        id
        matnr
        title
        unit
        oenormId
        ctrlLevel
        prdha
        pks {
          ktext
          cost
          compensation
        }
      }
      materialRemark
      oenorm {
        id
        code
        title
        ctrlLevels
        spezifikation
      }
      plant {
        id
        name
        city
        description
        facility
        number
        postal
        street
        tech
      }
      selectedUnit
      standort
      streckenabholung
      transportation
      notPossibleDisposal
      notPossibleDisposalReason
      notPossibleDisposalNote
    }
    comments {
      uuid
      comment
      createdAt
      author {
        id
        name
        email
        group
      }
    }
  }
`;

export const TRANSPORTATION_ENUM = {
  ANLIEFERUNG: { label: "Angeliefert", value: "ANLIEFERUNG" },
  ABHOLUNG: { label: "Abgeholt", value: "ABHOLUNG" },
};
