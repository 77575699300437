import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { FilterProvider } from "./context/FilterContext";
import "tachyons";
import "./index.css";

import store from "store";
import { authLink, errorLink, queryOrMutationLink } from "./utils/links";
import ApolloClient, { DefaultOptions } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import "react-toastify/dist/ReactToastify.css";
import "./utils/string.extensions";
import { Router } from "./components/router";

if (
  window.location.hostname !== "localhost" &&
  window.location.protocol !== "https:"
) {
  window.location.href = window.location.href.replace("http://", "https://");
}

const DEFAULT_ENDPOINT_PROTOCOL = "https";
const DEFAULT_ENDPOINT_PATH = "graphql";
const endpoint = (
  path = DEFAULT_ENDPOINT_PATH,
  protocol = DEFAULT_ENDPOINT_PROTOCOL
) => {
  const hostname = window.location.hostname;
  if (hostname.endsWith(".herokuapp.com")) {
    return `${protocol}://${hostname
      .split("-")
      .shift()}-ssts-api.herokuapp.com/${path}`;
  }
  if (hostname === "stoffstromticket.saubermacher.at") {
    return `${protocol}://stoffstromticket-api.saubermacher.at/${path}`;
  }
  return `http://localhost:5000/${path}`;
};

const links = [authLink, errorLink, queryOrMutationLink(endpoint())];

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "network-only",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "ignore",
  },
};

export const client = new ApolloClient({
  //@ts-ignore
  link: ApolloLink.from(links),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

function getParameterByName(name: string, url?: string): string | null {
  if (!url) url = window.location.href;
  name = name.replace(/[[]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

window.name = "ssts";

const tokenFromUrl = getParameterByName("token");
if (tokenFromUrl) {
  store.set("access_token", tokenFromUrl);
  window.history.pushState(
    "",
    "",
    window.location.href.replace(`/?token=${tokenFromUrl}`, "")
  );
}

ReactDOM.render(
  <AuthProvider token={store.get("access_token")}>
    <FilterProvider>
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </ApolloHooksProvider>
      </ApolloProvider>
    </FilterProvider>
  </AuthProvider>,
  document.getElementById("root")
);
