import React, { Fragment } from "react";
import DynamicTable from "@atlaskit/dynamic-table";
import { Order } from "../../../../../../api/graphql/queries/orderSearch";
import {
  generateOrderTableHead,
  generateOrderTableRows,
} from "./helper/order-table-helper";
import { useTranslation } from "react-i18next";
import { GridColumn } from "@atlaskit/page";

interface IOrderTableProps {
  orders: Order[];
}

export const OrderTable: React.FC<IOrderTableProps> = (props) => {
  const { orders } = props;
  const { t } = useTranslation();

  return (
    <Fragment>
      <GridColumn>
        <div>
          <h2>{t("create_claim.table.title")}</h2>
        </div>
      </GridColumn>
      <GridColumn>
        {orders.length > 0 && (
          <DynamicTable
            head={generateOrderTableHead()}
            rows={generateOrderTableRows(orders)}
          />
        )}
      </GridColumn>
      <GridColumn>
        {orders.length}{" "}
        {orders.length > 1
          ? t("create_claim.table.found_entries")
          : t("create_claim.table.found_entry")}
      </GridColumn>
    </Fragment>
  );
};
