import { GridColumn } from "@atlaskit/page";
import React from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@atlaskit/checkbox";
import Textfield from "@atlaskit/textfield";
import { Field, HelperMessage } from "@atlaskit/form";

export interface ITextSelection {
  name: string;
  isSelected: boolean;
  value: string;
}

interface ICustomCheckboxTextFieldProps {
  translation: string;
  selection: ITextSelection;
  onChange: (selection: ITextSelection) => void;
  disabled?: boolean;
}

export const CustomCheckboxTextField: React.VFC<
  ICustomCheckboxTextFieldProps
> = (props) => {
  const { t } = useTranslation();
  const { translation, selection, onChange, disabled } = props;
  const checkboxTranslation = t(`${translation}.label`);
  const helperTranslation = t(`${translation}.helper`);
  const fieldTranslation = t(`${translation}.field`);

  const onChangeCheckboxSelect = (event: any) => {
    const changedSelection = {
      ...selection,
      isSelected: event.target.checked,
    };
    onChange(changedSelection);
  };

  const onChangeText = (event: any) => {
    const changedSelection = {
      ...selection,
      value: event.target.value,
    };
    onChange(changedSelection);
  };

  return (
    <GridColumn medium={12}>
      <Field
        name={selection.name}
        layout="max-width"
        style={{ width: "100%" }}
        fullWidth
      >
        {({
          fieldProps: { id, ...rest },
          error,
        }: {
          fieldProps: any;
          error: any;
        }) => (
          <>
            <div style={{ display: "flex" }}>
              <div style={{ width: "180px", paddingTop: "24px" }}>
                <Checkbox
                  name={selection.name}
                  isChecked={selection.isSelected}
                  disabled={disabled}
                  onChange={onChangeCheckboxSelect}
                  label={checkboxTranslation}
                />
              </div>
              <div style={{ flexGrow: 1 }}>
                <div
                  style={{
                    fontWeight: 600,
                    color: "#5E6C84",
                    fontSize: 12,
                    paddingBottom: "4px",
                  }}
                >
                  {fieldTranslation}
                </div>
                <Textfield
                  style={{ height: "30px" }}
                  type="text"
                  defaultValue={selection.value}
                  onChange={onChangeText}
                  isDisabled={disabled}
                />
                {helperTranslation && (
                  <HelperMessage>{helperTranslation}</HelperMessage>
                )}
              </div>
            </div>
          </>
        )}
      </Field>
    </GridColumn>
  );
};
