import React, { useState } from "react";
import { MaterialFormSection } from "../../../../partials/claim/MaterialFormSection";
import { PhysicalQualityFormSection } from "../../../../partials/claim/PhysicalQualityFormSection";
import { ChemicalQualityFormSection } from "../../../../partials/claim/ChemicalQualityFormSection";
import { LogisticsFormSection } from "../../../../partials/claim/LogisticsFormSection";
import { ProductionFormSection } from "../../../../partials/claim/ProductionFormSection";
import { FileUploadFormSection } from "../../../../partials/claim/FileUploadFormSection";
import { PartnerServiceFormSection } from "../../../../partials/claim/PartnerServiceFormSection";
import { ClaimTypeField } from "../../../../partials/claim/ClaimTypeField";
import { Grid, GridColumn } from "@atlaskit/page";
import Button from "@atlaskit/button";
import { useTranslation } from "react-i18next";
import { PlantFragment } from "../../../../../api/graphql/fragments/types/PlantFragment";
import { useCreateClaimContext } from "../../../../partials/claim/context/create-claim-context";
import { AssignRoleDialog } from "../../../../partials/assign-role/AssignRoleDialog";
import { Order } from "../../../../../api/graphql/queries/orderSearch";
import { OrderInformationSection } from "../../../../partials/claim/OrderInformationSection";

interface ICreateClaimFormProps {
  order: Order;
  plants: PlantFragment[];
}

export const CreateClaimForm: React.FC<ICreateClaimFormProps> = (props) => {
  const { order, plants } = props;

  const [isAssignRoleDialogOpen, setIsAssignRoleDialogOpen] = useState(false);
  const {
    claimType,
    onUpdateClaimType,
    onUpdateOrderSection,
    materialSection,
    onUpdateMaterialSection,
    physicalQualitySection,
    onUpdatePhysicalQualitySection,
    productionSection,
    onUpdateProductionSection,
    chemicalQualitySection,
    onUpdateChemicalQualitySection,
    logisticQualitySection,
    onUpdateLogisticQualitySection,
    files,
    onUpdateFiles,
    partnerServiceSection,
    onUpdatePartnerServiceSection,
    assigneeRole,
    disabled,
    loading,
    onSubmit,
  } = useCreateClaimContext();
  const { t } = useTranslation();

  const showProductionSection = () => claimType === "UNPLANNED";

  const handleCreateClick = () => {
    setIsAssignRoleDialogOpen(true);
  };

  return (
    <div>
      <AssignRoleDialog
        isOpen={isAssignRoleDialogOpen}
        assignTo={assigneeRole}
        isLoading={loading}
        onClose={() => setIsAssignRoleDialogOpen(false)}
        onSubmit={(role, comment, assigneeId) =>
          onSubmit(order.orderNr, role, comment, assigneeId)
        }
        isClaim={true}
      />

      <Grid layout="max-width">
        <GridColumn medium={12}>
          <OrderInformationSection
            onChange={onUpdateOrderSection}
            section={{
              orderDate: order.orderDate,
              orderNr: order.orderNr,
              materialNumber: order.material?.matnr,
              materialTitle: order.material?.title,
              materialWeight: order.materialWeight,
              materialUnit: order.materialUnit,
              carrierId: order.carrierId,
              deliveryNumber: order.deliveryNumber,
              consignmentNoteNumber: order.consignmentNoteNumber,
              sourcePlant: order.sourcePlant?.facility,
              targetPlant: order.targetPlant?.facility,
            }}
          />
        </GridColumn>
        <GridColumn medium={6}>
          <ClaimTypeField
            value={claimType}
            onChange={onUpdateClaimType}
            disabled={disabled}
          />
          <MaterialFormSection
            sections={materialSection}
            onChange={onUpdateMaterialSection}
            disabled={disabled}
          />
          {showProductionSection() && (
            <ProductionFormSection
              section={productionSection}
              onChange={onUpdateProductionSection}
              plants={plants}
              disabled={disabled}
              sourcePlantId={order.sourcePlant?.id}
            />
          )}
        </GridColumn>

        <GridColumn medium={6}>
          <PhysicalQualityFormSection
            section={physicalQualitySection}
            onChange={onUpdatePhysicalQualitySection}
            disabled={disabled}
          />
          <ChemicalQualityFormSection
            section={chemicalQualitySection}
            onChange={onUpdateChemicalQualitySection}
            disabled={disabled}
          />
          <LogisticsFormSection
            section={logisticQualitySection}
            onChange={onUpdateLogisticQualitySection}
            disabled={disabled}
          />
        </GridColumn>
        <GridColumn medium={12}>
          <FileUploadFormSection
            files={files}
            onChange={onUpdateFiles}
            disabled={disabled}
          />
        </GridColumn>
        <GridColumn medium={12}>
          <PartnerServiceFormSection
            section={partnerServiceSection}
            onChange={onUpdatePartnerServiceSection}
            disabled={disabled}
          />
        </GridColumn>
        <GridColumn medium={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "16px",
              marginBottom: "32px",
            }}
          >
            <Button
              appearance="primary"
              type="submit"
              isDisabled={false}
              onClick={handleCreateClick}
              isLoading={loading}
            >
              {t("create_claim.form.create_button")}
            </Button>
          </div>
        </GridColumn>
      </Grid>
    </div>
  );
};
