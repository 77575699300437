export const FACILITY_GROUPS = {
  OL: { name: "STO Wien" },
  LAN: { name: "STO Lannach" },
  UPS: { name: "STO Unterpremstätten" },
  TRO: { name: "STO Trofaiach" },
  PUCH: { name: "STO Puchstrasse" },
  KREMS: { name: "STO Krems" },
  FELD: { name: "STO Feldbach" },
  KAPF: { name: "STO Kapfenberg" },
  TRÜGLER: { name: "STO Trügler" },
  KOMEX: { name: "STO KOMEX" },
  MBS: { name: "MBS" },
  SERVUS: { name: "SERVUS" },
  MAYER: { name: "Mayer" },
  SDAG: { name: "Slowenien" }
}
