import React, { FormEvent, FunctionComponent, SyntheticEvent, useCallback, useMemo, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import __ from "lodash"
import validator from "email-validator"
import Spinner from "@atlaskit/spinner"
import { useTranslation } from "react-i18next"
import { Mutation, Query } from "react-apollo"
import { Redirect } from "react-router"
import Flag from "@atlaskit/flag"
import Error from "@atlaskit/icon/glyph/error"
import LockFilledIcon from "@atlaskit/icon/glyph/lock-filled"
import FieldText from "@atlaskit/field-text"
import Button from "@atlaskit/button"
import { AuthConsumer } from "../../../context/AuthContext"
import { Background, Card, Footer, Header, Section, Wrapper } from "../../styled/login"
import SIGNIN_MUTATION from "../../../api/graphql/mutations/SigninMutation"
import VERSION_QUERY from "../../../api/graphql/queries/version"
import { ApolloError } from "apollo-boost"
import { Abilities } from '../../../domain/models/abilities';

interface ILoginProps {

}

export const LoginPage: FunctionComponent<ILoginProps> = (props) => {
  const {t} = useTranslation();
  const location = useLocation();

  const history = useHistory();

  const head = useMemo(() => document.head, [document]);
  const version = useMemo(() => __.get(head && head.querySelector("[name=version]"), "content", "-") || "-", [head]);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPasswordField, setShowPasswordField] = useState<boolean>(false);
  const [showValidationWarning, setShowValidationWarning] = useState<boolean>(false);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string | undefined>(undefined);
  const [loginError, setLoginError] = useState<boolean>(false);

  const handleSigninComplete = useCallback((authin: (token: string) => void, response: any) => {
    if (!response.signin.valid) {
      setRequestError(undefined);
      setLoginError(true);
      setShowLoadingIndicator(false);
    } else {
      setRequestError(undefined);
      setLoginError(false);
      setShowLoadingIndicator(false);
      authin(response.signin.token)

      if (location.search) {
        history.push(decodeURI(location.search.replace("?pushUrl=", "")));
      }
      // Todo: check if this fixes Stefan's problem
      window.location.reload();
    }
  }, [setRequestError, setLoginError, setShowLoadingIndicator]);

  const handleSigninError = useCallback((error: ApolloError) => {
    setRequestError(error.message);
    setShowLoadingIndicator(false);
  }, [setRequestError, setShowLoadingIndicator]);

  const handleEmailChange = useCallback((event: SyntheticEvent) => {
    const email = __.get(event, "target.value", "")
    setEmail(email);
    setShowValidationWarning(false);
    setShowPasswordField(validator.validate(email));
    setLoginError(false);
  }, [setEmail, setShowValidationWarning, setShowPasswordField, setLoginError, validator]);

  const handleEmailKeypress = useCallback((event: SyntheticEvent) => {
    if (email.length > 0 && validator.validate(email) && __.get(event, "charCode") === 13) {
      setShowPasswordField(true)
    }
  }, [email, validator, __, setShowPasswordField]);

  const handlePasswordChange = useCallback((event: SyntheticEvent) => {
    setPassword(__.get(event, "target.value", ""));
    setLoginError(false)
  }, [setPassword, setLoginError]);
  
  const handleButtonClick = useCallback((signin: Function) => {
    const isValid = validator.validate(email)
    const isLogin = showPasswordField && email.length > 0 && password.length > 0 && validator.validate(email)

    setShowPasswordField(isValid);
    setShowValidationWarning(!isValid);
    setShowLoadingIndicator(isLogin);

    if (isLogin) {
      signin({ variables: {
          email: email,
          password: password,
        }})
    }
  }, [validator, showPasswordField, email, password, setShowPasswordField, setShowValidationWarning, setShowLoadingIndicator]);

  return (
    <Background>
      <AuthConsumer>
        {({isAuthenticated, hasAbility, authin, token}) => (
          <Mutation
            mutation={SIGNIN_MUTATION}
            onCompleted={(response: any) => handleSigninComplete(authin, response)}
            onError={handleSigninError}
          >
            {(signin: Function) => (
              <Wrapper>
                {isAuthenticated() ? <Redirect to={{pathname: '/'}}/> : null}
                <Header>
                  <img src="./logo.png" alt={t("global.title")}/>
                  <h1>{t("global.company")} <em>{t("global.product")}</em></h1>
                </Header>

                <Section>
                  <Card>
                    {!!token && !hasAbility(Abilities.TICKET)
                      ? <div style={{marginBottom: "1.5em"}}>
                        <Flag
                          appearance="error"
                          title="Nicht berechtigt"
                          description="Ihr Benutzer hat nicht die notwendigen Rechte um auf das Ticketsystem zuzugreifen."
                          icon={<LockFilledIcon label="Error icon" secondaryColor="#E3001B"/>}
                          isDismissAllowed
                        />
                      </div>
                      : null}
                    {requestError
                      ? <div style={{marginBottom: "1.5em"}}>
                        <Flag
                          appearance="error"
                          title="Ohje, das war unser Fehler!"
                          description={requestError}
                          icon={<Error label="Error icon" secondaryColor="#E3001B"/>}
                          isDismissAllowed
                        />
                      </div>
                      : null}
                    <FieldText
                      label={t("login.email.label")}
                      isInvalid={showValidationWarning}
                      // invalidMessage="Gib deine Denovo Care Email Adresse ein."
                      name="email"
                      required
                      shouldFitContainer
                      disabled={showLoadingIndicator}
                      onChange={(event: FormEvent<HTMLInputElement>) => handleEmailChange(event)}
                      onKeyUp={(event: FormEvent<HTMLInputElement>) => handleEmailKeypress(event)}
                      value={email}
                    />
                    {showPasswordField ? (
                      <FieldText
                        label={t("login.password.label")}
                        name="password"
                        type="password"
                        required
                        isInvalid={loginError}
                        // invalidMessage="Die eingegebenen Benutzerdaten wurde nicht erkannt. Bitte kontrolliere deine Eingabe."
                        disabled={showLoadingIndicator}
                        shouldFitContainer
                        onChange={(event: FormEvent<HTMLInputElement>) => handlePasswordChange(event)}
                        value={password}
                      />
                    ) : null}

                    <Button
                      appearance="primary"
                      type="submit"
                      shouldFitContainer
                      onClick={() => handleButtonClick(signin)}
                      isDisabled={(showPasswordField && password.length < 1) || showLoadingIndicator}
                    >
                      {showLoadingIndicator
                        ? <div className="wom-button-spinner"><Spinner/> <span
                          className="text">{t("login.status.progress.message")}</span></div>
                        : (showPasswordField ? "Einloggen" : "Weiter")}
                    </Button>

                    <Button
                      appearance="secondary"
                      type="submit"
                      shouldFitContainer
                      onClick={() => history.push("/change-password")}
                    >
                      Passwort vergessen
                    </Button>
                  </Card>
                </Section>

                <Footer>
                  <Query query={VERSION_QUERY}>
                    {({data, loading, error, refetch}: { data: any, loading: any, error?: any, refetch: Function }) => (
                      <div>
                        <span>Version {version} → {!loading ? __.get(data, "version.tag", "n.A.") :
                          <Spinner size="small" invertColor/>}</span>
                        <ul className="wom-fine-print">
                          <li>
                            <a href="https://www.denovo.at/agb/" target="_blank" rel="noopener noreferrer">
                              <span>{t("global.tos.title")}</span>
                            </a>
                          </li>
                          <li>
                            <a href="https://www.denovo.at/impressum/" target="_blank" rel="noopener noreferrer">
                              <span>{t("global.imprint.title")}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </Query>
                </Footer>
              </Wrapper>
            )}
          </Mutation>
        )}
      </AuthConsumer>
    </Background>
  )
}
