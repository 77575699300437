export const HistoryEntryType = Object.freeze({
  CREATE_TICKET: "create_ticket",
  STATUS_UPDATE: "status_update",
  ADD_COMMENT: "add_comment",
  CREATE_WOM_NOTICE: "create_wom_notice",
  LINK_ATTACHMENT: "link_attachment",
  UTILIZATION: "utilization",
  ASSIGN_ROLE: "assign_role",
  REQUEST_MATERIAL_ANALYSIS: "request_material_analysis",
  ASSIGN_USER: "assign_user",
  CREATE_CLAIM: "create_claim",
  UPDATE_CLAIM: "update_claim",
});
