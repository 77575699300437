import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Claim } from "../../../../api/graphql/queries/claims";
import { List } from "../../../partials/list/List";
import { ClaimListItem } from "./ClaimListItem";

interface IClaimListProps {
  claims: Claim[];
  loading: boolean;
  error: boolean;
}

export const ClaimList: FC<IClaimListProps> = (props) => {
  const { t } = useTranslation();
  const { claims, loading, error } = props;

  const renderItem = (claim: Claim) => {
    return <ClaimListItem key={claim.id} claim={claim} />;
  };

  return (
    <List
      items={claims}
      loading={loading}
      error={error}
      renderItem={renderItem}
      emptyText={t("components.claim_list.empty")}
      errorText={t("components.claim_list.error")}
    />
  );
};
