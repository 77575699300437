import * as React from "react"
import __ from "lodash"
import { withTranslation } from "react-i18next"
import { IBaseProps } from "../../../../utils/BaseProps"
import { Field } from "@atlaskit/form"

interface ILabelProps extends IBaseProps {
  name: string
  translation: String
  required?: Boolean
}

export default withTranslation()((props: ILabelProps) => {
  const { t, name, translation } = props

  const validate = (selected: any) => {
    if (props.required && !selected) {
      return t("form.select.required.error")
    }
    return undefined
  }

  return (
    <Field
      label={t(`${translation}.title`)}
      name={name}
      isRequired={!!props.required}
      validate={validate}
    >
      {({ fieldProps: { id, ...rest }, error }: { fieldProps: any, error: any }) => <></>}
    </Field>
  )
})
