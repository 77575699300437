import React from 'react'
import {IBaseProps} from "../../../../utils/BaseProps"
import {withTranslation} from "react-i18next";
import BasicStructure from "./basic-structure";
import {Grid, GridColumn} from "@atlaskit/page";
import {Field} from "@atlaskit/form"
import TextArea from "@atlaskit/textarea"
import Select from "@atlaskit/select"
import Textfield from "@atlaskit/textfield"
import {IInfoHandler, WasteInputType} from "../CreateTicketForm";
import {IDescriptionImageInfoHandler} from "./description-image";
import renderAdditional from "../common/additional"
import {UnwrapGrid} from "../CreateTicketForm"
import UploadHint from "../common/upload-hint"

interface IDeponierbareAbfaelleProps extends IBaseProps {
  infoHandler: IInfoHandler
}

interface IDeponierbareAbfaelleState {
  produced: string
  process: string
  originalUse: string
  sample: IYesNoOption | null
  waste_from_incinerator: IYesNoOption | null
  detail: string,
  consistency: IConsistencyOption | null,
  consistency_additional: string,
  characteristic: IYesNoOption | null,
  additional: IYesNoOption | null,
  analysis: IYesNoOption | null
  ph_value: string
  ph_value_selection: IPhValueOption | null
  comment: string
}

export interface IConsistencyOption {
  value: string
  label: string
}

export interface IYesNoOption {
  value: string
  label: string
}

export interface IPhValueOption {
  value: string
  label: string
}

export const phValueSelection: IPhValueOption[] = [
  {value: '<12', label: '<12'},
  {value: '>12', label: '>12'},
]

export const YES = "true"
export const NO = "false"

export const yesNoOptions: IYesNoOption[] = [
  {value: 'true', label: 'Ja'},
  {value: 'false', label: 'Nein'},
]

const consistencyOptions: IConsistencyOption[] = [
  {value: 'fest', label: 'fest'},
  {value: 'flüssig', label: 'flüssig'},
  {value: 'körnig', label: 'körnig'},
  {value: 'pastös', label: 'pastös'},
  {value: 'staubförmig', label: 'staubförmig'},
  {value: 'stichfest', label: 'stichfest'},
  {value: 'trocken', label: 'trocken'},
]

class DeponierbareAbfaelle extends React.Component<IDeponierbareAbfaelleProps, IDeponierbareAbfaelleState> {

  private descriptionImageInfoHandler: IDescriptionImageInfoHandler = {}

  constructor(props: IDeponierbareAbfaelleProps) {
    super(props);

    this.props.infoHandler.getInfo = this.getInfo


    this.state = {
      produced: '',
      process: '',
      originalUse: '',
      sample: null,
      waste_from_incinerator: null,

      detail: '',
      consistency: null,
      consistency_additional: '',
      characteristic: null,
      additional: null,
      analysis: null,
      ph_value: '',
      ph_value_selection: null,

      comment: '',
    }
  }

  componentWillReceiveProps(nextProps: Readonly<IDeponierbareAbfaelleProps>, nextContext: any): void {
    nextProps.infoHandler.getInfo = this.getInfo
  }

  private getInfo = () => {
    const descriptionImageInfo = this.descriptionImageInfoHandler.getInfo && this.descriptionImageInfoHandler.getInfo()

    if (
      !descriptionImageInfo
      || descriptionImageInfo.wasteDescription.isBlank()
      || this.state.process.isBlank()
      || !this.state.consistency
      || !this.state.characteristic
    ) {
      return null
    }

    if (!this.state.sample || !this.state.consistency || !this.state.characteristic || !descriptionImageInfo)
      return null

    return {
      inputType: WasteInputType.DISPOSAL,
      description: descriptionImageInfo.wasteDescription,
      photoAttachmentId: descriptionImageInfo ? descriptionImageInfo.photoAttachmentId : "",

      production: this.state.produced,
      process: this.state.process,
      usage: this.state.originalUse,
      isSampleAvailable: this.state.sample.value === 'true',
      isIncinerator: this.state.waste_from_incinerator ? this.state.waste_from_incinerator.value === 'true' : null,

      size: this.state.detail,
      consistency:  this.state.consistency.value,
      consistencyAdditional: this.state.consistency_additional,
      isCharacterizationAvailable: this.state.characteristic.value === 'true',
      isAssessmentAvailable: this.state.additional ? this.state.additional.value === 'true' : null,
      isAnalyticsAvailable: this.state.analysis ? this.state.analysis.value === 'true' : null,
      phValue: this.state.ph_value,
      phValueSelection: this.state.ph_value_selection ? this.state.ph_value_selection.value : null,
    }
  }

  private onProducedChanged = (e: any) => this.setState({produced: e.target.value})
  private onProcessChanged = (e: any) => this.setState({process: e.target.value})
  private onOriginalUseChanged = (e: any) => this.setState({originalUse: e.target.value})
  private onSampleChange = (o: IYesNoOption) => this.setState({sample: o})
  private onWasteFromChanged = (o: IYesNoOption) => this.setState({waste_from_incinerator: o})

  private onDetailChanged = (e: any) => this.setState({detail: e.target.value})
  private onPhValueChanged = (e: any) => this.setState({ph_value: e.target.value})
  private onPhValueSelectionChanged = (o: IPhValueOption) => this.setState({ph_value_selection: o})
  private onConsistencyChanged = (e: IConsistencyOption) => this.setState({consistency: e})
  private onConsistencyAdditionalChanged = (e: any) => this.setState({consistency_additional: e.target.value})
  private onCharacteristicChanged = (o: IYesNoOption) => this.setState({characteristic: o})
  private onAdditionalChanged = (o: IYesNoOption) => this.setState({additional: o})
  private onAnalysisChanged = (o: IYesNoOption) => this.setState({analysis: o})
  private onCommentChanged = (e: any) => this.setState({comment: e.target.value})

  private renderCompanyAndProcess = () => {
    const {t} = this.props

    return (
      <UnwrapGrid>
      <Grid layout="max-width">
        <GridColumn medium={6}>
          <Field name="produced" label={t('tickets.create.fields.deponierbare_abfaelle.company_process.produced')}>
            {({ fieldProps }: { fieldProps: any }) => (
              <TextArea
                {...fieldProps}
                isCompact={false}
                minimumRows={5}
                onChange={this.onProducedChanged}
                value={this.state.produced}
              />
            )}
          </Field>
        </GridColumn>
        <GridColumn medium={6}>
          <Field name="process" label={t('tickets.create.fields.deponierbare_abfaelle.company_process.process')} isRequired={true}>
            {({ fieldProps }: { fieldProps: any }) => (
              <TextArea
                {...fieldProps}
                isCompact={false}
                minimumRows={5}
                onChange={this.onProcessChanged}
                value={this.state.process}
              />
            )}
          </Field>
        </GridColumn>

        <GridColumn medium={6}>
          <Field name="original_use" label={t('tickets.create.fields.deponierbare_abfaelle.company_process.original_use')}>
            {({ fieldProps }: { fieldProps: any }) => (
              <TextArea
                {...fieldProps}
                isCompact={false}
                minimumRows={5}
                onChange={this.onOriginalUseChanged}
                value={this.state.originalUse}
              />
            )}
          </Field>
        </GridColumn>
        <GridColumn medium={6}>
        </GridColumn>

        <GridColumn medium={6}>
          <Field name="sample" label={t('tickets.create.fields.deponierbare_abfaelle.company_process.sample')} isRequired={true}>
          {(props: any) => (
            <Select
              options={yesNoOptions}
              onChange={this.onSampleChange}
              value={this.state.sample}
              isSearchable={false}
            />
          )}
          </Field>
        </GridColumn>
        <GridColumn medium={6}>
          <Field name="waste_from_incinerator" label={t('tickets.create.fields.deponierbare_abfaelle.company_process.waste_from_incinerator')}>
          {(props: any) => (
            <Select
              options={yesNoOptions}
              onChange={this.onWasteFromChanged}
              value={this.state.waste_from_incinerator}
              isSearchable={false}
            />
          )}
          </Field>
        </GridColumn>
      </Grid>
      </UnwrapGrid>
    )
  }

  private renderMaterialDescription = () => {
    const {t} = this.props

    return (
      <UnwrapGrid>
      <Grid layout="max-width">
        <GridColumn medium={12}>
          <Field name="detail" label={t('tickets.create.fields.deponierbare_abfaelle.material_description.detail')}>
            {({ fieldProps }: { fieldProps: any }) => (
              <Textfield
                {...fieldProps}
                isCompact={false}
                onChange={this.onDetailChanged}
                value={this.state.detail}
              />
            )}
          </Field>
        </GridColumn>
        <GridColumn medium={6}>
          <Field name="consistency" label={t('tickets.create.fields.deponierbare_abfaelle.material_description.consistency')} isRequired={true}>
          {(props: any) => (
            <Select
              options={consistencyOptions}
              onChange={this.onConsistencyChanged}
              value={this.state.consistency}
              isSearchable={false}
            />
          )}
          </Field>
        </GridColumn>
        <GridColumn medium={6}>
          <Field name="consistency_additional" label={t('tickets.create.fields.deponierbare_abfaelle.material_description.consistency_additional')}>
            {({ fieldProps }: { fieldProps: any }) => (
              <Textfield
                {...fieldProps}
                isCompact={false}
                onChange={this.onConsistencyAdditionalChanged}
                value={this.state.consistency_additional}
              />
            )}
          </Field>
        </GridColumn>

        <GridColumn medium={6}>
          <Field name="characteristic" label={t('tickets.create.fields.deponierbare_abfaelle.material_description.characteristic')} isRequired={true}>
          {(props: any) => (
            <Select
              options={yesNoOptions}
              onChange={this.onCharacteristicChanged}
              value={this.state.characteristic}
              isSearchable={false}
            />
          )}
          </Field>
        </GridColumn>
        <GridColumn medium={6}>
          <Field name="additional" label={t('tickets.create.fields.deponierbare_abfaelle.material_description.additional')}>
          {(props: any) => (
            <Select
              options={yesNoOptions}
              onChange={this.onAdditionalChanged}
              value={this.state.additional}
              isSearchable={false}
            />
          )}
          </Field>
        </GridColumn>

        <GridColumn medium={12}>
          <Field name="analysis" label={t('tickets.create.fields.deponierbare_abfaelle.material_description.analysis')}>
          {(props: any) => (
            <Select
              options={yesNoOptions}
              onChange={this.onAnalysisChanged}
              value={this.state.analysis}
              isSearchable={false}
            />
          )}
          </Field>
          <UploadHint analysis={this.state.analysis} />
        </GridColumn>
        <GridColumn medium={6}>
          <Field name="ph_value_selection" label={t('tickets.create.fields.deponierbare_abfaelle.material_description.ph_value_selection')}>
          {(props: any) => (
            <Select
              options={phValueSelection}
              onChange={this.onPhValueSelectionChanged}
              value={this.state.ph_value_selection}
              isSearchable={false}
            />
          )}
          </Field>
        </GridColumn>
        <GridColumn medium={6}>
          <Field name="ph_value" label={t('tickets.create.fields.deponierbare_abfaelle.material_description.ph_value')}>
            {({ fieldProps }: { fieldProps: any }) => (
              <Textfield
                {...fieldProps}
                isCompact={false}
                onChange={this.onPhValueChanged}
                value={this.state.ph_value}
              />
            )}
          </Field>
        </GridColumn>

      </Grid>
      </UnwrapGrid>
    )
  }

  public render() {
    return <BasicStructure
      companyAndProcess={this.renderCompanyAndProcess()}
      materialDescription={this.renderMaterialDescription()}
      additional={renderAdditional(this.props.t, this.onCommentChanged, this.state.comment)}
      infoHandler={this.descriptionImageInfoHandler}
      descriptionRequired={true}
      imageRequired={false}
    />
  }
}

export default withTranslation()(DeponierbareAbfaelle)
