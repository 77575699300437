import { gql } from "apollo-boost";
import { MutationHookOptions, useMutation } from "react-apollo-hooks";
import {
  assignRoleToClaim,
  assignRoleToClaimVariables,
} from "./types/assignRoleToClaim";

const mutation = gql`
  mutation assignRoleToClaim(
    $claimNumber: Int!
    $role: Role!
    $assigneeId: ID
    $comment: String!
  ) {
    assignRoleToClaim(
      claimNumber: $claimNumber
      role: $role
      assigneeId: $assigneeId
      comment: $comment
    ) {
      id
      orderId
      number
    }
  }
`;

type AssignRoleToClaimResult = assignRoleToClaim;
type AssignRoleToClaimVariables = assignRoleToClaimVariables;
export const useAssignRoleToClaim = (
  options?: MutationHookOptions<
    AssignRoleToClaimResult,
    AssignRoleToClaimVariables
  >
) => useMutation(mutation, options);
