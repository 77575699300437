import { gql } from "apollo-boost";
import { MutationHookOptions, useMutation } from "react-apollo-hooks";
import {
  addCommentToClaim,
  addCommentToClaimVariables,
  addCommentToClaim_addCommentToClaim,
  addCommentToClaim_addCommentToClaim_author,
} from "./types/addCommentToClaim";

const mutation = gql`
  mutation addCommentToClaim($claimNumber: Int!, $comment: String!) {
    addCommentToClaim(claimNumber: $claimNumber, comment: $comment) {
      uuid
      comment
      createdAt
      author {
        id
        name
        email
        group
      }
    }
  }
`;

export type AddCommentToClaim = addCommentToClaim_addCommentToClaim;
export type AddCommentToClaimAuthor =
  addCommentToClaim_addCommentToClaim_author;
type AddCommentToClaimResult = addCommentToClaim;
type AddCommentToClaimVariables = addCommentToClaimVariables;
export const useAddCommentToClaimMutation = (
  options: MutationHookOptions<
    AddCommentToClaimResult,
    AddCommentToClaimVariables
  >
) => useMutation(mutation, options);
