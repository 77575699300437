import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import { carriers, carriers_carriers } from "./types/carriers";

export const CARRIERS_QUERY = gql`
  query carriers {
    carriers {
      id
      name
      description
      fleetEqunr
    }
  }
`;

export type Carrier = carriers_carriers;
export class CarriersQuery extends Query<carriers> {}
