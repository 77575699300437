import React, { Component } from "react"
import Spinner from '@atlaskit/spinner';

interface IPageLoadingIndicatorProps {
  title?: string
}

interface IPageLoadingIndicatorState {

}

export default class PageLoadingIndicator extends Component<IPageLoadingIndicatorProps, IPageLoadingIndicatorState> {
    constructor(props: IPageLoadingIndicatorProps) {
        super(props)
    }

    render() {
        return (
            <div style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", height: "50vh" }}>
                <Spinner size={"large"} />
                <div style={{ marginLeft: "1em" }}>{this.props.title || "Daten werden geladen..."}</div>
            </div>
        )
    }
}
