import React, { useEffect, useState } from "react";
import { GridColumn } from "@atlaskit/page";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { MaterialUnit } from "../../../api/graphql/graphql-global-types";
import { FormSection } from "../FormSection";
import { IMaterialSection } from "./types/claim-types";
import Button from "@atlaskit/button";
import AddIcon from "@atlaskit/icon/glyph/add";
import { MaterialSectionUnit } from "./MaterialFormSectionUnit";
import Spinner from "@atlaskit/spinner";
import { MATERIALS_QUERY } from "../../../api/graphql/queries/materials";
import { OENORM_QUERY } from "../../../api/graphql/queries/oenorms";
import { useQuery } from "react-apollo-hooks";
import { getMaterialOptions } from "../../../utils/material-options";

interface IMaterialFormSectionProps {
  sections: IMaterialSection[];
  onChange: (sections: IMaterialSection[]) => void;
  disabled?: boolean;
}

export const MaterialFormSection: React.VFC<IMaterialFormSectionProps> = (
  props
) => {
  const { sections, onChange, disabled } = props;
  const { t } = useTranslation();

  const materialUnitOptions = getMaterialUnitOptions(t);
  const { data: materials } = useQuery(MATERIALS_QUERY);
  const { data: oenorms } = useQuery(OENORM_QUERY);

  const onChangeMaterialSection = (
    materialSection: IMaterialSection,
    sectionIndex: number
  ) => {
    const changedMaterialSections = sections.map(
      (section: IMaterialSection, index: number) => {
        if (sectionIndex === index) {
          return materialSection;
        }
        return section;
      }
    );
    onChange(changedMaterialSections);
  };

  const addMaterialSection = () => {
    const materialSections = [
      ...sections,
      {
        materialId: null,
        comment: "",
        quantity: null,
        unit: null,
      },
    ];
    onChange(materialSections);
  };

  const removeMaterialSection = (sectionIndex: number) => {
    const materialSections = sections.filter(
      (_section: IMaterialSection, index: number) => index !== sectionIndex
    );
    onChange(materialSections);
  };

  useEffect(() => {
    if (!sections.length) {
      addMaterialSection();
    }
  }, []);

  const [materialOptions, setMaterialOptions] = useState<any>([]);

  useEffect(() => {
    if (materials?.materials && oenorms?.oenorms) {
      setMaterialOptions(
        getMaterialOptions(materials.materials, oenorms.oenorms)
      );
    }
  }, [materials, oenorms]);

  return (
    <FormSection title={t("create_claim.form.material_section.title")}>
      {sections.map((section, index) => {
        return (
          <>
            {materialOptions.length > 0 ? (
              <MaterialSectionUnit
                materials={materialOptions}
                oenorms={oenorms.oenorms}
                disabled={disabled}
                materialUnitOptions={materialUnitOptions}
                section={section}
                index={index}
                onChange={onChangeMaterialSection}
                onRemove={removeMaterialSection}
              />
            ) : (
              <div
                style={{
                  flex: 1,
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "4em",
                }}
              >
                <Spinner size={"large"} />
              </div>
            )}

            <hr style={{ border: "2px solid #F4F5F7", width: "100%" }} />
          </>
        );
      })}
      <GridColumn md={12}>
        <div style={{ paddingTop: "8px" }}>
          <Button
            iconBefore={<AddIcon label={t("general.add")} />}
            appearance="primary"
            onClick={addMaterialSection}
            isDisabled={disabled}
          >
            {t("create_claim.form.material_section.add_material")}
          </Button>
        </div>
      </GridColumn>
    </FormSection>
  );
};

export interface IMaterialUnitOption {
  label: string;
  value: MaterialUnit;
  subtitle?: string | null;
}

const getMaterialUnitOptions = (
  t: i18next.TFunction
): IMaterialUnitOption[] => {
  return [
    {
      label: t("create_claim.form.material_section.unit_options.percentage"),
      value: MaterialUnit.PERCENTAGE,
    },
    {
      label: t("create_claim.form.material_section.unit_options.tons"),
      value: MaterialUnit.TO,
    },
    {
      label: t("create_claim.form.material_section.unit_options.kg"),
      value: MaterialUnit.KG,
    },
  ];
};
