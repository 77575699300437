import styled from "styled-components"

interface CardFrameContentProps {
  isHoverable?: boolean
}

export default styled.div`
  padding: 0 8px 8px 8px;

  &:hover {
    background-color: ${(props: CardFrameContentProps) => props.isHoverable ? "#FAFBFC" : "inherit"};
    border-radius: 3px;
    cursor: ${(props: CardFrameContentProps) => props.isHoverable ? "pointer" : "inherit"};
  }
`
