import React from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import { ButtonGroup } from "@atlaskit/button";

interface IDialogProps {
  isOpen: boolean;
  title: string;
  buttons: JSX.Element[];
  onClose: () => void;
}

export const Dialog: React.FunctionComponent<IDialogProps> = (props) => {
  const { isOpen, title, buttons, onClose, children } = props;

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} heading={title}>
          <ModalBody>{children}</ModalBody>

          <ModalFooter style={{ display: "flex", justifyContent: "end" }}>
            <ButtonGroup>{buttons}</ButtonGroup>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};
