import * as React from "react"
import styled from "styled-components"

const Fancy = styled.div`
  text-align: center;
  overflow: hidden;
  color: #6B778C;
  padding-top: 0.5em;
  margin-bottom: 0.5em;

  font-size: 0.8571428571428571em;
  font-weight: 600;
`

const Inner = styled.span`
  display: inline-block;
  position: relative;
  
  &:before, &:after {
    content: " ";
    position: absolute;
    height: 1px;
    border-bottom: 1px solid #6B778C;
    top: 50%;
    width: 6000px;
    opacity: 0.6;
  }

  &:before {
    right: 100%;
    margin-right: 15px;
  }
  
  &:after {
    left: 100%;
    margin-left: 15px;
  }
`

export default (props: any) => <Fancy><Inner>{props.children}</Inner></Fancy>
