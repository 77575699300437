import { GridColumn } from "@atlaskit/page";
import React from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@atlaskit/checkbox";
import Textfield from "@atlaskit/textfield";
import { Field, HelperMessage } from "@atlaskit/form";

export interface INumberSelection {
  name: string;
  isSelected: boolean;
  value: number;
}

interface ICustomCheckboxNumberFieldProps {
  translation: string;
  selection: INumberSelection;
  onChange: (selection: INumberSelection) => void;
  disabled?: boolean;
}

export const CustomCheckboxNumberField: React.VFC<
  ICustomCheckboxNumberFieldProps
> = (props) => {
  const { t } = useTranslation();
  const { translation, selection, onChange, disabled } = props;
  const checkboxTranslation = t(`${translation}.label`);
  const helperTranslation = t(`${translation}.helper`);

  const onChangeCheckboxSelect = (event: any) => {
    const changedSelection = {
      ...selection,
      isSelected: event.target.checked,
    };
    onChange(changedSelection);
  };

  const onChangeNumber = (event: any) => {
    const changedSelection = {
      ...selection,
      value: Number(event.target.value),
    };
    onChange(changedSelection);
  };

  return (
    <GridColumn medium={12}>
      <Field
        name={selection.name}
        layout="max-width"
        style={{ width: "100%" }}
        fullWidth
      >
        {({
          fieldProps: { id, ...rest },
          error,
        }: {
          fieldProps: any;
          error: any;
        }) => (
          <>
            <div style={{ display: "flex" }}>
              <div style={{ width: "180px", paddingTop: "6px" }}>
                <Checkbox
                  name={selection.name}
                  isChecked={selection.isSelected}
                  disabled={disabled}
                  onChange={onChangeCheckboxSelect}
                  label={checkboxTranslation}
                />
              </div>
              <div style={{ flexGrow: 1 }}>
                <Textfield
                  style={{ height: "30px" }}
                  type="number"
                  defaultValue={selection.value}
                  onChange={onChangeNumber}
                  isDisabled={disabled}
                />
                {helperTranslation && (
                  <HelperMessage>{helperTranslation}</HelperMessage>
                )}
              </div>
            </div>
          </>
        )}
      </Field>
    </GridColumn>
  );
};
