import React, { Fragment, useEffect } from "react";
import { useQuery } from "react-apollo-hooks";
import orderSearch from "../../../../../api/graphql/queries/orderSearch";
import { IOrderSearchParams } from "../../types/order-search-params";
import { OrderTable } from "./table/OrderTable";
import { useTranslation } from "react-i18next";
import Spinner from "@atlaskit/spinner";
import { toast } from "react-toastify";

interface IOrderViewProps {
  searchParams: IOrderSearchParams;
}

export const OrderView: React.FC<IOrderViewProps> = (props) => {
  const { searchParams } = props;
  const { t } = useTranslation();
  const {
    data: searchResults,
    error,
    loading,
  } = useQuery(orderSearch, {
    fetchPolicy: "no-cache",
    variables: {
      ...searchParams,
    },
    errorPolicy: "all",
  });

  useEffect(() => {
    if (error) {
      toast.error(t("create_claim.search.result.error"));
    }
  }, [error]);

  return (
    <Fragment>
      {loading && (
        <div
          style={{
            flex: 1,
            justifyContent: "center",
            display: "flex",
            marginTop: "4em",
          }}
        >
          <Spinner size={"large"} />
        </div>
      )}
      {searchResults && searchResults.searchOrders && !error && (
        <OrderTable orders={searchResults.searchOrders} />
      )}
    </Fragment>
  );
};
