import React from "react";
import AtlaskitSelect from "@atlaskit/select";
import { useTranslation } from "react-i18next";

interface IOption<K> {
  value: K;
  label: string;
}

interface ISelectProps<T, K> {
  value: T | undefined;
  onChange: (value: T | undefined) => void;
  toOption: (value: T) => IOption<K>;
  options: T[];
  placeholder?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
}

export const Select = <T, K extends string | number | boolean>(
  props: ISelectProps<T, K>
) => {
  const { t } = useTranslation();
  const {
    value,
    onChange,
    toOption,
    options: rawOptions,
    placeholder,
    isClearable,
    isDisabled,
  } = props;

  const options = rawOptions.map(toOption);

  return (
    <AtlaskitSelect
      value={value ? toOption(value) : undefined}
      options={options}
      isClearable={isClearable}
      isDisabled={isDisabled}
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
      }}
      placeholder={placeholder || t("component.select.placeholder")}
      onChange={(selected: IOption<K> | null) => {
        if (selected == null) return onChange(undefined);
        const value = rawOptions.find(
          (raw) => toOption(raw).value === selected.value
        );
        onChange(value);
      }}
    />
  );
};
