import React from "react";
import Button from "@atlaskit/button";
import { useTranslation } from "react-i18next";
import { Dialog } from "./Dialog";

interface IConfirmDialogProps {
  isOpen: boolean;
  title: string;
  cancelText?: string;
  confirmText?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const ConfirmDialog: React.FunctionComponent<IConfirmDialogProps> = (
  props
) => {
  const { t } = useTranslation();
  const {
    isOpen,
    title,
    cancelText,
    confirmText,
    isLoading,
    isDisabled,
    onSubmit,
    onClose,
    children,
  } = props;

  const buttons = [
    <Button appearance="secondary" onClick={onClose}>
      {cancelText || t("cancel")}
    </Button>,
    <Button
      appearance="primary"
      onClick={onSubmit}
      isLoading={isLoading}
      isDisabled={isDisabled}
    >
      {confirmText || t("components.assign_role_dialog.on_submit")}
    </Button>,
  ];

  return (
    <Dialog isOpen={isOpen} title={title} onClose={onClose} buttons={buttons}>
      {children}
    </Dialog>
  );
};
