import { IDivisionOption } from "../../../../api/graphql/interfaces/OptionsInterfaces";

const BIOGENE_ABFAELLE = "ZGl2OjdiY2RlOWI5NzQ="
const DEPONIERBARE_ABFAELLE = "ZGl2OmRlZTM0MzJjNTc="
const GEFAEHRLICHE_ABFAELLE = "ZGl2OjUzNGUzZjk0YzY="
const SIEDLUNGS_ABFAELLE = "ZGl2OjY1NDFmNzk0NDg="
const BATTERIEN = "ZGl2OmRhZDExNmFiMDg="
const EAG = "ZGl2OjM1MDdkMjk3ZjI="
const KUNSTSTOFFE = "ZGl2OmVmNWYzM2U4OTM="
const METALLE = "ZGl2OjA2NDk0NTllZmY="
const ALTHOLZ_PAPIER = "ZGl2OmQ2YjVlNjE3Yzg="
const SONSTIGE_WERTSTOFFE = "ZGl2OmNjMmUwN2Y2MDk="

const DIVISION_MAPPING = [
  ["10000", BIOGENE_ABFAELLE], // Biogene Abfälle
  ["11000", DEPONIERBARE_ABFAELLE], // Deponierbare Abfälle
  ["13000", GEFAEHRLICHE_ABFAELLE], // Gefährliche Abfälle
  ["14000", SIEDLUNGS_ABFAELLE], // Siedlungsabfälle

  ["1200012000", BATTERIEN], // Batterien

  ["12000", EAG], // EAG
  ["23200", EAG],
  ["12200", EAG],
  ["12300", EAG],
  ["12400", EAG],
  ["12500", EAG],
  ["12600", EAG],

  ["1500015700", KUNSTSTOFFE], // Kunststoffe

  ["1500015400", METALLE], // Metalle
  ["1500015401", METALLE],

  ["1500015100", ALTHOLZ_PAPIER], // Altholz/-papier
  ["1500015200", ALTHOLZ_PAPIER],
  ["1500015600", ALTHOLZ_PAPIER],
  ["1500015201", ALTHOLZ_PAPIER],

  ["15000", SONSTIGE_WERTSTOFFE], // Sonstige Wertstoffe zur stofflichen Verwertung
  ["15300", SONSTIGE_WERTSTOFFE],
  ["15500", SONSTIGE_WERTSTOFFE],
  ["15800", SONSTIGE_WERTSTOFFE],
  ["15900", SONSTIGE_WERTSTOFFE],
]

export default (prdha: String, divisions: IDivisionOption[]): IDivisionOption | undefined => {
  for (const [key, divisionId] of DIVISION_MAPPING) {
    if (prdha.startsWith(key)) {
      return divisions.filter(division => division.id === divisionId).shift()
    }
  }
  return undefined
}