import PageHeader from "@atlaskit/page-header";
import Spinner from "@atlaskit/spinner";
import __ from "lodash";
import React, { Fragment } from "react";
import { useQuery } from "react-apollo-hooks";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { CARRIERS_QUERY } from "../../../api/graphql/queries/carriers";
import { PLANTS_QUERY } from "../../../api/graphql/queries/plants";
import { IBaseProps } from "../../../utils/BaseProps";
import { CreateClaimFormPage } from "./partials/create-claim/CreateClaimFormPage";
import { OrderViewPage } from "./partials/order-search/OrderViewPage";
import { useGetClaimByOrderIdQuery } from "../../../api/graphql/queries/getClaimByOrderId";
import { toast } from "react-toastify";

interface ICreateClaimPageProps extends IBaseProps {}

export const CreateClaimPage: React.VFC<ICreateClaimPageProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location: any = useLocation();

  const { data: claim, loading } = useGetClaimByOrderIdQuery({
    variables: { orderId: location.state?.order.orderNr },
    skip: !location.state,
  });

  if (claim && claim.getClaimByOrderId) {
    history.push(`/claims/${claim.getClaimByOrderId.number}`);
    toast.success(t("create_claim.already_existing"));
  }

  const { data: plants } = useQuery(PLANTS_QUERY);
  const { data: carriers } = useQuery(CARRIERS_QUERY);
  const id = __.get(props, "match.params.id");

  if (id && location.state?.order) {
    return <CreateClaimFormPage order={location.state.order} />;
  }

  return (
    <Fragment>
      {loading && <Spinner size={"large"} />}
      {!loading && (
        <>
          <PageHeader>{t("create_claim.title")}</PageHeader>
          {(!plants || !carriers) && (
            <div
              style={{
                flex: 1,
                justifyContent: "center",
                display: "flex",
                marginTop: "4em",
              }}
            >
              <Spinner size={"large"} />
            </div>
          )}
          {plants?.plants && carriers?.carriers && (
            <OrderViewPage
              plants={plants.plants}
              carriers={carriers.carriers}
            />
          )}
        </>
      )}
    </Fragment>
  );
};
