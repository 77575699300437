import React, { FC } from "react";
import { Tickets_tickets } from "../../../../api/graphql/queries/types/Tickets";
import { Lane } from "../../../partials/lane/Lane";
import { TicketLaneItem } from "./TicketLaneItem";

interface ITicketLane {
  laneName: string;
  tickets: Tickets_tickets[] | null;
  sublanes: { [key: string]: (ticket: Tickets_tickets) => boolean };
}

export const TicketLane: FC<ITicketLane> = (props) => {
  const { laneName, tickets, sublanes } = props;

  const renderItem = (ticket: Tickets_tickets) => (
    <TicketLaneItem key={ticket.id} ticket={ticket} />
  );

  const isInSublane = (sublane: string) => (ticket: Tickets_tickets) => 
    sublanes[sublane](ticket);

  return (
    <Lane
      title={laneName}
      items={tickets ?? []}
      sublanes={Object.keys(sublanes)}
      renderItem={renderItem}
      isInSublane={isInSublane}
    />
  );
};
