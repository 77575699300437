import * as React from "react"
import { IBaseProps } from "../../../utils/BaseProps"
import { withTranslation } from "react-i18next";
import loadMasterData, { MasterDataQuery } from "../../../api/graphql/queries/loadMasterData";
import { isNotNullOrUndefined } from "../../../utils/isNotNullOrUndefined";
import { MasterData } from "../../../api/graphql/queries/types/MasterData";
import TicketSearchForm from './TicketSearchForm';

interface ITicketSearchProps extends IBaseProps {

}

interface ITicketSearchState {

}


class TicketSearch extends React.Component<ITicketSearchProps, ITicketSearchState> {

      constructor(props: ITicketSearchProps) {
            super(props);
            this.state = {}
      }

      private renderForm = (data: MasterData) => {
            const oenorms = data.oenorms && data.oenorms.filter(isNotNullOrUndefined) || []
            const materials = data.materials && data.materials.filter(isNotNullOrUndefined) || []
            const divisions = data.divisions && data.divisions.filter(isNotNullOrUndefined) || []
            // const partners = data.partners && data.partners.filter(isNotNullOrUndefined) || []

            const oenormOptions = oenorms.map(o => {
                  return {
                        ...o,
                        label: `${o.code} - ${o.title}`,
                        value: o.id
                  }
            })

            const materialOptions = materials.map(m => {
                  const oenorm = oenorms.find(o => o.id === m.oenormId)
                  return {
                        ...m,
                        label: `${m.matnr} - ${m.title}`,
                        value: m.id,
                        subtitle: oenorm ? `${oenorm.code} - ${oenorm.title}` : null
                  }
            })

            const divisionOptions = divisions.map(d => {
                  return {
                        ...d,
                        label: `${d.title}`,
                        value: d.id
                  }
            })

            // const partnerOptions = partners.map(p => {
            //       return {
            //             ...p,
            //             label: `${p.code} - ${p.name}`,
            //             subtitle: [`${p.street} ${p.number}`, `${p.postal} ${p.city}`],
            //             value: p.id
            //       }
            // })

            return <TicketSearchForm
                  oenorms={oenormOptions}
                  materials={materialOptions}
                  divisions={divisionOptions}
                  // partners={partnerOptions}
                  // partners={[]}
            />
      }

      public render() {
            return (
                  <div>
                        <MasterDataQuery
                              query={loadMasterData}
                              fetchPolicy={'cache-first'}
                        >
                              {({ data }) => (data && this.renderForm(data))}
                        </MasterDataQuery>
                  </div>
            )
      }
}

export default withTranslation()(TicketSearch)
