import { gql } from "apollo-boost"

export default gql`
  fragment PlantFragment on Plant {
    id
    name
    city
    description
    facility
    number
    postal
    street
    tech
  }
`