import { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@atlaskit/spinner";

interface IListProps<T> {
  items: T[];
  loading: boolean;
  error: boolean;
  emptyText?: string;
  errorText?: string;
  renderItem: (item: T) => JSX.Element;
}

export const List = <T,>(props: IListProps<T>): ReactElement | null => {
  const { t } = useTranslation();
  const { items, loading, error, emptyText, errorText, renderItem } = props;

  const wrap = (element: ReactNode) => (
    <div style={{ textAlign: "center" }}>{element}</div>
  );

  if (loading) return wrap(<Spinner />);
  if (error) return wrap(errorText ?? t("components.list.error"));
  if (items.length <= 0) return wrap(emptyText ?? t("components.list.empty"));

  return <>{items.map(renderItem)}</>;
};
