import React, { useContext, VFC } from "react";
import __ from "lodash";
import { AuthConsumer, AuthContext } from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { useTicketsQuery } from "../../../api/graphql/queries/tickets";
import { Role } from "../../../api/graphql/graphql-global-types";
import { TicketList } from "./partials/TicketList";
import PageHeader from "@atlaskit/page-header";
import { useParams } from "react-router-dom";
import { useClaimsQuery } from "../../../api/graphql/queries/claims";
import { ClaimList } from "./partials/ClaimList";

interface ITicketListPageProps {}

export const TicketListPage: VFC<ITicketListPageProps> = (props) => {
  const { t } = useTranslation();
  const params = useParams() as any;
  const role = Object.values(Role).find(
    (role) => role.toUpperCase() === String(params.role).toUpperCase()
  );
  const { userId } = useContext(AuthContext);

  const ticketsQuery = useTicketsQuery();
  const claimsQuery = useClaimsQuery();

  const filteredTickets = (ticketsQuery.data?.tickets || [])
    .filter((ticket) => !role || ticket.assignedRole === role)
    .filter((ticket) => ticket.status !== "COMPLETED");

  const getMyTickets = (userId: string | undefined) =>
    filteredTickets.filter((ticket) => ticket?.assignedUser?.id === userId);
  const getOtherTickets = (userId: string | undefined) =>
    filteredTickets.filter((ticket) => ticket?.assignedUser?.id !== userId);

  const claims =
    claimsQuery.data?.claims.filter(
      (claim) => claim.assignedRole === role && claim.assignedUser.id === userId
    ) ?? [];

  return (
    <AuthConsumer>
      {({ userId }) => (
        <>
          <PageHeader>
            {t("tickets.title")} für {__.capitalize(role)}
          </PageHeader>

          <h3>{t("tickets.list.my_tickets")}</h3>
          <TicketList
            tickets={getMyTickets(userId)}
            loading={ticketsQuery.loading}
            error={!!ticketsQuery.error}
          />

          <h3>{t("tickets.list.other_tickets")}</h3>
          <TicketList
            tickets={getOtherTickets(userId)}
            loading={ticketsQuery.loading}
            error={!!ticketsQuery.error}
          />

          <hr
            style={{
              marginTop: "32px",
              marginBottom: "32px",
              border: "1px #aaa solid",
            }}
          />

          <h3>{t("tickets.list.claims")}</h3>
          <ClaimList
            claims={claims}
            loading={claimsQuery.loading}
            error={!!claimsQuery.error}
          />
        </>
      )}
    </AuthConsumer>
  );
};
