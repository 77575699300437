import * as React from "react"
import __ from "lodash"
import { withTranslation } from "react-i18next"
import { Grid, GridColumn } from "@atlaskit/page"
import { CardFrame } from "@atlaskit/media-ui"
import CardFrameContent from "../../../../partials/CardFrameContent"
import { IBaseProps } from "../../../../../utils/BaseProps"
import { Ticket_ticket_hazardousWaste as Specifics } from "../../../../../api/graphql/queries/types/Ticket"
import Headline from "../../partials/Headline"
import ShowText from "../../fields/ShowText"
import ShowBoolean from "../../fields/ShowBoolean"

interface IHazardousWasteProps extends IBaseProps {
  specifics: Specifics
}

interface IHazardousWasteState {

}

class HazardousWaste extends React.Component<IHazardousWasteProps, IHazardousWasteState> {
  state: IHazardousWasteState

  constructor(props: IHazardousWasteProps) {
    super(props)

    this.state = {
    }
  }

  private renderCompanyAndProcess() {
    const { t, specifics } = this.props
    return (
      <React.Fragment>
        <GridColumn medium={12}>
          <Headline>{t('company_process')}</Headline>
        </GridColumn>
        <GridColumn medium={12}>
          <ShowText name="production" translation="tickets.fields.company_process.production" value={specifics.production} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="process" translation="tickets.fields.company_process.process" value={specifics.process} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="process_selection" translation="tickets.fields.material_description.process_selection" value={t(`tickets.fields.material_description.process_selection.code.${specifics.processSelection}`)} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowBoolean name="sample_available" translation="tickets.fields.company_process.sample_available" value={specifics.isSampleAvailable} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="sample_creator" translation="tickets.fields.company_process.sample_creator" value={t(`tickets.fields.material_description.sample_creator.code.${specifics.sampleCreator}`)} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="sample_recipient" translation="tickets.fields.company_process.sample_recipient" value={specifics.sampleRecipient} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="sample_received" translation="tickets.fields.company_process.sample_received" value={specifics.sampleReceivedAt} />
        </GridColumn>
      </React.Fragment>
    )
  }

  private renderMaterialDescription() {
    const { t, specifics } = this.props
    return (
      <React.Fragment>
        <GridColumn medium={12}>
          <Headline>{t('material_description')}</Headline>
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="smell" translation="tickets.fields.material_description.smell" value={t(`tickets.fields.material_description.smell.code.${specifics.smell}`)} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="consistency" translation="tickets.fields.material_description.consistency" value={specifics.consistency} />
        </GridColumn>
        <GridColumn medium={12}>
          <ShowBoolean name="analysis" translation="tickets.fields.material_description.analysis" value={specifics.isAnalyticsAvailable} />
        </GridColumn>
      </React.Fragment>
    )
  }

  private renderAdditional() {
    const { t, specifics } = this.props
    return (
      <React.Fragment>
        <GridColumn medium={12}>
          <Headline>{t('additional')}</Headline>
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="comment" translation="tickets.fields.material_description.comment" value={specifics.comment} />
        </GridColumn>
      </React.Fragment>
    )
  }

  public render() {
    const { t, specifics } = this.props
    console.log(specifics)
    return (
      <CardFrame text={t('tickets.create.fields.gefaehrliche_abfaelle.title')}>
        <CardFrameContent>
          <Grid layout={"max-width"}>
            <GridColumn medium={12}>
              <Headline>{t('generic')}</Headline>
            </GridColumn>
            <GridColumn medium={6}>
              <ShowText name="waste_description" translation="tickets.create.fields.waste_description" value={specifics.description} />
            </GridColumn>
            {this.renderCompanyAndProcess()}
            {this.renderMaterialDescription()}
            {this.renderAdditional()}
          </Grid>
        </CardFrameContent>
      </CardFrame>
    )
  }
}

export default withTranslation()(HazardousWaste)
