import * as React from "react"
import { withTranslation } from "react-i18next"
import { Field } from "@atlaskit/form"
import { IBaseProps } from "../../../../utils/BaseProps"
import FieldValue from "../partials/FieldValue"
import { MetaData_materials as MaterialType } from "../../../../api/graphql/queries/types/MetaData"
import { MetaData_oenorms as OenormType } from "../../../../api/graphql/queries/types/MetaData"
import { transformOptionLabel } from "../fields/EditSelect"
import { toSelectOption as materialsToSelectOption } from "../../../../api/graphql/fragments/Material"

interface IShowMaterialProps extends IBaseProps {
  name: String
  translation: String
  material?: MaterialType | null
  oenorm?: OenormType | null
}

export default withTranslation()((props: IShowMaterialProps) => (
  <Field name={props.name} label={props.t(`${props.translation}.title`)} helperText={props.t(`${props.translation}.helper`)}>
    {() => {
      const option = props.material ? materialsToSelectOption([props.material], props.oenorm ? [props.oenorm] : []).shift() : undefined
      return (
        <FieldValue>
          {option ? transformOptionLabel(option) : "-"}
        </FieldValue>
      )
    }}
  </Field>
))