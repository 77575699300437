import * as React from "react"
import __ from "lodash"
import { withTranslation } from "react-i18next"
import { Grid, GridColumn } from "@atlaskit/page"
import { CardFrame } from "@atlaskit/media-ui"
import GridSpacer from "../partials/GridSpacer"
import CardFrameContent from "../../../partials/CardFrameContent"
import Headline from "../partials/Headline"
import ShowNumber from "../fields/ShowNumber"
import ShowText from "../fields/ShowText"
import ShowPlant from "../fields/ShowPlant"
import ShowMaterial from "../fields/ShowMaterial"
import ShowOenorm from "../fields/ShowOenorm"
import Highlight from "../partials/Highlight"
import { IBaseProps } from "../../../../utils/BaseProps"
import { Ticket_ticket_utilizations as UtilizationType } from "../../../../api/graphql/queries/types/Ticket"
import { FACILITY_GROUPS } from "../../../../api/facility-groups"

interface ITicketUtilizationsProps extends IBaseProps {
  utilizations: Array<UtilizationType>
}

interface ITicketUtilizationsState {

}

class TicketUtilizations extends React.Component<ITicketUtilizationsProps, ITicketUtilizationsState> {
  state: ITicketUtilizationsState

  constructor(props: ITicketUtilizationsProps) {
    super(props)

    this.state = {
    }
  }

  public render() {
    const { utilizations, t } = this.props
    return (
      <React.Fragment>
        {utilizations.map(utilization => (
          <div key={utilization.id}>
            <GridColumn medium={12}>
              <GridSpacer />
            </GridColumn>
            <GridColumn medium={12}>
              <CardFrame text={t('tickets.detail.sections.utilization.title')}>
                <CardFrameContent>
                  <Grid layout="max-width">
                    <GridColumn medium={4}>
                      <Grid layout="max-width">
                        <GridColumn medium={5}>
                          <Headline>{t('tickets.utilization.modal.section.adjustments.title')}</Headline>
                        </GridColumn>
                        <GridColumn medium={5}>
                          <ShowMaterial
                            name="material"
                            translation="tickets.utilization.modal.section.adjustments.fields.material"
                            material={utilization.material}
                            oenorm={utilization.oenorm} />
                          <ShowOenorm
                            name="oenorm"
                            translation="tickets.utilization.modal.section.adjustments.fields.oenorm"
                            oenorm={utilization.oenorm} />
                          <ShowText
                            name="materialRemark"
                            translation="tickets.utilization.modal.section.adjustments.fields.material_remark"
                            value={__.get(utilization, "materialRemark", "-")} />
                        </GridColumn>
                      </Grid>
                    </GridColumn>

                    {utilization.notPossibleDisposal && (
                      <GridColumn medium={4}>
                        <Grid layout="max-width">
                          <GridColumn medium={4}>
                            <Headline>
                              {t(
                                "tickets.utilization.modal.section.disposal_possibility.fields.not_possible_disposal.title"
                              )}
                            </Headline>
                          </GridColumn>
                          <GridColumn medium={4}>
                            <ShowText
                              name="notPossibleDisposalReason"
                              translation="tickets.utilization.modal.section.disposal_possibility.fields.not_possible_disposal_reason"
                              value={t(
                                `tickets.utilization.modal.section.disposal_possibility.reasons.${utilization.notPossibleDisposalReason}`
                              )}
                            />
                            <GridSpacer />
                          </GridColumn>
                          <GridColumn medium={4}>
                            <ShowText
                              name="notPossibleDisposalNote"
                              translation="tickets.utilization.modal.section.disposal_possibility.fields.not_possible_disposal_note"
                              value={__.get(utilization, "notPossibleDisposalNote", "-")}
                            />
                            <GridSpacer />
                          </GridColumn>
                        </Grid>
                      </GridColumn>
                    )}
                    {!utilization.notPossibleDisposal && (
                      <GridColumn medium={4}>
                        <Grid layout="max-width">
                          <GridColumn medium={4}>
                            <Headline>{t('tickets.utilization.modal.section.monetary.title')}</Headline>
                          </GridColumn>
                          <GridColumn medium={2}>
                            {utilization.costs ? (
                              <ShowNumber
                                name="costs"
                                translation="tickets.utilization.modal.section.monetary.fields.costs"
                                value={utilization.costs}
                                precision={2}
                                postfix=" €" />
                            ) : (utilization.compensation ? (
                              <ShowNumber
                                name="compensation"
                                translation="tickets.utilization.modal.section.monetary.fields.compensation"
                                value={utilization.compensation}
                                precision={2}
                                postfix=" €" />
                            ) : (
                              <ShowText
                                name="pricelist"
                                translation="tickets.utilization.modal.section.monetary.fields.pricelist"
                                value="⚠️ Preis lt. Preisliste verwenden"
                                />
                            ))}
                            <GridSpacer />
                          </GridColumn>
                          <GridColumn medium={2}>
                            <ShowText
                              name="unit"
                              translation="tickets.create.fields.unit"
                              value={`€/${t(`units.code.${utilization.selectedUnit}`)}`}
                            />
                            <GridSpacer />
                          </GridColumn>
                          {`${__.get(utilization, "material.pks.cost")}`.replace(".", ",") === `${utilization.costs}`.replace(".", ",") && <GridColumn medium={4}>
                            <ShowText
                              name="pks"
                              translation="tickets.detail.sections.material.pks"
                              value={__.get(utilization, "material.pks")
                              ? (<div>
                                  {__.get(utilization, "material.pks.ktext")}
                                  {__.get(utilization, "material.pks.cost") && (<>
                                    <br/><Highlight>{`${__.get(utilization, "material.pks.cost") || ""}`.replace(".", ",")} €</Highlight>
                                  </>)}
                                </div>)
                              : (<div>-</div>)}
                            />
                          </GridColumn>}
                          <GridColumn medium={4}>
                            <ShowText
                              name="transport"
                              translation="tickets.utilization.modal.section.logistics.fields.transportation"
                              value={t(`units.transportation.${utilization.transportation}`)}
                              emptyIndicator
                            />
                          </GridColumn>
                        </Grid>
                      </GridColumn>
                    )}

                    <GridColumn medium={4}>
                      <Grid layout="max-width">
                        <GridColumn medium={4}>
                          <Headline>{t('tickets.utilization.modal.section.logistics.title')}</Headline>
                        </GridColumn>
                        <GridColumn medium={4}>
                          <ShowText
                            name="standort"
                            translation="tickets.utilization.modal.section.logistics.fields.standort"
                            value={utilization.standort
                              ? FACILITY_GROUPS[utilization.standort].name
                              : "-"} />
                        </GridColumn>
                        <GridColumn medium={4}>
                          <ShowPlant
                            name="plant"
                            translation={!utilization.standort ? "tickets.utilization.modal.section.logistics.fields.plant" : "tickets.utilization.modal.section.logistics.fields.plant_planned"}
                            plant={utilization.plant} />
                        </GridColumn>
                        <GridColumn medium={2}>
                          <ShowNumber
                            name="logisticCosts"
                            translation="tickets.utilization.modal.section.logistics.fields.costs"
                            value={utilization.logisticCosts}
                            postfix=" €" />
                        </GridColumn>
                        <GridColumn medium={2}>
                          <ShowText
                            name="streckenabholung"
                            translation="tickets.utilization.modal.section.logistics.fields.streckenabholung"
                            value={utilization.streckenabholung ? "Ja" : "Nein"} />
                        </GridColumn>
                      </Grid>
                    </GridColumn>

                  </Grid>
                </CardFrameContent>
              </CardFrame>
            </GridColumn>
          </div>
        ))}
      </React.Fragment>
    )
  }
}

export default withTranslation()(TicketUtilizations)
