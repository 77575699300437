import * as React from "react"
import {IBaseProps} from "../../../../utils/BaseProps"
import {withTranslation} from "react-i18next";
import BasicStructure from "./basic-structure";
import {Grid, GridColumn} from "@atlaskit/page";
import {Field} from "@atlaskit/form"
import Select from "@atlaskit/select"
import Textfield from "@atlaskit/textfield"
import TextArea from "@atlaskit/textarea"
import {IYesNoOption, yesNoOptions} from "./deponierbare-abfaelle";
import {IInfoHandler, WasteInputType} from "../CreateTicketForm";
import {IDescriptionImageInfoHandler} from "./description-image";
import renderAdditional from "../common/additional"
import renderCompanyAndProcess from "../common/company-process"
import {Mixture} from "../../../../api/graphql/graphql-global-types"
import {IMixtureOption, mixtureOptions} from "./kunststoffe"
import {UnwrapGrid} from "../CreateTicketForm"
import UploadHint from "../common/upload-hint"

interface IVerwertungProps extends IBaseProps {
  infoHandler: IInfoHandler
}

interface IVerwertungState {
  produced: string
  process: string
  originalUse: string

  analysis: IYesNoOption | null
  analysis_additional: string | null
  detail: string
  foreign_matter_exists: IYesNoOption | null
  foreign_matter_exists_additional: string | null
  is_licenced: IYesNoOption | null
  is_licenced_additional: string | null
  mixture: IMixtureOption | null

  comment: string
}

class Verwertung extends React.Component<IVerwertungProps, IVerwertungState> {

  private descriptionImageInfoHandler: IDescriptionImageInfoHandler = {}

  constructor(props: IVerwertungProps) {
    super(props);

    this.props.infoHandler.getInfo = this.getInfo

    this.state = {
      produced: '',
      process: '',
      originalUse: '',

      analysis: null,
      analysis_additional: null,
      detail: '',
      foreign_matter_exists: null,
      foreign_matter_exists_additional: null,
      is_licenced: null,
      is_licenced_additional: null,
      mixture: null,

      comment: '',
    }
  }

  componentWillReceiveProps(nextProps: Readonly<IVerwertungProps>, nextContext: any): void {
    nextProps.infoHandler.getInfo = this.getInfo
  }

  private getInfo = () => {
    const descriptionImageInfo = this.descriptionImageInfoHandler.getInfo && this.descriptionImageInfoHandler.getInfo()

    if (
      !descriptionImageInfo
      || descriptionImageInfo.wasteDescription.isBlank()
      || !descriptionImageInfo.filecontent
      || !descriptionImageInfo.photoAttachmentId
      || this.state.process.isBlank()
      || this.state.produced.isBlank()
      || this.state.detail.isBlank()
      || !this.state.is_licenced
      || this.state.foreign_matter_exists === null || this.state.foreign_matter_exists === undefined
    ) {
      console.log("getInfo validation failed", descriptionImageInfo)
      return null
    }


    return {
      inputType: WasteInputType.VERWERTUNG,
      description: descriptionImageInfo ? descriptionImageInfo.wasteDescription : '',
      photoAttachmentId: descriptionImageInfo ? descriptionImageInfo.photoAttachmentId : "",

      production: this.state.produced,
      process: this.state.process,
      usage: this.state.originalUse,

      isAnalyticsAvailable: this.state.analysis ? this.state.analysis.value === 'true' : null,
      isAnalyticsAvailableAdditional: this.state.analysis_additional,
      size: this.state.detail,
      hasForeignSubstance: this.state.foreign_matter_exists ? this.state.foreign_matter_exists.value === 'true' : null,
      hasForeignSubstanceAdditional: this.state.foreign_matter_exists_additional,
      isLicensed:  this.state.is_licenced.value === 'true',
      isLicensedAdditional: this.state.is_licenced_additional,
      mixture: this.state.mixture ? (this.state.mixture.value === Mixture.GEMISCH ? Mixture.GEMISCH : Mixture.SORTENREIN) : null, // Sortenrein/Gemisch
      comment: this.state.comment,
    }
  }

  private onProducedChanged = (e: any) => this.setState({produced: e.target.value})
  private onProcessChanged = (e: any) => this.setState({process: e.target.value})
  private onOriginalUseChanged = (e: any) => this.setState({originalUse: e.target.value})

  private onDetailChanged = (e: any) => this.setState({detail: e.target.value})
  private onAnalysisChanged = (o: IYesNoOption) => this.setState({analysis: o})
  private onAnalysisAdditionalChanged = (e: any) => this.setState({analysis_additional: e.target.value})
  private onForeinMatterExistsChanged = (o: IYesNoOption) => this.setState({foreign_matter_exists: o})
  private onForeinMatterExistsAdditionalChanged = (e: any) => this.setState({foreign_matter_exists_additional: e.target.value})
  private onIsLicencedChanged = (o: IYesNoOption) => this.setState({is_licenced: o})
  private onIsLicencedAdditionalChanged = (e: any) => this.setState({is_licenced_additional: e.target.value})
  private onMixtureChanged = (o: IMixtureOption) => this.setState({mixture: o})
  private onCommentChanged = (e: any) => this.setState({comment: e.target.value})

  private renderMaterialDescription = () => {
    const {t} = this.props

    return (
      <UnwrapGrid>
      <Grid layout="max-width">
        <GridColumn medium={6}>
          <Field name="analysis" label={t('tickets.create.fields.deponierbare_abfaelle.material_description.analysis')}>
          {(props: any) => (
            <Select
              options={yesNoOptions}
              onChange={this.onAnalysisChanged}
              value={this.state.analysis}
              isSearchable={false}
            />
          )}
          </Field>
          <UploadHint analysis={this.state.analysis} />
        </GridColumn>
        <GridColumn medium={6}>
          <Field name="analysis_additional" label={t('tickets.create.fields.deponierbare_abfaelle.material_description.analysis_additional')}>
            {({ fieldProps }: { fieldProps: any }) => (
              <Textfield
                {...fieldProps}
                isCompact={false}
                onChange={this.onAnalysisAdditionalChanged}
                value={this.state.analysis_additional}
              />
            )}
          </Field>
        </GridColumn>
        <GridColumn medium={12}>
          <Field name="detail" label={t('tickets.create.fields.deponierbare_abfaelle.material_description.detail')} isRequired={true}>
            {({ fieldProps }: { fieldProps: any }) => (
              <TextArea
                {...fieldProps}
                isCompact={false}
                minimumRows={1}
                onChange={this.onDetailChanged}
                value={this.state.detail}
              />
            )}
          </Field>
        </GridColumn>

        <GridColumn medium={6}>
          <Field name="foreign_matter_exists" label={t('tickets.create.fields.kunststoffe.foreign_matter_exists')} isRequired={true}>
          {(props: any) => (
            <Select
              options={yesNoOptions}
              onChange={this.onForeinMatterExistsChanged}
              value={this.state.foreign_matter_exists}
              isSearchable={false}
            />
          )}
          </Field>
        </GridColumn>
        <GridColumn medium={6}>
          <Field name="foreign_matter_exists_additional" label={t('tickets.create.fields.kunststoffe.foreign_matter_exists_additional')}>
            {({ fieldProps }: { fieldProps: any }) => (
              <Textfield
                {...fieldProps}
                isCompact={false}
                onChange={this.onForeinMatterExistsAdditionalChanged}
                value={this.state.foreign_matter_exists_additional}
              />
            )}
          </Field>
        </GridColumn>
        <GridColumn medium={6}>
          <Field name="is_licenced" label={t('tickets.create.fields.kunststoffe.is_licenced')} isRequired={true}>
          {(props: any) => (
            <Select
              options={yesNoOptions}
              onChange={this.onIsLicencedChanged}
              value={this.state.is_licenced}
              isSearchable={false}
            />
          )}
          </Field>
        </GridColumn>
        <GridColumn medium={6}>
          <Field name="is_licenced_additional" label={t('tickets.create.fields.kunststoffe.is_licenced_additional')}>
            {({ fieldProps }: { fieldProps: any }) => (
              <Textfield
                {...fieldProps}
                isCompact={false}
                onChange={this.onIsLicencedAdditionalChanged}
                value={this.state.is_licenced_additional}
              />
            )}
          </Field>
        </GridColumn>

        <GridColumn medium={6}>
          <Field name="mixture" label={t('tickets.create.fields.kunststoffe.mixture')}>
          {(props: any) => (
            <Select
              options={mixtureOptions}
              onChange={this.onMixtureChanged}
              value={this.state.mixture}
              isSearchable={false}
            />
          )}
          </Field>
        </GridColumn>
      </Grid>
      </UnwrapGrid>
    )
  }

  public render() {
    return <BasicStructure
      infoHandler={this.descriptionImageInfoHandler}
      companyAndProcess={renderCompanyAndProcess(
        this.props.t,
        this.onProducedChanged, this.state.produced,
        this.onProcessChanged, this.state.process,
        this.onOriginalUseChanged, this.state.originalUse
      )}
      materialDescription={this.renderMaterialDescription()}
      additional={renderAdditional(this.props.t, this.onCommentChanged, this.state.comment)}
      imageRequired={true}
      descriptionRequired={true}
    />
  }
}

export default withTranslation()(Verwertung)
