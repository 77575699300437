import { gql } from "apollo-boost"
import { IEditSelectOption } from '../../../components/pages/tickets/fields/EditSelect';
import { OenormFragment } from './types/OenormFragment';

export default gql`
  fragment OenormFragment on Oenorm { 
  	id
    code
    title
    ctrlLevels
    spezifikation
  }
`

export const toSelectOption = (oenorms: Array<OenormFragment>): Array<IEditSelectOption> => oenorms
.filter(o => `${o.code}`.toUpperCase() !== "XXXXX")
.map(o => ({
  value: o.id,
  label: `${o.code} ${o.spezifikation ? o.spezifikation : ""} ${o.title}`,
  title: `${o.code} ${o.spezifikation ? o.spezifikation : ""}`,
  additional: o.title || undefined
}))
