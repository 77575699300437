import { Grid, GridColumn } from "@atlaskit/page";
import { FC } from "react";
import { CardFrame } from "@atlaskit/media-ui";
import CardFrameContent from "../CardFrameContent";

interface IListItemProps {
  title: string;
  variant: "white" | "red"
}

export const ListItem: FC<IListItemProps> = (props) => {
  const { children, title, variant } = props;

  const color = variant === "white" ? undefined : "rgba(230, 72, 61, 0.2)";

  return (
    <div style={{ marginBottom: "10px" }}>
      <GridColumn medium={12}>
        <CardFrame text={title}>
            <CardFrameContent style={{backgroundColor: color}}>
              <Grid layout="max-width">{children}</Grid>
            </CardFrameContent>
        </CardFrame>
      </GridColumn>
    </div>
  );
};
