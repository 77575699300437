import { gql } from "apollo-boost";
import { MutationHookOptions, useMutation } from "react-apollo-hooks";
import { archiveClaim, archiveClaimVariables } from "./types/archiveClaim";

const mutation = gql`
    mutation archiveClaim($claimId: String!) {
        archiveClaim(claimId: $claimId) {
            id
            orderId
            claimType
            closedAt
        }
    }
`

export const useArchiveClaimMutation = (
  options: MutationHookOptions<
    archiveClaim,
    archiveClaimVariables
  >
) => useMutation(mutation, options);
