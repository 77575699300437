import { gql } from "apollo-boost";
import { QueryHookOptions, useQuery } from "react-apollo-hooks";
import {
  claim,
  claim_claim,
  claimVariables,
  claim_claim_attachments,
} from "./types/claim";

export const CLAIM_QUERY = gql`
  query claim($claimNumber: Int!) {
    claim(claimNumber: $claimNumber) {
      id
      orderId
      claimType
      closedAt
      orderSection {
        orderDate
        orderNr
        materialNumber
        materialTitle
        materialWeight
        materialUnit
        carrierId
        deliveryNumber
        consignmentNoteNumber
        sourcePlant
        targetPlant
      }
      materialSection {
        materialId
        comment
        quantity
        unit
      }
      physicalQualitySection {
        deviationType
        description
        comment
      }
      chemicalQualitySection {
        deviationType
        description
        comment
      }
      logisticQualitySection {
        deviationType
        comment
      }
      attachments {
        id
        url
        title
        mimeType
      }
      productionSection {
        plantId
        repeatedError
        reason
        recommendedAction
        comment
      }
      partnerServiceSection {
        comment
        commentDeviationPosition
        deviationPosition {
          name
          isSelected
          value
        }
        invoicePrice {
          name
          isSelected
          value
        }
        missingBilledMaterial
        priceMismatch
      }
      assignedRole
      assignedUser {
        id
      }
      createdBy {
        id
      }
      comments {
        uuid
        comment
        createdAt
        author {
          id
          name
          email
          group
        }
      }
    }
  }
`;

export type Claim = claim_claim;
export type ClaimAttachment = claim_claim_attachments;
type ClaimResult = claim;
type ClaimVariables = claimVariables;
export const useClaimQuery = (options: QueryHookOptions<ClaimVariables>) =>
  useQuery<ClaimResult, ClaimVariables>(CLAIM_QUERY, options);
