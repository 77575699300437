import { GridColumn } from "@atlaskit/page";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PlantFragment } from "../../../api/graphql/fragments/types/PlantFragment";
import { AuthConsumer } from "../../../context/AuthContext";
import { Abilities } from "../../../domain/models/abilities";
import { formatDisposalPlantOption } from "../../../utils/form-helper";
import { IProductionSection } from "./types/claim-types";
import EditCheckbox from "../../pages/tickets/fields/EditCheckbox";
import EditSelect, {
  IEditSelectOption,
} from "../../pages/tickets/fields/EditSelect";
import { CustomTextArea } from "../CustomTextArea";
import { FormSection } from "../FormSection";

interface IProductionFormSectionProps {
  section: IProductionSection;
  onChange: (section: IProductionSection) => void;
  plants: PlantFragment[];
  disabled?: boolean;
  sourcePlantId?: string;
}

export const ProductionFormSection: React.FC<IProductionFormSectionProps> = (
  props
) => {
  const { section, onChange, plants, disabled, sourcePlantId } = props;
  const { t } = useTranslation();

  const plantOptions = plants.map(formatDisposalPlantOption);
  const [selectedPlant, setSelectedPlant] = useState<
    IEditSelectOption | undefined
  >(
    plantOptions.find(
      (option) =>
        (sourcePlantId && option.value === sourcePlantId) ||
        option.value === section.plantId
    ) ?? undefined
  );

  useEffect(() => {
    if (selectedPlant) {
      const changedProductionSection: IProductionSection = {
        ...section,
        plantId: selectedPlant?.value,
      };
      onChange(changedProductionSection);
    }
  }, []);

  const onChangePlant = (_: string, selected?: IEditSelectOption) => {
    setSelectedPlant(selected);
    const changedProductionSection: IProductionSection = {
      ...section,
      plantId: selected?.value,
    };
    onChange(changedProductionSection);
  };

  const onChangeRepeatedError = (_: string, value: Boolean) => {
    const changedProductionSection: IProductionSection = {
      ...section,
      repeatedError: !!value,
    };
    onChange(changedProductionSection);
  };

  const onChangeReason = (event: any) => {
    const changedProductionSection: IProductionSection = {
      ...section,
      reason: event?.target?.value ?? "",
    };
    onChange(changedProductionSection);
  };

  const onChangeRecommendedAction = (event: any) => {
    const changedProductionSection: IProductionSection = {
      ...section,
      recommendedAction: event?.target?.value ?? "",
    };
    onChange(changedProductionSection);
  };
  const onChangeComment = (event: any) => {
    const changedProductionSection: IProductionSection = {
      ...section,
      comment: event?.target?.value ?? "",
    };
    onChange(changedProductionSection);
  };

  return (
    <AuthConsumer>
      {({ hasAbility }) => (
        <FormSection title={t("create_claim.form.production_section.title")}>
          <GridColumn medium={12}>
            <EditSelect
              name="plant"
              translation="create_claim.form.production_section.plant"
              options={plantOptions}
              onChange={onChangePlant}
              selected={selectedPlant}
              disabled={disabled}
              required={!hasAbility(Abilities.STOFFSTROM)}
              clearable
            />
          </GridColumn>
          <GridColumn medium={12}>
            <EditCheckbox
              translation="create_claim.form.production_section.repeated_error"
              name="repeated_error"
              hideHelper={true}
              onChange={onChangeRepeatedError}
              selected={section.repeatedError}
              disabled={disabled}
            />
          </GridColumn>
          <GridColumn medium={12}>
            <CustomTextArea
              name="reason"
              translation="create_claim.form.production_section.reason"
              value={section.reason}
              onChange={onChangeReason}
              disabled={disabled}
              required={!hasAbility(Abilities.STOFFSTROM)}
            />
          </GridColumn>
          <GridColumn medium={12}>
            <CustomTextArea
              name="recommended_action"
              translation="create_claim.form.production_section.recommended_action"
              value={section.recommendedAction}
              onChange={onChangeRecommendedAction}
              disabled={disabled}
              required={!hasAbility(Abilities.STOFFSTROM)}
            />
          </GridColumn>
          <GridColumn medium={12}>
            <CustomTextArea
              name="comment"
              translation="create_claim.form.production_section.comment"
              value={section.comment}
              onChange={onChangeComment}
              disabled={disabled}
            />
          </GridColumn>
        </FormSection>
      )}
    </AuthConsumer>
  );
};
