import React from "react"
import { useTranslation } from "react-i18next"
import { IYesNoOption } from '../ssm-specific/deponierbare-abfaelle'

interface UploadHintProps {
  analysis?: IYesNoOption | null
}

export default (props: UploadHintProps) => {
  const { t } = useTranslation()

  return props.analysis && props.analysis.value === "true"
    ? <div style={{ marginTop: "0.5em", marginBottom: "1em", textAlign: "left", fontSize: "11px" }}>
      {t("tickets.create.upload_hint")}
    </div>
    : <></>
}