import { gql } from "apollo-boost"
import { Query } from "react-apollo";
import FullTicketFragment from "../fragments/FullTicket";
import PlantFragment from "../fragments/Plant"
import { TicketByMaterialAndCustomer } from "./types/TicketByMaterialAndCustomer";

export default gql`
    query TicketByMaterialAndCustomer($customerId: String!,  $materialId: String!) {
        ticketByMaterialAndCustomer(customerId: $customerId, materialId:$materialId) {
            ...FullTicketFragment
        }
        plants {
            ...PlantFragment
        }
    }

    ${FullTicketFragment}
    ${PlantFragment}
`

export class TicketByMaterialAndCustomerQuery extends Query<TicketByMaterialAndCustomer> {}