import * as lodash from "lodash"
import React from "react";
import styled from "styled-components";
import { PartnerFragment as IPartner } from "../api/graphql/fragments/types/PartnerFragment";

const Subtitle = styled.span`
  display: block;
  font-size: 13px;
  color: gray;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 99%;
`

const Address = styled.span`
  display: block;
  font-size: 11px;
  color: gray;
`

export const optionLabel = (title: string, subtitle: string | undefined, additional: string | undefined, formatSubtitle: ((subtitle: string | undefined) => string) | undefined | undefined,
                            prefix: string | undefined, suffix: string | undefined, opt: string | undefined) => (
    <div>
      <div>
        {lodash.get(opt, title)}
        <span style={{ fontWeight: 600, float: "right", paddingLeft: "6px" }}>{formatSubtitle && subtitle ? formatSubtitle(lodash.get(opt, subtitle)) : (subtitle ? lodash.get(opt, subtitle) : null)}</span>
      </div>
      {additional && additional !== "XXXXX" ? <Subtitle>{prefix ? `${prefix} ` : null}{lodash.get(opt, additional)}{suffix && lodash.get(opt, suffix) ? ` ${lodash.get(opt, suffix)}` : null}</Subtitle> : null}
      {lodash.get(opt, "address") && <Address>{lodash.get(opt, "address")}</Address>}
    </div>
)

export const formatOptionLabel = (title: string, subtitle: string | undefined, additional: string | undefined = undefined, formatSubtitle: ((subtitle: string | undefined) => string) | undefined = undefined,
                                  prefix: string | undefined = undefined, suffix: string | undefined = undefined) => (opt: any) => {
  return optionLabel(title, subtitle, additional, formatSubtitle, prefix, suffix, opt)
}

export const formatMaterialOptionLabel = (title: string, sub: string) => (obj: any) => {

  const subtitle = lodash.get(obj, sub)

  return (
    <div>
      <div>
        {lodash.get(obj, title)}
      </div>
      <Subtitle>
        {
          Array.isArray(subtitle) ? subtitle.reduce((p, c) => {return p.concat(c, <br key={Math.random()}/>)}, []) : subtitle
        }
      </Subtitle>
    </div>
  )
}

export const formatPartnerOption = (partner: IPartner) => {
  return (
    <div>
      <div style={{ color: "rgb(52,69,99)" }}>
        {lodash.get(partner, "name")}
        <span style={{ fontWeight: 600, float: "right", paddingLeft: "6px" }}>{lodash.get(partner, "code")}</span>
      </div>
      <Address>
        {lodash.get(partner, "street")} {lodash.get(partner, "number")}<br/>
        {lodash.get(partner, "postal")} {lodash.get(partner, "city")}
      </Address>
    </div>
  )
}

export const formatDisposalPlantOption = (plant: any) => ({
  label: `${plant.facility} ${plant.description} ${plant.street} ${plant.number} ${plant.postal} ${plant.city}`,
  facility: plant.facility,
  description: plant.description,
  value: plant.id,
  address: `${plant.street || ""} ${plant.number || ""}${(plant.street || plant.number) && (plant.postal || plant.city) ? "," : ""} ${plant.postal || ""} ${plant.city || ""}`
})
