import { GridColumn } from "@atlaskit/page";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { CommentView } from "../CommentView";
import { CommentEditor } from "../comment/CommentEditor";
import { FormSection } from "../FormSection";

interface IComment {
  uuid: string;
  comment: string;
  authorName: string;
  authorGroup: string | null;
  createdAt: Date;
}

interface ICommentFormSectionProps {
  comments: IComment[];
  onAddComment: (comment: string) => void;
  loading: boolean;
  disabled?: boolean;
}

export const CommentFormSection: VFC<ICommentFormSectionProps> = (props) => {
  const { comments, onAddComment, loading, disabled } = props;
  const { t } = useTranslation();

  return (
    <FormSection title={t("comments")}>
      <GridColumn medium={12}>
        {comments.map(
          ({ uuid, comment, authorGroup, authorName, createdAt }) => (
            <CommentView
              key={uuid}
              comment={comment}
              authorGroup={authorGroup}
              authorName={authorName}
              createdAt={createdAt}
            />
          )
        )}
        <CommentEditor onSubmit={onAddComment} loading={loading} disabled={disabled} />
      </GridColumn>
    </FormSection>
  );
};
