import React from "react";
import __ from "lodash";
import "url-search-params-polyfill";
import claimsHistoryQuery, {
  ClaimHistoryQuery,
} from "../../../../api/graphql/queries/claimHistory";
import { claim_claim } from "../../../../api/graphql/queries/types/claim";
import { ClaimHistoryEntry } from "./ClaimHistoryEntry";

interface IClaimHistoryProps {
  claim: claim_claim | null | undefined;
}

export const ClaimHistory: React.FunctionComponent<IClaimHistoryProps> = (
  props
) => {
  const { claim } = props;

  if (!claim) {
    return <p>Es konnte kein Verlauf für diese Reklamation gefunden werden</p>;
  }
  return (
    <ClaimHistoryQuery
      query={claimsHistoryQuery}
      variables={{ claimId: claim.id }}
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <div>Verlauf wird geladen ...</div>;
        }
        if (data && data.claimHistory && data.claimHistory.length > 0) {
          return (
            <ul>
              {data.claimHistory.map((historyEntry, index) => (
                <ClaimHistoryEntry key={index} historyEntry={historyEntry} />
              ))}
            </ul>
          );
        } else if (data && data.claimHistory && data.claimHistory.length <= 0) {
          return (
            <p>Es konnte kein Verlauf für diese Reklamation gefunden werden</p>
          );
        }
        return <div>Verlauf nicht verfügbar</div>;
      }}
    </ClaimHistoryQuery>
  );
};
