import React from "react";
import {
  StyledLane,
  Sublane,
  LaneHeader,
  LaneHeaderContent,
  LaneContainer,
  LaneStrip,
  LaneTitle,
} from "../../styled/Board";

interface ILaneProps<T, K> {
  title: string;
  items: T[];
  sublanes: readonly K[];
  isInSublane: (sublane: K) => (item: T) => boolean;
  renderItem: (item: T) => React.ReactNode;
}

export const Lane = <T, K extends string>(
  props: ILaneProps<T, K>
): React.ReactElement | null => {
  const { title, items, sublanes, isInSublane, renderItem } = props;

  return (
    <StyledLane>
      <LaneHeader>
        <LaneHeaderContent>
          <LaneTitle>{title}</LaneTitle>
        </LaneHeaderContent>
      </LaneHeader>
      <div
        style={{
          display: "flex",
          alignItems: "stretch",
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        {sublanes.map((title) => (
          <Sublane key={title}>
            <LaneHeader>
              <LaneHeaderContent>
                <LaneTitle>{title}</LaneTitle>
              </LaneHeaderContent>
            </LaneHeader>
            <LaneStrip className="board-lanestrip">
              <LaneContainer>
                {items.filter(isInSublane(title)).map(renderItem)}
              </LaneContainer>
            </LaneStrip>
          </Sublane>
        ))}
      </div>
    </StyledLane>
  );
};
