import React from "react";
import Textfield from "@atlaskit/textfield";
import { Field, HelperMessage } from "@atlaskit/form";
import { useTranslation } from "react-i18next";

interface ICustomNumberfieldProps {
  name: string;
  translation: string;
  value: number | undefined;
  onChange: (event: any) => void;
  required?: boolean;
  disabled?: boolean;
}

export const CustomNumberfield: React.VFC<ICustomNumberfieldProps> = (
  props
) => {
  const { name, value, translation, onChange, disabled, required } = props;
  const { t } = useTranslation();
  const helper = t(`${translation}.helper`);

  return (
    <Field label={t(`${translation}.title`)} name={name} isRequired={required}>
      {({ fieldProps }: { fieldProps: any }) => (
        <React.Fragment>
          <Textfield
            style={{ height: "40px" }}
            type="number"
            {...fieldProps}
            defaultValue={value}
            onChange={onChange}
            isDisabled={disabled}
          />
          {helper && <HelperMessage>{helper}</HelperMessage>}
        </React.Fragment>
      )}
    </Field>
  );
};
