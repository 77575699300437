import React, { FunctionComponent } from "react";
import { AuthConsumer } from '../../../context/AuthContext';
import ticketByMaterialAndCustomerQuery, {
  TicketByMaterialAndCustomerQuery
} from "../../../api/graphql/queries/ticketByMaterialAndCustomer";
import PageLoadingIndicator from "../../partials/PageLoadingIndicator";
import { TicketDetail } from "./TicketDetail";
import { useTranslation } from "react-i18next";

interface ITicketDetailExternalProps {
  materialNumber: string;
  customerId: string
}

export const TicketDetailExternal: FunctionComponent<ITicketDetailExternalProps> = (props) => {
  const {materialNumber, customerId} = props;
  const {t} = useTranslation();

  return (
    <AuthConsumer>
      {({hasAbility, userId, token}) => (
        <>
          {userId && token && hasAbility && (
            <TicketByMaterialAndCustomerQuery
              query={ticketByMaterialAndCustomerQuery}
              fetchPolicy="network-only"
              variables={{customerId: customerId, materialId: materialNumber}}
            >
              {({data, refetch, loading}) => {
                const ticket = data ? data.ticketByMaterialAndCustomer : null
                if (loading) {
                  return <PageLoadingIndicator/>
                }

                return ticket ? <TicketDetail
                  data={data!}
                  ticket={ticket}
                  userId={userId}
                  token={token}
                  hasAbility={hasAbility}
                  refetch={refetch}
                /> : <div style={{
                  marginTop: "5em",
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "500",
                }}>{t("tickets.ticket_detail_external.no_ticket")}</div>
              }}
            </TicketByMaterialAndCustomerQuery>
          )}
        </>
      )}
    </AuthConsumer>
  )
}