import {
  DeviationPositionType,
  InvoicePriceType,
  MaterialUnit,
} from "../api/graphql/graphql-global-types";

export const CLAIM_DEFAULTS = {
  claimType: undefined,
  materialSection: [],
  orderSection: {
    orderDate: "",
    orderNr: "",
    materialNumber: null,
    materialTitle: null,
    materialWeight: null,
    materialUnit: MaterialUnit.TO,
    carrierId: null,
    deliveryNumber: null,
    consignmentNoteNumber: null,
    sourcePlant: null,
    targetPlant: null,
  },
  physicalQualitySection: {
    deviationType: null,
    description: "",
    comment: "",
  },
  chemicalQualitySection: {
    deviationType: null,
    description: "",
    comment: "",
  },
  logisticQualitySection: {
    deviationType: null,
    comment: "",
  },
  files: [],
  productionSection: {
    plantId: null,
    repeatedError: false,
    reason: "",
    recommendedAction: "",
    comment: "",
  },
  partnerServiceSection: {
    comment: "",
    commentDeviationPosition: "",
    deviationPosition: [
      {
        name: DeviationPositionType.ANDERES_MATERIAL,
        isSelected: false,
        value: "",
      },
      {
        name: DeviationPositionType.MANIPULATION,
        isSelected: false,
        value: "",
      },
      { name: DeviationPositionType.REGIE, isSelected: false, value: "" },
      {
        name: DeviationPositionType.SORTIERKOSTEN,
        isSelected: false,
        value: "",
      },
    ],
    invoicePrice: [
      {
        name: InvoicePriceType.SURCHARGE,
        isSelected: false,
        value: 0,
      },
      {
        name: InvoicePriceType.FIXED_PRICE,
        isSelected: false,
        value: 0,
      },
      { name: InvoicePriceType.SPECIAL_PRICE, isSelected: false, value: 0 },
    ],
    missingBilledMaterial: false,
    priceMismatch: false,
  },
};
