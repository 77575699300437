import React, { VFC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button, { ButtonGroup } from "@atlaskit/button";
import { CommentInput } from "./CommentInput";

interface ICommentEditorProps {
  onSubmit: (comment: string) => void;
  onCancel?: () => void;
  loading: boolean;
  disabled?: boolean;
}

const Container = styled.div`
  margin-top: 8px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
`;

const CancelButton = styled.div`
  margin-right: 8px;
`;

export const CommentEditor: VFC<ICommentEditorProps> = (props) => {
  const { loading, disabled } = props;
  const { t } = useTranslation();

  const [comment, setComment] = useState("");

  const onCancel = () => {
    setComment("");
    props.onCancel?.();
  };

  const onSubmit = () => {
    setComment("");
    props.onSubmit(comment);
  };

  return (
    <Container>
      <CommentInput value={comment} onChange={setComment} disabled={disabled} />

      <ButtonContainer>
        <ButtonGroup>
          <CancelButton>
            <Button
              appearance="secondary"
              onClick={onCancel}
              isDisabled={loading || disabled}
            >
              {t("cancel")}
            </Button>
          </CancelButton>
          <Button
            tabIndex={-1}
            appearance="primary"
            onClick={onSubmit}
            isDisabled={loading || disabled}
          >
            {t("to_comment")}
          </Button>
        </ButtonGroup>
      </ButtonContainer>
    </Container>
  );
};
