import React, { FC, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import Spinner from "@atlaskit/spinner";
import UploadIcon from "@atlaskit/icon/glyph/upload";
import { useTranslation } from "react-i18next";
import CrossCircleIcon from "@atlaskit/icon/glyph/cross-circle";
import PdfDocument48Icon from "@atlaskit/icon-file-type/glyph/pdf-document/48";
import Spreadsheet48Icon from "@atlaskit/icon-file-type/glyph/spreadsheet/48";
import Image48Icon from "@atlaskit/icon-file-type/glyph/image/48";
import Generic48Icon from "@atlaskit/icon-file-type/glyph/generic/48";
import Document48Icon from "@atlaskit/icon-file-type/glyph/document/48";
import Tooltip from "@atlaskit/tooltip";
import { FileInput } from "../../api/graphql/graphql-global-types";
import { Constants } from "../../utils/constants";
import { downloadFile } from "../../utils/file-helper";

const AttachmentItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: #f4f5f7;
  width: 150px;
  padding: 5px;
  border-radius: 3px;
  font-size: 0.7em;
  margin: 5px;

  &:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }

  & > img {
    border-radius: 3px;
  }

  & > span {
    display: block;
    margin-top: 5px;
  }
`;

interface IExistingFile {
  url: string;
  title: string;
}

interface CustomDropzoneProps {
  files: FileInput[];
  existing?: IExistingFile[];
  onChange: (files: FileInput[]) => void;
  loading: boolean;
  disabled?: boolean;
}

export const CustomDropzone: FC<CustomDropzoneProps> = (props) => {
  const { files, existing, loading, onChange, disabled } = props;
  const { t } = useTranslation();
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const uploadFile = handleFileLoaded(file, reader.result);
        onChange([...files, uploadFile]);
      };
      reader.readAsDataURL(file);
    },
    [files, onChange]
  );
  const handleFileLoaded = (file: File, fileBase64: any) => {
    const uploadFile = {
      title: file.name,
      base64: fileBase64,
    };
    return uploadFile;
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      disabled: disabled,
      multiple: false,
      maxSize: Constants.MAX_FILE_SIZE_UPLOAD,
    });

  const handleDelete = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    event.preventDefault();
    event.stopPropagation();
    onChange(files.filter((_, i) => i !== index));
  };

  const getFileIcon = ({ title }: FileInput | IExistingFile) => {
    const extension = title.split(".").pop()?.toLocaleLowerCase();
    switch (extension) {
      case "pdf":
        return <PdfDocument48Icon label="PDF Anhang" />;
      case "jpg":
      case "jpeg":
      case "png":
        return <Image48Icon label="Bild Anhang" />;
      case "doc":
      case "docx":
        return <Document48Icon label="Word Anhang" />;
      case "xlsx":
      case "xls":
        return <Spreadsheet48Icon label="Excel Anhang" />;
      default:
        return <Generic48Icon label="Sonstiger Anhang" />;
    }
  };

  return (
    <div {...getRootProps()}>
      <div style={{ display: "flex", overflow: "auto" }}>
        {files.map((file, index) => (
          <AttachmentItem key={file.title}>
            {!disabled && (
              <div
                style={{ position: "absolute", right: "4px", top: "4px" }}
                onClick={(e) => handleDelete(e, index)}
              >
                <Tooltip position="top" content={t("delete")}>
                  <CrossCircleIcon label={t("delete")} />
                </Tooltip>
              </div>
            )}
            {getFileIcon(file)}
            <p
              style={{
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                textAlign: "center",
              }}
            >
              {file.title}
            </p>
          </AttachmentItem>
        ))}

        {existing?.map((file) => (
          <AttachmentItem
            onClick={() => {
              downloadFile(file);
            }}
            key={file.title}
          >
            {getFileIcon(file)}
            <p
              style={{
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                textAlign: "center",
              }}
            >
              {file.title}
            </p>
          </AttachmentItem>
        ))}

        <AttachmentItem>
          {loading ? (
            <Spinner size="large" />
          ) : (
            <UploadIcon label={"CustomDropzoneLabel"} size="xlarge" />
          )}
          <input {...getInputProps()} multiple={false} />
          <span>
            {!isDragActive && t("components.dropzone.upload")}
            {isDragActive && !isDragReject && t("components.dropzone.drophere")}
            {isDragReject && t("components.dropzone.reject")}
          </span>
        </AttachmentItem>
      </div>
    </div>
  );
};
