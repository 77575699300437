interface IFile {
  title: string;
  url: string;
}

export const downloadFile = ({ title, url }: IFile): void => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.download = title;
  a.href = url;
  a.click();
  document.body.removeChild(a);
};
