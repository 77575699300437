import validator from "email-validator"
import { useState } from 'react'

export function useEmail(initialValue: string) {
    const validateEmail = (newEmail: string) => (newEmail && newEmail.length > 6 && validator.validate(newEmail))

    const [email, setEmail] = useState<string>(initialValue)
    const [isEmailValid, setIsEmailValid] = useState(validateEmail(initialValue))

    const onEmailChange = (newEmail: string) => {
        setEmail(newEmail)
        setIsEmailValid(validateEmail(newEmail))
    }

    return {
        email,
        isEmailValid,
        onEmailChange
    }
}