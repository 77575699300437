import React from "react";
import { IconButton } from "@material-ui/core";
import InfoIcon from "@atlaskit/icon/glyph/info";
import Tooltip from "@atlaskit/tooltip";

interface IToolTipLinkProps {
  material: any | null;
}

export const ToolTipLink: React.FC<IToolTipLinkProps> = (props) => {
  const { material } = props;
  const serviceLink =
    material && material.matnr
      ? `https://saubermacher.service-now.com/sp?id=search&spa=1&disableSpellCheck=false&q=${material.matnr}` // TODO: SSTS-257
      : undefined;

  if (!serviceLink) {
    return <></>;
  }

  return (
    <IconButton
      onClick={() => window.open(serviceLink, "_blank")}
      style={{ marginLeft: 8, height: 24, width: 24 }}
    >
      <Tooltip position="right" content="Produktdatenblatt">
        <InfoIcon
          label="Produktdatenblatt"
          size="medium"
          primaryColor="#e6483d"
        />
      </Tooltip>
    </IconButton>
  );
};
