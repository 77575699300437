import { IEditSelectOption } from "../../components/pages/tickets/fields/EditSelect";
import { WasteFrequency } from "../../api/graphql/graphql-global-types";

export interface IWasteFrequencyOption extends IEditSelectOption {
  label: string;
  value: string;
  subtitle?: string | null;
}

export const WASTE_FREQUENCY: { [key: string]: IWasteFrequencyOption } =
  Object.freeze({
    [WasteFrequency.SINGLE]: {
      label: "einmalig",
      value: "SINGLE",
    },
    [WasteFrequency.CONTINUOUS]: {
      label: "laufend",
      value: "CONTINUOUS",
    },
    [WasteFrequency.MONTHLY]: {
      label: "monatlich",
      value: "MONTHLY",
    },
    [WasteFrequency.YEARLY]: {
      label: "jährlich",
      value: "YEARLY",
    },
  });
