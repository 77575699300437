export const de = {
  save: "Speichern",
  to_comment: "Kommentieren",
  comment: "Kommentar",
  comments: "Kommentare",
  cancel: "Abbrechen",
  delete: "Löschen",
  global: {
    company: "Stoffstrom",
    product: "Ticketsystem",
    title: "Stoffstrom Ticketsystem",
    imprint: {
      title: "Impressum",
    },
    tos: {
      title: "Allgemeine Geschäftsbedingungen",
    },
  },
  role: {
    LABOR: "Produktion",
    PARTNER_SERVICE: "Partnerservice",
    STOFFSTROM: "Stoffstrom",
    VERTRIEB: "Vertrieb",
  },
  login: {
    email: {
      label: "Ihre Saubermacher E-Mail Adresse",
    },
    password: {
      label: "Ihr Passwort",
    },
    status: {
      progress: {
        message: "Sie werden angemeldet ...",
      },
    },
  },
  logout: {
    title: "Abmelden",
    subtitle: "von Ticketsystem",
    info: {
      greeting: "Hallo,",
      text: "möchtest du dich vorübergehend aus dem Ticketsystem abmelden um deinen Account vor unbefugtem Zugriff zu schützen? Du kannst dich jederzeit wieder anmelden.",
    },
    action: {
      logout: "Angemeldet bleiben",
      cancel: "Ja, abmelden",
    },
  },
  form: {
    select: {
      required: {
        error: "Die Auswahl dieses Feldes ist erforderlich!",
      },
    },
  },
  generic: "Allgemein",
  yes: "Ja",
  no: "Nein",
  generic_waste_info: "Allgemeine Abfallinformationen",
  company_process: "Unternehmen + Prozess",
  material_description: "Materialbeschreibung",
  additional: "Zusatz",
  check_input: "Bitte überprüfen Sie Ihre Eingaben!",
  partner: "Kunde",
  freetext: "Freitext",
  filter: "Filter",
  assignee: {
    unassigned: {
      title: "Nicht zugewiesen",
    },
    stoffstrom: {
      title: "Stoffstrom",
    },
    vertrieb: {
      title: "Vertrieb",
    },
    labor: {
      title: "Labor",
    },
    production: {
      title: "Produktion",
    },
    partner_service: {
      title: "Partnerservice",
    },
  },
  password_change: {
    title: "Passwort ändern",
    request_change: "Passwortänderung anfordern",
    change: "Passwort Ändern",
    redirect_login: "Zum Login",
  },
  waste_frequency: {
    code: {
      SINGLE: "einmalig",
      CONTINUOUS: "laufend",
      MONTHLY: "monatlich",
      YEARLY: "jährlich",
    },
  },
  units: {
    code: {
      PIECES: "Stück",
      TONS: "Tonnen",
      QM: "m³",
      KG: "kg",
    },
    transportation: {
      ANLIEFERUNG: "Angeliefert",
      ABHOLUNG: "Abgeholt",
    },
  },
  waste_from: {
    code: {
      PRODUCTION: "Produktion",
      BUSINESS: "Gewerbe",
      PRIVAT: "Haushalt",
    },
  },
  pickup: {
    code: {
      OPENING_HOURS: "Nur zu den Öffnungszeiten",
      SELF_LOADING: "Selbstverladung jederzeit möglich",
      IMMEDIATE_LOADING: "Sofortverladung durch Kunde",
      INTERIM_STORAGE: "Zwischenlager möglich 2 Container",
      EXCHANGE_CONTAINER: "Tauschcontainer oder Mulde",
      SUCTION: "Nur im Saugauto möglich",
      MISCELLANEOUS: "Sonstiges - im Kommentar eintragen",
    },
  },
  quantity_determination: {
    code: {
      AGREEMENT: "nach Vereinbarung",
      ON_SITE: "Verwiegung vor Ort",
      RECYCLER: "Verwiegung bei Verwerter",
      FOREIGN: "Fremdwiegung nächstgelegene Möglichkeit",
      CBM: "nach CBM",
      LITER: "nach Liter",
      ATRO: "Atrotonnen",
    },
  },
  quality_control: {
    code: {
      AGREEMENT: "Analyse von Abfall beigelegt",
      ON_SITE: "Bescheidauszug und Bezeichnung des Abfalls beigelegt",
      RECYCLER: "noch unbekannte Einstufung des Abfalls",
      FOREIGN: "Häufigkeit der Analysen pro Jahr bekannt",
      CBM: "Starke Verunreinigung",
      LITER: "Bisher Geruchsprobleme bestanden",
      ATRO: "Grundabfall von belastenden Flächen z.B. Autobahn",
    },
  },
  mixture: {
    code: {
      SORTENREIN: "Sortenrein",
      GEMISCH: "Gemisch",
    },
  },
  tickets: {
    title: "Tickets",
    ticket_detail_external: {
      no_ticket: "Kein Ticket gefunden",
    },
    list: {
      my_tickets: "Mir zugewiesene Tickets",
      other_tickets: "Weitere Tickets",
      claims: "Reklamationstickets",
    },
    search: {
      title: "Ticketsuche",
      results: "Suchergebnisse",
      error: {
        failed_to_fetch: "Tickets konnten nicht geladen werden",
        no_tickets_found: "Es konnten keine Tickets gefunden werden",
      },
    },
    detail: {
      title: "Ticket",
      assignRole: {
        to: "Zuweisen an {{role}}",
      },
      sections: {
        completion: {
          title: "Informationen zum Auftragsabschluss",
          has_received_order: {
            title: "Auftrag erhalten",
            helper: null,
            yes: "Ja",
            no: "Nein",
          },
          explanation: {
            title: "Informationen über den Mitbewerb",
            helper: null,
          },
        },
        attachments: {
          title: "Anhänge",
          dropzone:
            "Neuen Anhang hier ablegen. (Erlaubt: *.pdf, *.jpg, *.jpeg, *.png, *.doc(x), *.xls(x))",
          drophere: "Legen Sie die Datei hier ab um den Upload zu beginnen.",
          reject: "Diese Datei wird nicht unterstützt.",
        },
        ticket: {
          title: "Ticket",
          no: {
            title: "№",
            helper: null,
          },
          sourceId: {
            title: "Importiert aus Ticket",
            helper: null,
          },
          status: {
            title: "Status",
            helper: null,
            mapping: {
              requested: {
                title: "Angefragt",
              },
              completed: {
                title: "Abgeschlossen",
              },
              canceled: {
                title: "Storniert",
              },
            },
          },
          division: {
            title: "Stoffstromgruppe",
            helper: null,
          },
          assignee: {
            title: "Zugewiesen",
            helper: null,
          },
          modified_at: {
            title: "Zuletzt bearbeitet",
            helper: null,
          },
          created_at: {
            title: "Erstellt am",
            helper: null,
          },
          created_by: {
            title: "Erstellt von",
            helper: null,
          },
          assigned_user: {
            title: "Bearbeiter",
            helper: null,
          },
          assigned_role: {
            title: "Zugewiesen an",
            helper: null,
          },
          link: {
            title: "Zur Detailansicht",
            helper: null,
          },
          partner: {
            title: "Partner",
            helper: null,
          },
          oenorm: {
            title: "ÖNORM",
            helper: null,
          },
          material: {
            title: "Material",
            helper: null,
          },
        },
        material: {
          title: "Allgemeine Abfallinformationen",
          oenorm: {
            title: "ÖNORM",
            helper: null,
          },
          material: {
            title: "Material",
            helper: null,
            empty: "-",
          },
          pks: {
            title: "Produktkostensammler",
            helper: null,
            empty: "-",
          },
          ssm: {
            title: "Stoffstromgruppe",
            helper: null,
          },
        },
        partner: {
          title: "Kunde",
          customer: {
            title: "Kundennr.",
            helper: null,
          },
          name: {
            title: "Kundenname",
            helper: null,
          },
          street: {
            title: "Straße",
            helper: null,
          },
          number: {
            title: "Nummer",
            helper: null,
          },
          city: {
            title: "Ort",
            helper: null,
          },
          postal: {
            title: "Postleitzahl",
            helper: null,
          },
          contact: {
            title: "Ansprechperson Kunde",
            helper: null,
          },
          location: {
            title: "Produktionsprozess/Abfallort/Baustelle",
            helper: null,
          },
        },
        utilization: {
          title: "Verwertungsmöglichkeit",
        },
        comments: {
          title: "Kommentare",
        },
        editor: {
          placeholder: "Kommentar hinzufügen",
          error:
            "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut",
        },
        assignDialog: {
          title: "Zuweisen an: ",
          assign: "Zuweisen",
        },
      },
    },
    create: {
      title: "Neues Ticket erstellen",
      upload_hint:
        "Hinweis: Nach dem Erstellen des Tickets können weitere Dokumente/Bilder und Anhänge hochgeladen werden.",
      fields: {
        request_assignment: {
          title: "Abfallzuordnung gewünscht",
          helper: "Abfallzuordnung gewünscht",
        },
        additional: {
          comment: "Kommentar/Zusatz",
        },
        battery: {
          title: "Batterien",
        },
        oenorm: {
          placeholder: "Bitte wählen Sie eine ASN aus",
          title: "Abfallschlüsselnummer (ASN)",
          helper: null,
        },
        material: {
          placeholder: "Wählen Sie ein Material aus",
          title: "Material",
          helper: null,
        },
        division: {
          placeholder: "Wählen Sie eine Stoffstromgruppe aus",
          title: "Stoffstromgruppe",
        },
        quantity: {
          title: "Abfallmenge Angabe ",
          placeholder: null,
        },
        unit: {
          title: "Einheit",
          helper: null,
          placeholder: null,
        },
        frequency: {
          title: "Wie oft fällt der Abfall an:",
          helper: null,
          placeholder: null,
        },
        pack: {
          title:
            "Wie liegt das Material vor (lose, Ballen, Big Bags, Verpackung, Gebinden,..)?",
          placeholder: null,
        },
        transport: {
          title: "Transportart - wie wird der Abfall abgeholt?",
          placeholder: null,
        },
        partner: {
          title: "Geschäftspartner",
          placeholder: "Bitte wählen Sie einen Geschäftspartner aus",
          alternative: {
            title: "Alternativer Geschäftspartner",
            fields: {
              customer: "Kundennummer",
              name: "Kundenname",
              industry: "Branche",
              street: "Straße",
              number: "Nummer",
              postal_code: "Postleitzahl",
              city: "Ort",
              contact: "Ansprechperson Kunde",
              location: "Produktionsprozess/Abfallort/Baustelle",
            },
          },
        },
        waste_description: {
          placeholder: "",
          title: "Abfallbeschreibung",
        },
        image: {
          placeholder: "",
          dropzone:
            "Anhang hier ablegen. (Erlaubt: *.pdf, *.jpg, *.jpeg, *.png, *.doc(x), *.xls(x))",
          active: "Anhang ablegen",
          reject: "Kein gültiger Anhang!",
          title: "Foto",
        },
        eag: {
          title: "EAG",
          waste_from: "Abfall aus",
        },
        deponierbare_abfaelle: {
          title: "Deponierbare Abfälle",
          company_process: {
            produced: "Was wird im Unternehmen produziert? ",
            process: "Wie entsteht der Abfall (Prozess)?",
            original_use: "Ursprüngliche Verwendung des Materials?",
            sample: "Probe vorhanden?",
            waste_from_incinerator: "Abfall aus einer Verbrennungsanlage?",
          },
          material_description: {
            detail: "Größe/Länge/Kubator des Abfalls",
            consistency: "Abfallkonsistenz?",
            consistency_additional: "Beschreibung der Konsistenz",
            characteristic: "Grundlegene Charakterisierung vorhanden?",
            additional: "weitere Beurteilungen vorhanden?",
            analysis: "Analytik vorhanden?",
            analysis_additional: "Anmerkungen zur Analytik",
            ph_value: "Detailangaben zum pH-Wert",
            ph_value_selection: "pH-Wert",
            dry_substance: "Trockensubstanzgehalt in %",
            pickup: "Abholung/Verladung",
            quantity_determination: "Mengenermittlung erfolgt?",
            quality_control: "Qualitätskontrolle",
          },
        },
        kunststoffe: {
          title: "Kunststoffe",
          foreign_matter_exists:
            "Sind Fremdstoffe oder Störstoffe im Material vorhanden?",
          foreign_matter_exists_additional:
            "Um welche Fremdstoffe handelt es sich?",
          is_licenced: "Ist das Material lizensiert?",
          is_licenced_additional: "Um welche Lizenzen handelt es sich?",
          mixture: "Sortenrein oder Gemisch?",
          kind: "Kunststoffart (HDFPE,…",
          fuellstoffe: "Füllstoffe",
          fuellstoffe_additional: "Welcher Anteil in Prozent?",
          fiberglass: "Glasfaser",
          fiberglass_additional: "Welcher Anteil in Prozent?",
          flammhemmer: "Flammhemmer",
          addtive: "Andere Additive",
          color: "Farbe",
          coating: "Beschichtung (Wachs, Folie)",
        },
        metalle: {
          title: "Metalle",
          is_indexed: "Gibt es eine Indexbindung?",
          company_process: {
            container_available: "Container vor Ort?",
          },
        },
        altholz_papier: {
          title: "Altholz/-papier",
          is_indexed: "Gibt es eine Indexbindung?",
          coating: "Beschichtung (Wachs, Folie)",
          coating_additional: "Um welche Beschichtung handelt es sich?",
        },
        util: {
          title: "Sonstige Wertstoffe zur stofflichen Verwertung",
        },
        gefaehrliche_abfaelle: {
          title: "Gefährliche Abfälle",
          sample_taken_from: "Probenahme erfolgt durch?",
          sample_given_to: "Probe übergeben an?",
          sample_delivered_at: "Probe übergeben am?",
          smell: "Geruch",
          process_selection: "Art des Abfalls?",
        },
        biogene_abfaelle: {
          material_description: {
            consistency: "Abfallkonsistenz?",
          },
        },
      },
      success: "Ticket wurde erfolgreich angelegt!",
      error: "Beim Anlegen des Tickets ist ein Fehler aufgetreten",
    },
    utilization: {
      modal: {
        title: "Verwertung für {{ticket}} dokumentieren",
        actions: {
          save: {
            title: "Dokumentation hinzufügen",
          },
          cancel: {
            title: "Abbrechen",
          },
        },
        section: {
          monetary: {
            title: "Monetäre Angaben",
            fields: {
              costs: {
                title: "Kosten",
                helper: "Angabe in €",
              },
              compensation: {
                title: "Vergütung",
                helper: "Angabe in €",
              },
              unit: {
                title: "Einheit",
                helper: null,
                placeholder: "Wählen Sie die Einheit aus ...",
              },
              pricelist: {
                title: "Preis",
                helper: null,
              },
            },
          },
          logistics: {
            title: "Logistik",
            fields: {
              plant: {
                title: "Anlieferung Verwerter",
                helper: null,
                placeholder: "Wählen Sie eine Entsorgungsanlage aus ...",
              },
              plant_planned: {
                title: "Geplanter Verwerter",
                helper: null,
                placeholder: "Wählen Sie eine Entsorgungsanlage aus ...",
              },
              costs: {
                title: "Logistikkosten (Preis)",
                helper: "Die Einheit im Textfeld mit anführen",
              },
              transportation: {
                title: "Kosten/Erlöse verstehen sich als",
                helper: null,
                placeholder: "Wählen Sie die Transportart aus ...",
              },
              streckenabholung: {
                title: "Streckenabholung",
                helper: null,
                placeholder: null,
              },
              standort: {
                title: "Anlieferung Standort",
                helper: null,
                placeholder: "Wählen Sie einen Standort aus ...",
              },
            },
          },
          actual: {
            title: "Angaben des Ticketerstellers",
            fields: {
              oenorm: {
                title: "ÖNORM",
              },
              material: {
                title: "Material",
              },
            },
          },
          adjustments: {
            title: "Korrekturangaben",
            fields: {
              oenorm: {
                title: "Korrekte ÖNORM",
                helper: "Nur auszufüllen wenn die angegebene ÖNORM falsch ist.",
                placeholder: "Wählen Sie eine ÖNORM aus ...",
              },
              material: {
                title: "Korrektes Material",
                helper:
                  "Nur auszufüllen wenn das angegebene Material falsch ist.",
                placeholder: "Wählen Sie eine Material aus ...",
              },
              material_remark: {
                title: "Materialanmerkung",
                helper: null,
                placeholder: null,
              },
            },
          },
          recommendations: {
            title: "Vorschläge",
          },
          disposal_possibility: {
            title: "Entsorgungsmöglichkeiten",
            reasons: {
              NO_PERMIT: "keine Abfallgenehmigung vorhanden",
              NO_OUTPUT_SOLUTION: "keine Outputlösung vorhanden",
            },
            fields: {
              not_possible_disposal: {
                title: "Abfallübernahme nicht möglich",
                helper: null,
              },
              not_possible_disposal_reason: {
                title: "Grund",
                helper: null,
                placeholder: null,
              },
              not_possible_disposal_note: {
                title: "Notiz",
                helper: null,
                placeholder: null,
              },
            },
          },
        },
      },
    },

    fields: {
      sample_available: {
        title: "Probe vorhanden?",
      },
      company_process: {
        production: {
          title: "Was wird im Unternehmen produziert? ",
          helper: null,
        },
        process: {
          title: "Wie entsteht der Abfall (Prozess)?",
          helper: null,
        },
        usage: {
          title: "Ursprüngliche Verwendung des Materials?",
          helper: null,
        },
        container_available: {
          title: "Container vor Ort?",
          helper: null,
        },
        sample_available: {
          title: "Probe vorhanden?",
          helper: null,
        },
        sample_creator: {
          title: "Probenahme erfolgt durch?",
          helper: null,
        },
        sample_received: {
          title: "Probe übergeben am?",
          helper: null,
        },
        sample_recipient: {
          title: "Probe übergeben an?",
          helper: null,
        },
      },
      material_description: {
        waste_from: {
          title: "Abfall aus",
        },
        dry_substance: {
          title: "Trockensubstanzgehalt in %",
        },
        pickup: {
          title: "Abholung/Verladung",
        },
        quantity_determination: {
          title: "Mengenermittlung erfolgt?",
        },
        quality_control: {
          title: "Qualitätskontrolle",
        },
        incinerator: {
          title: "Abfall aus einer Verbrennungsanlage?",
        },
        analysis: {
          title: "Analytik vorhanden?",
          helper: null,
        },
        analysis_additional: {
          title: "Anmerkungen zur Analytik",
          helper: null,
        },
        size: {
          title: "Größe/Länge/Kubator des Abfalls",
          helper: null,
        },
        foreign_substance: {
          title: "Sind Fremdstoffe oder Störstoffe im Material vorhanden?",
          helper: null,
        },
        foreign_substance_additional: {
          title: "Um welche Fremdstoffe handelt es sich?",
          helper: null,
        },
        licensed: {
          title: "Ist das Material lizensiert?",
          helper: null,
        },
        licensed_additional: {
          title: "Um welche Lizenzen handelt es sich?",
          helper: null,
        },
        is_indexed: {
          title: "Gibt es eine Indexbindung?",
          helper: null,
        },
        mixture: {
          title: "Sortenrein oder Gemisch?",
          helper: null,
        },
        kind: {
          title: "Kunststoffart (HDFPE, …)",
          helper: null,
        },
        bulking: {
          title: "Füllstoffe",
          helper: null,
        },
        bulking_additional: {
          title: "Welcher Anteil in Prozent?",
          helper: null,
        },
        fiber: {
          title: "Glasfaser",
          helper: null,
        },
        fiber_additional: {
          title: "Welcher Anteil in Prozent?",
          helper: null,
        },
        flame_retardant: {
          title: "Flammhemmer",
          helper: null,
        },
        additives: {
          title: "Andere Additive",
          helper: null,
        },
        color: {
          title: "Farbe",
          helper: null,
        },
        coating: {
          title: "Beschichtung (Wachs, Folie)",
          helper: null,
        },
        smell: {
          title: "Geruch?",
          helper: null,
          code: {
            INTENSIV: "intensiv",
            SCHWACH: "schwach",
            OHNE: "ohne",
            LOESEMITTEL: "nach Lösemittel",
          },
        },
        sample_creator: {
          code: {
            saubermacher: "Saubermachter",
            kunde: "Kunde",
          },
        },
        process_selection: {
          title: "Art des Abfalls",
          code: {
            BETRIEBSMITTELABFALL: "Betriebsmittelabfall",
            PRODUKTIONSABFALL: "Produktionsabfall",
          },
        },
        consistency: {
          title: "Abfallkonsistenz?",
          helper: null,
        },
        consistency_additional: {
          title: "Beschreibung der Abfallkonsistenz",
          helper: null,
        },
        characterization_available: {
          title: "Grundlegene Charakterisierung vorhanden?",
          helper: null,
        },
        assassment_available: {
          title: "weitere Beurteilungen vorhanden?",
          helper: null,
        },
        ph_value: {
          title: "pH-Wert",
          helper: null,
        },
        comment: {
          title: "Kommentar/Zusatz",
          helper: null,
        },
      },
    },
    cancel: {
      modal: {
        title: "Ticket stornieren",
        confirmation:
          "Sind sie sich sicher, dass sie das Ticket №{{number}} stornieren möchten?",
        actions: {
          submit: {
            title: "Stornieren",
          },
          cancel: {
            title: "Abbrechen",
          },
        },
      },
    },
    complete: {
      modal: {
        title: "Ticket abschließen",
        info: "Bitte helfen sie uns besser zu werden, indem Sie die Verwertung dokumentieren.",
        actions: {
          complete: {
            title: "Abschließen",
          },
          cancel: {
            title: "Abbrechen",
          },
        },
        section: {
          order: {
            title: "Vermerk zur Auftragsvergabe",
            fields: {
              hasReceivedOrder: {
                title: "Hat Saubermacher den Auftrag erhalten?",
                placeholder: "Bitte auswählen",
                helper: null,
              },
              explanation: {
                title: "Informationen über den Mitbewerb",
                placeholder: "Bitte ausfüllen",
                helper:
                  "Bitte dokumentieren Sie wer den Auftrag bekommen hat und führen Sie mögliche Begründungen an.",
              },
            },
          },
        },
      },
    },
  },
  claim: {
    claim_type: {
      UNPLANNED: "Ungeplant",
      PLANNED: "Geplant",
      null: "Unausgefüllt",
    },
    dialog: {
      title: "Reklamation abschließen",
      content: "Soll die Reklamation wirklich geschlossen werden?",
      confirm_btn: "Abschließen",
    },
  },
  claims: {
    title: "Reklamationen",
    detail: {
      archive: "Abschließen",
    },
  },
  create_claim: {
    title: "Reklamation erstellen",
    already_existing: "Reklamation bereits vorhanden!",
    result: {
      success: "Reklamation wurde erfolgreich erstellt",
      error: "Ein Fehler ist aufgetreten. Bitte überprüfen Sie Ihre Eingabe!",
    },
    search: {
      result: {
        error:
          "Ein unbekannter Fehler ist aufgetreten. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.",
      },
      from_date: "von",
      to_date: "bis",
      carrier: {
        title: "Frächter",
        helper: "",
        placeholder: "",
      },
      objectnumber: {
        title: "Objektnummer",
        helper: "",
      },
      deliverynumber: {
        title: "Lieferscheinnummer",
        helper: "",
      },
      consignment_note_number: {
        title: "Begleitscheinnummer",
        helper: "",
      },
      source_plant: {
        title: "Quellentsorgungsanlage",
        helper: "",
        placeholder: "",
      },
      target_plant: {
        title: "Zielentsorgungsanlage",
        helper: "",
        placeholder: "",
      },
      filter: "Aufträge filtern",
    },
    no_filter_selected:
      "Bitte wählen Sie einen Filter aus um Aufträge anzuzeigen",
    table: {
      title: "Aufträge",
      heading: {
        created_at: "Datum",
        objectnumber: "Objekt Nr.",
        material_nr: "Material Nr.",
        material_desc: "Material",
        material_weight: "Gewicht",
        material_unit: "Einheit",
        carrier_name: "Frächter",
        deliverynumber: "Lieferschein Nr.",
        consignment_note_number: "Begleitschein Nr.",
        source_plant: "Quellentsorgungsanlage",
        target_plant: "Zielentsorgungsanlage",
      },
      unit_options: {
        percentage: "Prozent",
        tons: "Tonnen",
        kg: "Kilogramm",
      },
      create_claim: "Reklamation erstellen",
      found_entries: "Aufträge gefunden",
      found_entry: "Auftrag gefunden",
    },
    form: {
      title: "Reklamation erstellen für Auftrag #",
      validation_error: "Bitte überprüfen Sie Ihre Eingaben!",
      claim_type: {
        title: "Art der Abweichung",
        helper: null,
        placeholder: "Wählen Sie eine Abweichungsart aus",
      },
      type_options: {
        PLANNED: "geplant",
        UNPLANNED: "ungeplant",
      },
      order_section: {
        title: "Auftragsinformationen",
        order_date: {
          title: "Auftragsdatum",
          helper: null,
        },
        order_number: {
          title: "Objekt Nr.",
          helper: null,
        },
        material_number: {
          title: "Material Nr.",
          helper: null,
        },
        material: {
          title: "Material",
          helper: null,
        },
        material_weight: {
          title: "Menge",
          helper: null,
        },
        carrier: {
          title: "Frächter",
          helper: "",
        },
        delivery_number: {
          title: "Lieferscheinnummer",
          helper: "",
        },
        consignment_note_number: {
          title: "Begleitscheinnummer",
          helper: "",
        },
        source_plant: {
          title: "Quellentsorgungsanlage",
          helper: "",
        },
        target_plant: {
          title: "Zielentsorgungsanlage",
          helper: "",
        },
      },
      material_section: {
        title: "Umdeklaration des Materials/Materialsplit",
        material_correction: {
          title: "Materialkorrektur",
          helper: null,
        },
        comment: {
          title: "Anmerkung",
          helper: null,
        },
        quantity: {
          title: "Menge",
          helper: null,
        },
        unit: {
          title: "Einheit",
          helper: null,
          placeholder: "Mengeneinheit wählen",
        },
        unit_options: {
          percentage: "Prozent",
          tons: "Tonnen",
          kg: "Kilogramm",
        },
        remove_material: "Material entfernen",
        add_material: "Material hinzufügen",
      },
      physical_quality_section: {
        title: "Sichtkontrolle/physikalische Qualitätsabweichung",
        deviation_type: {
          title: "Qualitätsabweichung",
          helper: null,
          placeholder: "Art der Qualitätsabweichung",
        },
        description: {
          title: "Beschreibung",
          helper: null,
        },
        comment: {
          title: "Anmerkung",
          helper: null,
        },
        deviation_options: {
          STOERSTOFFE: "Störstoffe",
          FEUCHTE: "Feuchte",
          FARBE: "Farbe",
          STAUBEND: "Staubend",
          KORNGROESSE: "Korngröße",
          GEWICHT: "Gewicht",
          SONSTIGES: "Sonstiges",
        },
      },
      chemical_quality_section: {
        title: "Chemische Qualitätsabweichung",
        deviation_type: {
          title: "Qualitätsabweichung",
          helper: null,
          placeholder: "Art der Qualitätsabweichung",
        },
        description: {
          title: "Beschreibung",
          helper: null,
        },
        comment: {
          title: "Anmerkung",
          helper: null,
        },
        deviation_options: {
          SCHWERMETALLE: "Schwermetalle",
          WASSERGEHALT: "Wassergehalt",
          PCB: "PCB",
          HEIZWERT: "Heizwert",
          FLAMMPUNKT: "Flammpunkt",
          ANLIEFERTEMPERATUR: "Anliefertemperatur",
          SEDIMENTE: "Sedimente",
          VISKOSITAT: "Viskosität",
          CHLOR: "Chlor",
          HALOGENE: "Halogene",
          SONSTIGES: "Sonstiges",
          LEITFAEHIGKEIT: "Leitfähigkeit",
          PHWERT: "pH-Wert",
          SCHWEFEL: "Schwefel",
        },
      },
      logistic_quality_section: {
        title: "Logistikabweichung",
        deviation_type: {
          title: "Qualitätsabweichung",
          helper: null,
          placeholder: "Art der Logistikabweichung",
        },
        comment: {
          title: "Anmerkung",
          helper: null,
        },
        deviation_options: {
          WRONG_PACKAGE: "falsche Verpackung",
          WRONG_CONTAINER: "falsches Gebinde",
          WRONG_LABEL: "falsche Kennzeichnung",
        },
      },
      production_section: {
        title: "Produktion",
        plant: {
          title: "Betroffener Lagerbereich/Anlage",
          helper: null,
          placeholder: "Wählen Sie eine Anlage aus",
        },
        repeated_error: {
          title: null,
          helper: "Wiederholfehler",
        },
        reason: {
          title: "Ursache",
          helper: null,
        },
        recommended_action: {
          title: "(vorgeschlagene) Maßnahme",
          helper: null,
        },
        comment: {
          title: "Anmerkung",
          helper: null,
        },
      },
      partner_service_section: {
        title: "Partnerservice",
        price_mismatch: {
          title: "Preis stimmt nicht mit Kontrakt überein",
          helper: "Preis stimmt nicht mit Kontrakt überein",
          placeholder: null,
        },
        invoice_price: {
          title: "Preis lt. Rechnung",
          helper: "Angabe in €",
          SURCHARGE: {
            label: "Zuschlag [€/t]",
            helper: "Angabe in €",
          },
          FIXED_PRICE: {
            label: "Fixpreis [€/t]",
            helper: "Angabe in €",
          },
          SPECIAL_PRICE: {
            label: "Sonderposition [€]",
            helper: "Angabe in €",
          },
        },
        comment: {
          title: "Anmerkung",
          helper: null,
        },
        missing_billed_material: {
          title: "Verrechnetes Material ist nicht angelegt",
          helper: "Verrechnetes Material ist nicht angelegt",
        },
        deviation_position: {
          title: "Abweichungsposition",
          helper: null,
          placeholder: "Wählen Sie eine Abweichungsposition aus",
          ANDERES_MATERIAL: {
            label: "Anderes Material",
            helper: null,
            field: "Anmerkung",
          },
          REGIE: {
            label: "Regie",
            helper: null,
            field: "Anmerkung",
          },
          SORTIERKOSTEN: {
            label: "Sortierkosten",
            helper: null,
            field: "Anmerkung",
          },
          MANIPULATION: {
            label: "Manipulation",
            helper: null,
            field: "Anmerkung",
          },
        },
        comment_deviation_position: {
          title: "Anmerkung",
          helper: null,
        },
        deviation_position_options: {
          ANDERES_MATERIAL: "Anderes Material",
          REGIE: "Regie",
          SORTIERKOSTEN: "Sortierkosten",
          MANIPULATION: "Manipulation",
        },
      },
      file_upload_section: {
        title: "Fileupload",
      },
      create_button: "Erstellen",
    },
  },
  edit_claim: {
    not_found: "Reklamation nicht gefunden",
    archive: {
      success: "Reklamation erfolgreich bearbeitet",
      error: "Ein Fehler ist aufgetreten. Bitte überprüfen Sie Ihre Eingabe!",
    },
    result: {
      success: "Reklamation erfolgreich bearbeitet",
      error: "Ein Fehler ist aufgetreten. Bitte überprüfen Sie Ihre Eingabe!",
    },
    add_comment: {
      result: {
        success: "Kommentar erfolgreich hinzugefügt",
      },
    },
    form: {
      title: "Reklamation bearbeiten",
      title_closed: "Reklamation geschlosssen",
      submit_button: "Speichern",
      assign_button: "Zuweisen",
      validation_error: "Bitte überprüfen Sie Ihre Eingaben!",
    },
    assign_role: {
      error:
        "Rolle konnte nicht zugewiesen werden. Bitte überprüfen Sie Ihre Eingabe!",
    },
  },
  components: {
    assign_role_dialog: {
      title: "Zuweisen an: {{role}}",
      user_select_placeholder: "Ticket zuweisen an (Pflichtfeld)...",
      on_submit: "Zuweisen",
    },
    claim_lane: {
      sublane: "Reklamationstickets",
    },
    claim_lane_item: {
      title: "Reklamation #{{claimNumber}}",
      material: "Material",
      plant: "Anlage",
      object_nr: "Objekt Nr.",
    },
    claim_list: {
      empty: "Es konnten keine Reklamationen gefunden werden",
      error: "Reklamationen konnten nicht geladen werden",
    },
    claim_list_item: {
      title: "Reklamation #{{claimNumber}} • {{username}}",
      claim_type: "Art der Abweisung",
      material: "Material",
      plant: "Anlage",
    },
    claim_list_page: {
      error: "Reklamationen konnten nicht geladen werden.",
    },
    dropzone: {
      upload:
        "Neuen Anhang hier ablegen. (Erlaubt: *.pdf, *.jpg, *.jpeg, *.png, *.doc(x), *.xls(x))",
      drophere: "Legen Sie die Datei hier ab um den Upload zu beginnen.",
      reject: "Diese Datei wird nicht unterstützt.",
    },
    list: {
      empty: "Es konnte nichts gefunden werden",
      error: "Daten konnten nicht geladen werden",
    },
    select: {
      placeholder: "Wählen Sie aus...",
    },
    user_select: {
      placeholder: "Benutzer auswählen",
    },
  },
};
