import * as React from "react";
import { withTranslation } from "react-i18next";
import { IBaseProps } from "../../../../utils/BaseProps";
// import { FieldTextStateless } from "@atlaskit/field-text"
import TextField from "@atlaskit/textfield";
import { Field, HelperMessage } from "@atlaskit/form";

interface IShowTextProps extends IBaseProps {
  name: string;
  translation: String;
  required?: boolean | null;
  disabled?: boolean;
  value?: string;
  onChange?: (name: string, value: string) => void;
  style?: any;
}

export default withTranslation()((props: IShowTextProps) => {
  const { t, name, translation, style } = props;
  const helper = t(`${translation}.helper`);
  return (
    <Field
      label={t(`${translation}.title`)}
      name={name}
      isRequired={!!props.required}
    >
      {({ fieldProps }: { fieldProps: any }) => (
        <React.Fragment>
          <TextField
            {...fieldProps}
            isDisabled={props.disabled}
            defaultValue={props.value}
            onChange={(event: any) => props.onChange && props.onChange(name, event.target.value)}
            onBlur={(event: any) =>
              props.onChange && props.onChange(name, event.target.value)
            }
            style={style}
          />
          {helper && <HelperMessage>{helper}</HelperMessage>}
        </React.Fragment>
      )}
    </Field>
  );
});
