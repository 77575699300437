import { Order } from "../../../../../../../api/graphql/queries/orderSearch";
import moment from "moment";
import { CreateClaimButton } from "../../button/CreateClaimButton";
import { OrderTableHeading } from "../OrderTableHeading";
import { FormattedMaterialUnit } from "../FormattedMaterialUnit";

const TABLE_KEYS = {
  orderNr: "orderNr",
  consignmentNoteNumber: "consignmentNoteNumber",
  deliveryNumber: "deliveryNumber",
  carrierName: "carrierName",
  materialNr: "materialNr",
  materialDesc: "materialDesc",
  materialWeight: "materialWeight",
  materialUnit: "materialUnit",
  sourcePlant: "sourcePlant",
  targetPlant: "targetPlant",
  createClaim: "createClaim",
  createdAt: "createdAt",
};

const formatDate = (date: string) => {
  return moment(date).locale("de").format("DD.MM.YYYY");
};

export const generateOrderTableHead = () => {
  return {
    cells: [
      {
        key: TABLE_KEYS.createdAt,
        content: (
          <OrderTableHeading name="create_claim.table.heading.created_at" />
        ),
      },
      {
        key: TABLE_KEYS.orderNr,
        content: (
          <OrderTableHeading name="create_claim.table.heading.objectnumber" />
        ),
      },
      {
        key: TABLE_KEYS.materialNr,
        content: (
          <OrderTableHeading name="create_claim.table.heading.material_nr" />
        ),
      },
      {
        key: TABLE_KEYS.materialDesc,
        content: (
          <OrderTableHeading name="create_claim.table.heading.material_desc" />
        ),
      },
      {
        key: TABLE_KEYS.materialWeight,
        content: (
          <OrderTableHeading name="create_claim.table.heading.material_weight" />
        ),
      },
      {
        key: TABLE_KEYS.materialUnit,
        content: (
          <OrderTableHeading name="create_claim.table.heading.material_unit" />
        ),
      },
      {
        key: TABLE_KEYS.carrierName,
        content: (
          <OrderTableHeading name="create_claim.table.heading.carrier_name" />
        ),
      },
      {
        key: TABLE_KEYS.deliveryNumber,
        content: (
          <OrderTableHeading name="create_claim.table.heading.deliverynumber" />
        ),
      },
      {
        key: TABLE_KEYS.consignmentNoteNumber,
        content: (
          <OrderTableHeading name="create_claim.table.heading.consignment_note_number" />
        ),
      },
      {
        key: TABLE_KEYS.sourcePlant,
        content: (
          <OrderTableHeading name="create_claim.table.heading.source_plant" />
        ),
      },
      {
        key: TABLE_KEYS.targetPlant,
        content: (
          <OrderTableHeading name="create_claim.table.heading.target_plant" />
        ),
      },
      { key: TABLE_KEYS.createClaim, content: "" },
    ],
  };
};

export const generateOrderTableRows = (orders: Order[]) => {
  const rows = orders.map((order) => {
    return {
      key: order.id,
      cells: [
        {
          key: TABLE_KEYS.createdAt,
          content: <span>{formatDate(order.orderDate)}</span>,
        },
        {
          key: TABLE_KEYS.orderNr,
          content: <span>{order.orderNr}</span>,
        },
        {
          key: TABLE_KEYS.materialNr,
          content: <span>{order.material?.matnr}</span>,
        },
        {
          key: TABLE_KEYS.materialDesc,
          content: <span>{order.material?.title}</span>,
        },
        {
          key: TABLE_KEYS.materialWeight,
          content: <span>{order.materialWeight}</span>,
        },
        {
          key: TABLE_KEYS.materialUnit,
          content: <FormattedMaterialUnit unit={order.materialUnit} />,
        },
        {
          key: TABLE_KEYS.carrierName,
          content: <span>{order.carrierId}</span>,
        },
        {
          key: TABLE_KEYS.deliveryNumber,
          content: <span>{order.deliveryNumber}</span>,
        },
        {
          key: TABLE_KEYS.consignmentNoteNumber,
          content: <span>{order.consignmentNoteNumber}</span>,
        },
        {
          key: TABLE_KEYS.sourcePlant,
          content: <span>{order.sourcePlant?.facility}</span>,
        },
        {
          key: TABLE_KEYS.targetPlant,
          content: <span>{order.targetPlant?.facility}</span>,
        },
        {
          key: TABLE_KEYS.createClaim,
          content: <CreateClaimButton order={order} />,
        },
      ],
    };
  });
  return rows;
};
