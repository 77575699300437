import { gql } from "apollo-boost";
import { Mutation } from "react-apollo";
import { documentUtilization, documentUtilizationVariables } from "./types/documentUtilization";

export default gql` 
  mutation documentUtilization(
    $ticketId: ID!,
    $compensation: Float,
    $costs: Float,
    $logisticCosts: Float,
    $materialId: ID,
    $materialRemark: String,
    $oenormId: ID,
    $plantId: ID,
    $selectedUnit: Unit,
    $standort: String,
    $streckenabholung: Boolean,
    $transportation: Transportation,
    $notPossibleDisposal: Boolean,
    $notPossibleDisposalReason: NotPossibleDisposalReason,
    $notPossibleDisposalNote: String
  ) {
    documentUtilization(
      ticketId: $ticketId,
      compensation: $compensation,
      costs: $costs,
      logisticCosts: $logisticCosts,
      materialId: $materialId,
      materialRemark: $materialRemark,
      oenormId: $oenormId,
      plantId: $plantId,
      selectedUnit: $selectedUnit,
      standort: $standort,
      streckenabholung: $streckenabholung,
      transportation: $transportation,
      notPossibleDisposal: $notPossibleDisposal,
      notPossibleDisposalReason: $notPossibleDisposalReason,
      notPossibleDisposalNote: $notPossibleDisposalNote    
    ) {
      id
    }
  }
`

export class DocumentUtilizationMutation extends Mutation<documentUtilization, documentUtilizationVariables> {}
