import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import { PlantFragment } from "../fragments/types/PlantFragment";

export const PLANTS_QUERY = gql`
  query plants {
    plants {
      id
      name
      facility
      street
      number
      postal
      city
    }
  }
`;

export class PlantsQuery extends Query<PlantFragment> {}
