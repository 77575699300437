import { gql } from 'apollo-boost'
import { Query } from 'react-apollo';
import { FindRecommendations } from './types/findRecommendations';

export default gql`
query FindRecommendations($materialId:ID, $oenormId:ID!, $divisionId:ID!, $plantId:ID){
    findRecommendations(
        materialId:$materialId,
        oenormId:$oenormId,
        divisionId:$divisionId,
        plantId:$plantId
    ){
      plant{
        id
        name
        facility
        street
        number
        postal
        city
      }  
      ticket{
        id
      }
      costs
      createdAt
      notPossibleDisposal
      notPossibleDisposalReason
      notPossibleDisposalNote
    }
  }
`

export class FindRecommendationsQuery extends Query<FindRecommendations> {
}
