import React, { FunctionComponent } from "react";
import queryString from "querystring";
import { TicketDetailExternal } from "./TicketDetailExternal";
import { TicketDetail } from "./TicketDetail";
import ticketQuery, { TicketQuery } from "../../../api/graphql/queries/ticket";
import __ from "lodash";
import PageLoadingIndicator from "../../partials/PageLoadingIndicator";
import { AuthConsumer } from "../../../context/AuthContext";

interface ITicketDetailContentProps {
}

export const TicketDetailContent: FunctionComponent<ITicketDetailContentProps> = (props) => {
  const parsed = queryString.parse(window.location.search)
  if (Object.keys(parsed).length > 0) {
    const materialNumber = Object.values(parsed)[0] as string
    const customerId = Object.values(parsed)[1] as string

    return <TicketDetailExternal
      materialNumber={materialNumber}
      customerId={customerId}
    />
  }

  return (
    <AuthConsumer>
      {({hasAbility, userId, token}) => (
        <TicketQuery
          query={ticketQuery}
          fetchPolicy={'network-only'}
          variables={{id: __.get(props, "match.params.id")}}
        >
          {({data, refetch, loading}) => {
            const ticket = data?.ticket || null;
            if (loading) {
              return <PageLoadingIndicator/>
            }
            return ticket ? <TicketDetail
              data={data!}
              ticket={ticket}
              refetch={refetch}
              hasAbility={hasAbility}
              token={token!}
              userId={userId!}
            /> : null
          }}
        </TicketQuery>
      )}
    </AuthConsumer>
  )
}