import PageHeader from "@atlaskit/page-header";
import Spinner from "@atlaskit/spinner";
import React from "react";
import { useQuery } from "react-apollo-hooks";
import { useTranslation } from "react-i18next";
import { Order } from "../../../../../api/graphql/queries/orderSearch";
import { PLANTS_QUERY } from "../../../../../api/graphql/queries/plants";
import { AuthConsumer } from "../../../../../context/AuthContext";
import { CreateClaimProvider } from "../../../../partials/claim/context/create-claim-context";
import { CreateClaimForm } from "./CreateClaimForm";

interface ICreateClaimFormPageProps {
  order: Order;
}

export const CreateClaimFormPage: React.FC<ICreateClaimFormPageProps> = (
  props
) => {
  const { t } = useTranslation();
  const { order } = props;

  const { data: plants } = useQuery(PLANTS_QUERY);

  if (order.id) {
    return (
      <AuthConsumer>
        {({ hasAbility }) => (
          <CreateClaimProvider hasAbility={hasAbility}>
            <PageHeader>
              {t("create_claim.form.title")} {order.orderNr}
            </PageHeader>
            {plants?.plants ? (
              <CreateClaimForm order={order} plants={plants.plants} />
            ) : (
              <div
                style={{
                  flex: 1,
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "4em",
                }}
              >
                <Spinner size={"large"} />
              </div>
            )}
          </CreateClaimProvider>
        )}
      </AuthConsumer>
    );
  }

  return (
    <div
      style={{
        flex: 1,
        justifyContent: "center",
        display: "flex",
        marginTop: "4em",
      }}
    >
      <h3>Es konnte kein Auftrag gefunden werden</h3>
    </div>
  );
};
