import React, { useState, VFC } from "react";
import { GridColumn } from "@atlaskit/page";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { LogisticQualityDeviationType } from "../../../api/graphql/graphql-global-types";
import EditSelect, {
  IEditSelectOption,
} from "../../pages/tickets/fields/EditSelect";
import { CustomTextArea } from "../CustomTextArea";
import { FormSection } from "../FormSection";
import { ILogisticQualitySection } from "./types/claim-types";

interface ILogisticsFormSectionProps {
  section: ILogisticQualitySection;
  onChange: (section: ILogisticQualitySection) => void;
  disabled?: boolean;
}

export const LogisticsFormSection: VFC<ILogisticsFormSectionProps> = (
  props
) => {
  const { section, onChange, disabled } = props;
  const { t } = useTranslation();

  const deviationOptions = getDeviationOptions(t);
  const [deviationOption, setDeviationOption] = useState(
    deviationOptions.find((option) => option.value === section.deviationType) ??
      null
  );

  const onChangeDeviationType = (
    _: string,
    selected: IEditSelectOption | null
  ) => {
    const selectedDeviation = selected
      ? {
          label: selected.label,
          value: selected.value,
        }
      : null;
    setDeviationOption(selectedDeviation);

    const changedLogisticQuality: ILogisticQualitySection = {
      ...section,
      deviationType: selected?.value || null,
    };
    onChange(changedLogisticQuality);
  };

  const onChangeComment = (event: any) => {
    const changedLogisticQuality: ILogisticQualitySection = {
      ...section,
      comment: event?.target?.value ?? "",
    };
    onChange(changedLogisticQuality);
  };

  return (
    <FormSection title={t("create_claim.form.logistic_quality_section.title")}>
      <GridColumn medium={6}>
        <EditSelect
          name="deviation_type"
          translation="create_claim.form.logistic_quality_section.deviation_type"
          options={deviationOptions}
          onChange={onChangeDeviationType}
          selected={deviationOption}
          clearable
          disabled={disabled}
        />
      </GridColumn>
      <GridColumn medium={12}>
        <CustomTextArea
          name="comment"
          translation="create_claim.form.logistic_quality_section.comment"
          value={section.comment}
          onChange={onChangeComment}
          disabled={disabled}
        />
      </GridColumn>
    </FormSection>
  );
};

interface IDeviationOption {
  label: string;
  value: LogisticQualityDeviationType;
  subtitle?: string | null;
}

const getDeviationOptions = (t: i18next.TFunction): IDeviationOption[] => {
  return [
    {
      label: t(
        `create_claim.form.logistic_quality_section.deviation_options.WRONG_PACKAGE`
      ),
      value: LogisticQualityDeviationType.WRONG_PACKAGE,
    },
    {
      label: t(
        "create_claim.form.logistic_quality_section.deviation_options.WRONG_CONTAINER"
      ),
      value: LogisticQualityDeviationType.WRONG_CONTAINER,
    },
    {
      label: t(
        "create_claim.form.logistic_quality_section.deviation_options.WRONG_LABEL"
      ),
      value: LogisticQualityDeviationType.WRONG_LABEL,
    },
  ];
};
