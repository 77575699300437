import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { IBaseProps } from "../../../../utils/BaseProps";
// import { FieldTextStateless } from "@atlaskit/field-text"
import TextArea from "@atlaskit/textarea";
import { Field, HelperMessage } from "@atlaskit/form";

interface IShowTextProps extends IBaseProps {
  name: string;
  translation: String;
  required?: boolean | null;
  disabled?: boolean;
  value?: string;
  onChange?: (name: string, value: string) => void;
}

export default withTranslation()((props: IShowTextProps) => {
  const { t, name, translation } = props;
  const helper = t(`${translation}.helper`);
  const [internal, setInternal] = useState<string | undefined>(undefined);
  return (
    <Field
      label={t(`${translation}.title`)}
      name={name}
      isRequired={!!props.required}
    >
      {({ fieldProps }: { fieldProps: any }) => (
        <React.Fragment>
          <div style={{ position: "relative" }}>
            <TextArea
              {...fieldProps}
              value={internal || props.value}
              isDisabled={props.disabled}
              onChange={(event: any) => {
                if (props.onChange && `${event.target.value}` === "") {
                  props.onChange(name, event.target.value);
                }
                setInternal(event.target.value);
              }}
              onBlur={(event: any) => {
                props.onChange && props.onChange(name, event.target.value);
                setInternal(undefined);
              }}
            />
            {internal && internal !== props.value && (
              <div
                style={{
                  position: "absolute",
                  right: "5px",
                  bottom: "5px",
                  opacity: 0.5,
                  fontSize: "0.9em",
                  cursor: "pointer",
                }}
              >
                Nicht gespeichert
              </div>
            )}
          </div>
          {helper && <HelperMessage>{helper}</HelperMessage>}
        </React.Fragment>
      )}
    </Field>
  );
});
