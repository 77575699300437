import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import { QueryHookOptions, useQuery } from "react-apollo-hooks";
import { Tickets, TicketsVariables } from "./types/Tickets";

export const TICKETS_QUERY = gql`
  query Tickets($showCompletedTickets: Boolean) {
    tickets(showCompletedTickets: $showCompletedTickets) {
      id
      number
      status
      requestMaterialAnalysis
      assignedRole
      assignedUser {
        id
        name
        email
      }
      general {
        partnerName
        oenorm {
          id
          title
          code
          spezifikation
        }
        material {
          id
          title
          matnr
        }
        partner {
          id
          name
          code
        }
        division {
          id
          title
        }
      }
      utilizations {
        id
      }
    }
  }
`;

export class TicketsQuery extends Query<Tickets> {}
type TicketsResult = Tickets;
export const useTicketsQuery = (options?: QueryHookOptions<TicketsVariables>) =>
  useQuery<TicketsResult>(TICKETS_QUERY, options);

