import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import { SearchOrders, SearchOrders_searchOrders } from "./types/SearchOrders";

export default gql`
  query SearchOrders(
    $orderNr: String
    $consignmentNoteNumber: String
    $deliveryNumber: String
    $carrierFleetEquNr: String
    $partnerId: String
    $sourcePlantId: String
    $targetPlantId: String
    $dateFrom: Date
    $dateTo: Date
  ) {
    searchOrders(
      orderNr: $orderNr
      consignmentNoteNumber: $consignmentNoteNumber
      deliveryNumber: $deliveryNumber
      carrierFleetEquNr: $carrierFleetEquNr
      partnerId: $partnerId
      sourcePlantId: $sourcePlantId
      targetPlantId: $targetPlantId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      id
      orderNr
      orderLaufNr
      consignmentNoteNumber
      deliveryNumber
      carrierId
      sourcePlant {
        id
        name
        facility
        street
        number
        postal
        city
      }
      targetPlant {
        id
        name
        facility
        street
        number
        postal
        city
      }
      material {
        id
        matnr
        title
        prdha
        pks {
          ktext
          cost
          compensation
        }
      }
      materialWeight
      materialUnit
      createdAt
      orderDate
    }
  }
`;

export type Order = SearchOrders_searchOrders;
export class SearchOrdersQuery extends Query<SearchOrders> {}
