import React, { Fragment } from "react";
import __ from "lodash";
import "url-search-params-polyfill";
import { claimHistory_claimHistory } from "../../../../api/graphql/queries/types/claimHistory";
import { HistoryEntryType } from "../../../../domain/models/history-entry-type";
import lodash from "lodash";
import moment from "moment";

interface IClaimHistoryEntryProps {
  historyEntry: claimHistory_claimHistory | null;
}

const formatHistoryEntry = (historyEntry: claimHistory_claimHistory) => {
  switch (historyEntry.entryType) {
    case HistoryEntryType.ADD_COMMENT:
      return historyEntry.user.name + " hat kommentiert.";
    case HistoryEntryType.ASSIGN_ROLE:
      return `${
        historyEntry.user.name
      } hat die Zuweisung von ${lodash.capitalize(
        historyEntry.oldValue || ""
      )} auf ${lodash.capitalize(historyEntry.newValue || "")} geändert.`;
    case HistoryEntryType.ASSIGN_USER:
      return `Die Reklamation wurde ${historyEntry.newValue} zugewiesen.`;
    case HistoryEntryType.CREATE_CLAIM:
      return `Die Reklamation wurde von ${historyEntry.user.name} erstellt.`;
    case HistoryEntryType.LINK_ATTACHMENT:
      return `${historyEntry.user.name} hat der Reklamation ein Dokument angehängt.`;
    case HistoryEntryType.UPDATE_CLAIM:
      return `Die Reklamation wurde von ${historyEntry.user.name} bearbeitet`;
    default:
      return "";
  }
};

export const ClaimHistoryEntry: React.FunctionComponent<
  IClaimHistoryEntryProps
> = (props) => {
  const { historyEntry } = props;

  if (historyEntry) {
    return (
      <li style={{ margin: "8px 0" }}>
        <strong>
          {moment(historyEntry.createdAt).format("DD.MM.YYYY HH:mm")}{" "}
        </strong>
        {formatHistoryEntry(historyEntry)}
      </li>
    );
  }

  return <Fragment />;
};
