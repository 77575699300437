import * as React from "react"
import __ from "lodash"
import { withTranslation } from "react-i18next"
import { Grid, GridColumn } from "@atlaskit/page"
import { CardFrame } from "@atlaskit/media-ui"
import CardFrameContent from "../../../../partials/CardFrameContent"
import { IBaseProps } from "../../../../../utils/BaseProps"
import { Ticket_ticket_houseWaste as Specifics } from "../../../../../api/graphql/queries/types/Ticket"
import Headline from "../../partials/Headline"
import ShowText from "../../fields/ShowText"

interface IHouseWasteProps extends IBaseProps {
  specifics: Specifics
}
  
interface IHouseWasteState {
    
}
  
class HouseWaste extends React.Component<IHouseWasteProps, IHouseWasteState> {
  state: IHouseWasteState
  
  constructor(props: IHouseWasteProps) {
    super(props)

    this.state = {
    }
  }
  
  private renderCompanyAndProcess() {
    const { t, specifics } = this.props
    return (
      <React.Fragment>
        <GridColumn medium={12}>
          <Headline>{t('company_process')}</Headline>
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="process" translation="tickets.fields.company_process.process" value={specifics.process} />
        </GridColumn>
      </React.Fragment>
    )
  }

  private renderAdditional() {
    const { t, specifics } = this.props
    return (
      <React.Fragment>
        <GridColumn medium={12}>
          <Headline>{t('additional')}</Headline>
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="comment" translation="tickets.fields.material_description.comment" value={specifics.comment} />
        </GridColumn>
      </React.Fragment>
    )
  }

  public render() {
    const { t, specifics } = this.props
    console.log(specifics)
    return (
      <CardFrame text={t('tickets.create.fields.kunststoffe.title')}>
        <CardFrameContent>
          <Grid layout="max-width">
            <GridColumn medium={12}>
              <Headline>{t('generic')}</Headline>
            </GridColumn>
            <GridColumn medium={6}>
              <ShowText name="waste_description" translation="tickets.create.fields.waste_description" value={specifics.description} />
            </GridColumn>
            {this.renderCompanyAndProcess()}
            {this.renderAdditional()}
          </Grid>
        </CardFrameContent>
      </CardFrame>
    )
  }
}

export default withTranslation()(HouseWaste)
