import { Grid, GridColumn } from "@atlaskit/page";
import moment from "moment-timezone";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormattedMaterialUnit } from "../../pages/create-claim/partials/order-search/table/FormattedMaterialUnit";
import { CustomTextDisplay } from "../CustomTextDisplay";
import { FormSection } from "../FormSection";
import { IOrderSection } from "./types/claim-types";

interface IOrderInformationSectionProps {
  onChange: (section: IOrderSection) => void;
  section: IOrderSection;
}

export const OrderInformationSection: React.VFC<
  IOrderInformationSectionProps
> = (props) => {
  const { onChange, section } = props;
  const { t } = useTranslation();

  useEffect(() => {
    onChange(section);
  }, []);

  return (
    <FormSection title={t("create_claim.form.order_section.title")}>
      <GridColumn medium={12}>
        <Grid layout="max-width">
          <GridColumn>
            <CustomTextDisplay
              value={moment(section.orderDate).format("DD.MM.YYYY")}
              name="orderDate"
              label={t("create_claim.form.order_section.order_date.title")}
              helperText={t(
                "create_claim.form.order_section.order_date.helper"
              )}
            />
            <CustomTextDisplay
              value={section.orderNr}
              name="orderNumber"
              label={t("create_claim.form.order_section.order_number.title")}
              helperText={t(
                "create_claim.form.order_section.order_number.helper"
              )}
            />
          </GridColumn>
          <GridColumn>
            <CustomTextDisplay
              value={section.materialNumber || "-"}
              name="materialNumber"
              label={t("create_claim.form.order_section.material_number.title")}
              helperText={t(
                "create_claim.form.order_section.material_number.helper"
              )}
            />
            <CustomTextDisplay
              value={section.materialTitle || "-"}
              name="material"
              label={t("create_claim.form.order_section.material.title")}
              helperText={t("create_claim.form.order_section.material.helper")}
            />
            <CustomTextDisplay
              value={
                <span>
                  {section.materialWeight || "-"}{" "}
                  <FormattedMaterialUnit unit={section.materialUnit} />
                </span>
              }
              name="materialWeight"
              label={t("create_claim.form.order_section.material_weight.title")}
              helperText={t(
                "create_claim.form.order_section.material_weight.helper"
              )}
            />
          </GridColumn>
          <GridColumn>
            <CustomTextDisplay
              value={section.carrierId || "-"}
              name="carrierId"
              label={t("create_claim.form.order_section.carrier.title")}
              helperText={t("create_claim.form.order_section.carrier.helper")}
            />
          </GridColumn>
          <GridColumn>
            <CustomTextDisplay
              value={section.deliveryNumber || "-"}
              name="deliveryNumber"
              label={t("create_claim.form.order_section.delivery_number.title")}
              helperText={t(
                "create_claim.form.order_section.delivery_number.helper"
              )}
            />
            <CustomTextDisplay
              value={section.consignmentNoteNumber || "-"}
              name="consignmentNoteNumber"
              label={t(
                "create_claim.form.order_section.consignment_note_number.title"
              )}
              helperText={t(
                "create_claim.form.order_section.consignment_note_number.helper"
              )}
            />
          </GridColumn>
          <GridColumn>
            <CustomTextDisplay
              value={section.sourcePlant || "-"}
              name="sourcePlant"
              label={t("create_claim.form.order_section.source_plant.title")}
              helperText={t(
                "create_claim.form.order_section.source_plant.helper"
              )}
            />
            <CustomTextDisplay
              value={section.targetPlant || "-"}
              name="targetPlant"
              label={t("create_claim.form.order_section.target_plant.title")}
              helperText={t(
                "create_claim.form.order_section.target_plant.helper"
              )}
            />
          </GridColumn>
        </Grid>
      </GridColumn>
    </FormSection>
  );
};
