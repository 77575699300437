export const Abilities = {
  ADMIN: "admin",
  TICKET: "tic",
  VERTRIEB: "vert",
  LABOR: "lab",
  STOFFSTROM: "sto",
  STOFFSTROM_MANAGER: "sma",
  PARTNER_SERVICE: "part",
} as const;

type Keys = keyof typeof Abilities;
export type Ability = typeof Abilities[Keys];
