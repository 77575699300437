import React, { VFC } from "react";
import Comment, { CommentAuthor, CommentTime } from "@atlaskit/comment";
import Avatar from "@atlaskit/avatar";
import moment from "moment";
import styled from "styled-components";

interface ICommentProps {
  comment: string;
  authorName: string;
  authorGroup: string | null;
  createdAt: Date;
}

const Container = styled.div`
  margin-top: 8px;
  border-bottom: 1px #d3d6dd solid;
`;

export const CommentView: VFC<ICommentProps> = (props) => {
  const { authorGroup, authorName, comment, createdAt } = props;

  return (
    <Container>
      <Comment
        avatar={<Avatar label="Atlaskit avatar" size="medium" />}
        author={<CommentAuthor>{authorName}</CommentAuthor>}
        type={authorGroup}
        time={
          <CommentTime>
            {moment(createdAt).format("DD.MM.YYYY HH:mm")}
          </CommentTime>
        }
        content={comment}
      />
    </Container>
  );
};
