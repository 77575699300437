export function getLinkToWomByGuessing(token: string | undefined, protocol: string, url?: string) {
    if (token) {
        const hostname = window.location.hostname
        const tokenQuery = `?token=${token}`
        const urlAppend = url ? url : ""
        console.log(hostname)
        if (hostname.endsWith(".herokuapp.com")) {
            return `${protocol}://${hostname.split("-").shift()}-wom-webclient.herokuapp.com${urlAppend}/${tokenQuery}`
        }
        if (hostname.endsWith(".saubermacher.at")) {
            return `${protocol}://wom.saubermacher.at${urlAppend}/${tokenQuery}`
        }
        if (hostname.includes("localhost")){
            return `${protocol}://dev-wom-webclient.herokuapp.com${urlAppend}/${tokenQuery}`
        }
    }
    return null
}