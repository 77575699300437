import { gql } from "apollo-boost";
import { QueryHookOptions, useQuery } from "react-apollo-hooks";
import {
  commentsForClaim,
  commentsForClaimVariables,
  commentsForClaim_claim_comments,
  commentsForClaim_claim_comments_author,
} from "./types/commentsForClaim";

export const COMMENT_QUERY = gql`
  query commentsForClaim($claimNumber: Int!) {
    claim(claimNumber: $claimNumber) {
      id
      comments {
        uuid
        comment
        createdAt
        author {
          id
          name
          email
          group
        }
      }
    }
  }
`;

export type Claim = commentsForClaim_claim_comments;
export type ClaimAttachment = commentsForClaim_claim_comments_author;
type CommentsForClaimResult = commentsForClaim;
type CommentsForClaimVariables = commentsForClaimVariables;
export const useCommentsForClaimQuery = (
  options: QueryHookOptions<CommentsForClaimVariables>
) =>
  useQuery<CommentsForClaimResult, CommentsForClaimVariables>(
    COMMENT_QUERY,
    options
  );
