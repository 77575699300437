import React, { FC, useEffect, useState } from "react";
import { useMutation } from "react-apollo-hooks";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ClaimType,
  FileInput,
  PhysicalQualityDeviationType,
  Role,
} from "../../../../api/graphql/graphql-global-types";
import { useAssignRoleToClaim } from "../../../../api/graphql/mutations/AssignRoleToClaim";
import {
  CreateClaimResult,
  CreateClaimVariables,
  CREATE_CLAIM_MUTATION,
} from "../../../../api/graphql/mutations/CreateClaim";
import { Abilities } from "../../../../domain/models/abilities";
import { CLAIM_DEFAULTS } from "../../../../utils/claim-defaults";
import {
  IChemicalQualitySection,
  ILogisticQualitySection,
  IMaterialSection,
  IOrderSection,
  IPartnerServiceSection,
  IPhysicalQualitySection,
  IProductionSection,
} from "../types/claim-types";

interface ICreateClaimContext {
  claimType: ClaimType | undefined;
  materialSection: IMaterialSection[];
  orderSection: IOrderSection;
  physicalQualitySection: IPhysicalQualitySection;
  chemicalQualitySection: IChemicalQualitySection;
  logisticQualitySection: ILogisticQualitySection;
  productionSection: IProductionSection;
  files: FileInput[];
  partnerServiceSection: IPartnerServiceSection;
  assigneeRole: Role;
  disabled: boolean;
  loading: boolean;
  onUpdateClaimType: (claimType: ClaimType | undefined) => void;
  onUpdateOrderSection: (orderSection: IOrderSection) => void;
  onUpdateMaterialSection: (materialSection: IMaterialSection[]) => void;
  onUpdatePhysicalQualitySection: (
    physicalQualitySection: IPhysicalQualitySection
  ) => void;
  onUpdateChemicalQualitySection: (
    chemicalQualitySection: IChemicalQualitySection
  ) => void;
  onUpdateLogisticQualitySection: (
    logisticQualitySection: ILogisticQualitySection
  ) => void;
  onUpdateProductionSection: (productionSection: IProductionSection) => void;
  onUpdateFiles: (files: FileInput[]) => void;
  onUpdatePartnerServiceSection: (
    partnerServiceSection: IPartnerServiceSection
  ) => void;
  onSubmit: (
    orderId: string,
    role: Role,
    comment: string,
    assigneeId: string | undefined
  ) => void;
}

const DEFAULT_VALUES: ICreateClaimContext = {
  ...CLAIM_DEFAULTS,
  assigneeRole: Role.STOFFSTROM,
  disabled: false,
  loading: false,
  onUpdateClaimType: () => {},
  onUpdateOrderSection: () => {},
  onUpdateMaterialSection: () => {},
  onUpdatePhysicalQualitySection: () => {},
  onUpdateChemicalQualitySection: () => {},
  onUpdateLogisticQualitySection: () => {},
  onUpdateProductionSection: () => {},
  onUpdateFiles: () => {},
  onUpdatePartnerServiceSection: () => {},
  onSubmit: () => {},
};

interface ICreateClaimProps {
  hasAbility: (ability: string) => boolean;
}

const useCreateClaimProvider = ({
  hasAbility,
}: ICreateClaimProps): ICreateClaimContext => {
  const { t } = useTranslation();
  const history = useHistory();

  const [claimType, setClaimType] = useState<ClaimType>();
  const [orderSection, setOrderSection] = useState(DEFAULT_VALUES.orderSection);
  const [materialSection, setMaterialSection] = useState(
    DEFAULT_VALUES.materialSection
  );
  const [physicalQualitySection, setPhysicalQualitySection] = useState(
    DEFAULT_VALUES.physicalQualitySection
  );
  const [chemicalQualitySection, setChemicalQualitySection] = useState(
    DEFAULT_VALUES.chemicalQualitySection
  );
  const [logisticQualitySection, setLogisticQualitySection] = useState(
    DEFAULT_VALUES.logisticQualitySection
  );
  const [productionSection, setProductionSection] = useState(
    DEFAULT_VALUES.productionSection
  );
  const [files, setFiles] = useState(DEFAULT_VALUES.files);
  const [partnerServiceSection, setPartnerServiceSection] = useState(
    DEFAULT_VALUES.partnerServiceSection
  );
  const [assigneeRole, setAssigneeRole] = useState(DEFAULT_VALUES.assigneeRole);
  const [disabled, setIsDisabled] = useState(false);

  const [createClaimMutation, { loading, error }] = useMutation<
    CreateClaimResult,
    CreateClaimVariables
  >(CREATE_CLAIM_MUTATION, {
    rethrow: false,
  });

  const [assignRoleToClaimMutation, { loading: assignRoleLoading }] =
    useAssignRoleToClaim({ rethrow: false });

  useEffect(() => {
    if (error) {
      toast.error(t("create_claim.result.error"));
    }
  }, [error]);

  // Update to whom this claim should be initially assigned to.
  // See <https://denovo-gmbh.atlassian.net/browse/SSTS-169> for the expected behavior.
  useEffect(() => {
    if (
      hasAbility(Abilities.STOFFSTROM_MANAGER) ||
      hasAbility(Abilities.STOFFSTROM)
    ) {
      if (claimType === ClaimType.PLANNED) {
        setAssigneeRole(Role.PARTNER_SERVICE);
        return;
      }
      setAssigneeRole(Role.LABOR);
      return;
    }

    if (hasAbility(Abilities.PARTNER_SERVICE)) {
      setIsDisabled(true);
      setAssigneeRole(Role.STOFFSTROM);
    }
  }, [claimType, hasAbility]);

  const onCreateClaim = async (
    orderId: string,
    role: Role,
    comment: string,
    assigneeId: string | undefined
  ) => {
    const claimNumber = await createClaimForRole(orderId);
    if (claimNumber == null) {
      toast.error(t("create_claim.result.error"));
      return;
    }

    // Assign role to the created claim.
    const assignResult = await assignRoleToClaimMutation({
      variables: { claimNumber, role, assigneeId, comment },
    });

    if (!assignResult.data?.assignRoleToClaim) {
      toast.error(t("create_claim.result.error"));
      return;
    }

    // Redirect to details page of the newly created claim.
    history.push("/claims");
    // Fixes empty fields in detail view.
    window.location.reload();
  };

  const createClaimForRole = async (
    orderId: string
  ): Promise<number | undefined> => {
    // Create claim without additional information for partnerservice
    if (disabled) {
      const createResult = await createClaimMutation({
        variables: {
          claim: {
            orderId,
            claimType: claimType,
            orderSection,
            materialSection,
            physicalQualitySection,
            chemicalQualitySection,
            logisticQualitySection,
            files,
            productionSection:
              claimType === ClaimType.UNPLANNED ? productionSection : null,
            partnerServiceSection,
          },
        },
      });
      return createResult.data?.createClaim?.number;
    }

    // Validate input.
    if (!isValidClaimCreation()) {
      toast.error(t("create_claim.form.validation_error"));
      return undefined;
    }

    if (!claimType) {
      return undefined;
    }

    // Create the claim.
    const createResult = await createClaimMutation({
      variables: {
        claim: {
          orderId,
          claimType: claimType,
          orderSection,
          materialSection,
          physicalQualitySection,
          chemicalQualitySection,
          logisticQualitySection,
          files,
          productionSection:
            claimType === ClaimType.UNPLANNED ? productionSection : null,
          partnerServiceSection,
        },
      },
    });
    return createResult.data?.createClaim?.number;
  };

  const isValidClaimCreation = (): boolean => {
    if (!claimType) {
      return false;
    }
    if (claimType === ClaimType.UNPLANNED && !isValidProductionSection()) {
      return false;
    }
    if (!isValidPhysicalQualitySection() || !isValidChemicalQualitySection()) {
      return false;
    }
    return true;
  };

  const isValidPhysicalQualitySection = (): boolean => {
    if (
      physicalQualitySection.deviationType !==
        PhysicalQualityDeviationType.STAUBEND &&
      physicalQualitySection.deviationType != null &&
      !physicalQualitySection.description
    ) {
      return false;
    }
    return true;
  };

  const isValidChemicalQualitySection = (): boolean => {
    if (
      chemicalQualitySection.deviationType != null &&
      !chemicalQualitySection.description
    ) {
      return false;
    }
    return true;
  };

  const isValidProductionSection = (): boolean => {
    if (hasAbility(Abilities.STOFFSTROM)) return true;

    if (productionSection.plantId == null) {
      return false;
    }

    if (!productionSection.reason) {
      return false;
    }

    if (!productionSection.recommendedAction) {
      return false;
    }

    return true;
  };

  const handleClaimTypeChange = (claimType: ClaimType | undefined) => {
    if (!claimType || claimType === ClaimType.PLANNED) {
      setProductionSection(DEFAULT_VALUES.productionSection);
    }

    setClaimType(claimType);
  };

  return {
    claimType,
    orderSection,
    materialSection,
    physicalQualitySection,
    chemicalQualitySection,
    logisticQualitySection,
    productionSection,
    files,
    partnerServiceSection,
    assigneeRole,
    disabled,
    loading: loading || assignRoleLoading,
    onUpdateClaimType: handleClaimTypeChange,
    onUpdateOrderSection: setOrderSection,
    onUpdatePhysicalQualitySection: setPhysicalQualitySection,
    onUpdateMaterialSection: setMaterialSection,
    onUpdateChemicalQualitySection: setChemicalQualitySection,
    onUpdateLogisticQualitySection: setLogisticQualitySection,
    onUpdateProductionSection: setProductionSection,
    onUpdateFiles: setFiles,
    onUpdatePartnerServiceSection: setPartnerServiceSection,
    onSubmit: onCreateClaim,
  };
};

const CreateClaimContext =
  React.createContext<ICreateClaimContext>(DEFAULT_VALUES);

export const CreateClaimProvider: FC<ICreateClaimProps> = (props) => {
  const createclaim = useCreateClaimProvider(props);

  return (
    <CreateClaimContext.Provider value={createclaim}>
      {props.children}
    </CreateClaimContext.Provider>
  );
};

export const useCreateClaimContext = () => React.useContext(CreateClaimContext);
