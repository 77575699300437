import { IEditSelectOption } from "../../components/pages/tickets/fields/EditSelect";

export interface IUnitOption extends IEditSelectOption {
    label: string
    value: string
    subtitle?: string | null
}

export const UNITS: {[key: string]: IUnitOption} = Object.freeze({
    "PIECES": {
        label: "Stück",
        value: "PIECES",
    },
    "TONS": {
        label: "Tonnen",
        value: "TONS",
    },
    "QM": {
        label: "m³",
        value: "QM",
    },
})
