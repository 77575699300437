import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Claim } from "../../../../api/graphql/queries/claims";
import { Lane } from "../../../partials/lane/Lane";
import { ClaimLaneItem } from "./ClaimLaneItem";

interface IClaimLaneProps {
  title: string;
  claims: Claim[];
}

export const ClaimLane: FC<IClaimLaneProps> = (props) => {
  const { t } = useTranslation();
  const { title: laneName, claims } = props;

  const renderItem = (claim: Claim) => (
    <ClaimLaneItem key={claim.id} claim={claim} />
  );

  // Always true as only one lane is used.
  const isInSublane = (sublane: string) => (claim: Claim) => true;

  return (
    <Lane
      title={laneName}
      items={claims ?? []}
      sublanes={[t("components.claim_lane.sublane")]}
      renderItem={renderItem}
      isInSublane={isInSublane}
    />
  );
};
