import {gql} from "apollo-boost";
import {Mutation} from "react-apollo";
import {addComment, addCommentVariables} from "./types/addComment";

export const addCommentMutation =  gql` 
mutation addComment($ticketId: ID!, $comment: String!) {
  addComment(ticketId: $ticketId, comment: $comment) {
    uuid
    comment
    author {
      id
      name
      email
      group
    }
  }
}
`
export class AddCommentMutation extends Mutation<addComment, addCommentVariables> {}
