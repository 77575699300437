import React from "react"
import styled from "styled-components"

const Ellipsis = styled.div`
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

export default (props: any) => (
    <Ellipsis style={props.style}>
        {props.children}
    </Ellipsis>
)
