import React, { useState } from "react";
import { GridColumn } from "@atlaskit/page";
import { useTranslation } from "react-i18next";
import { OenormFragment } from "../../../api/graphql/fragments/types/OenormFragment";
import { IMaterialOption } from "../../../api/graphql/interfaces/OptionsInterfaces";
import EditSelect, {
  IEditSelectOption,
} from "../../pages/tickets/fields/EditSelect";
import { CustomNumberfield } from "../CustomNumberfield";
import { CustomTextArea } from "../CustomTextArea";
import { IMaterialSection } from "./types/claim-types";
import { toSelectOption } from "../../../api/graphql/fragments/Material";
import Button from "@atlaskit/button";
import TrashIcon from "@atlaskit/icon/glyph/trash";
import { IMaterialUnitOption } from "./MaterialFormSection";

interface IMaterialSectionUnitProps {
  materials: IMaterialOption[];
  oenorms: OenormFragment[];
  disabled?: boolean;
  materialUnitOptions: IMaterialUnitOption[];
  section: IMaterialSection;
  onChange: (section: IMaterialSection, index: number) => void;
  index: number;
  onRemove: (index: number) => void;
}

export const MaterialSectionUnit: React.VFC<IMaterialSectionUnitProps> = (
  props
) => {
  const {
    materials,
    oenorms,
    disabled,
    materialUnitOptions,
    section,
    onChange,
    index,
    onRemove,
  } = props;
  const { t } = useTranslation();

  const [materialUnitOption, setMaterialUnitOption] = useState(
    materialUnitOptions.find((unit) => unit.value === section.unit) ?? null
  );
  const [material, setMaterial] = useState<IMaterialOption | null | undefined>(
    materials.find((material) => material.value === section.materialId)
  );

  const onChangeMaterial = (_: string, selected?: IEditSelectOption) => {
    const selectedMaterial = selected
      ? materials.find((m) => m.value === selected.value)
      : null;

    setMaterial(selectedMaterial);
    const changedMaterial = {
      ...section,
      materialId: selectedMaterial?.value ?? null,
    };
    onChange(changedMaterial, index);
  };

  const onChangeMaterialUnit = (
    _: string,
    selected: IEditSelectOption | null
  ) => {
    const selectedMaterial = selected
      ? {
          label: selected.label,
          value: selected.value,
        }
      : null;
    setMaterialUnitOption(selectedMaterial);

    const changedMaterial = {
      ...section,
      unit: selected?.value || null,
    };
    onChange(changedMaterial, index);
  };

  const onChangeMaterialComment = (event: any) => {
    const changedMaterial = {
      ...section,
      comment: event?.target?.value ?? "",
    };
    onChange(changedMaterial, index);
  };

  const onChangeMaterialQuantity = (event: any) => {
    const changedMaterial = {
      ...section,
      quantity: Number(event?.target?.value),
    };
    onChange(changedMaterial, index);
  };

  return (
    <>
      <GridColumn medium={12}>
        {index > 0 && (
          <div
            style={{
              width: "100%",
              justifyItems: "flex-end",
              textAlign: "right",
              marginBottom: "-20px",
            }}
          >
            <Button
              iconBefore={<TrashIcon label={t("general.delete")} />}
              onClick={() => onRemove(index)}
              isDisabled={disabled}
            >
              {t("create_claim.form.material_section.remove_material")}
            </Button>
          </div>
        )}
        <EditSelect
          name="material"
          translation="tickets.create.fields.material"
          options={toSelectOption(materials, oenorms)}
          onChange={onChangeMaterial}
          selected={
            material
              ? {
                  ...material,
                  title: material.matnr,
                  additional: material.title || "Unbekannt",
                  subtitle: null,
                }
              : null
          }
          disabled={disabled}
          clearable
        />
      </GridColumn>
      <GridColumn medium={6}>
        <CustomNumberfield
          name="quantity"
          translation="create_claim.form.material_section.quantity"
          value={section.quantity ?? undefined}
          onChange={onChangeMaterialQuantity}
          disabled={disabled}
        />
      </GridColumn>
      <GridColumn medium={6}>
        <EditSelect
          name="unit"
          translation="create_claim.form.material_section.unit"
          options={materialUnitOptions}
          onChange={onChangeMaterialUnit}
          selected={materialUnitOption}
          clearable
          disabled={disabled}
        />
      </GridColumn>
      <GridColumn medium={12}>
        <CustomTextArea
          name="comment"
          translation="create_claim.form.material_section.comment"
          value={section.comment ?? ""}
          onChange={onChangeMaterialComment}
          disabled={disabled}
        />
      </GridColumn>
    </>
  );
};
