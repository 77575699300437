import React, { useState, useEffect, useCallback } from "react";
import { MaterialFormSection } from "../../../../partials/claim/MaterialFormSection";
import { PhysicalQualityFormSection } from "../../../../partials/claim/PhysicalQualityFormSection";
import { ChemicalQualityFormSection } from "../../../../partials/claim/ChemicalQualityFormSection";
import { LogisticsFormSection } from "../../../../partials/claim/LogisticsFormSection";
import { ProductionFormSection } from "../../../../partials/claim/ProductionFormSection";
import { FileUploadFormSection } from "../../../../partials/claim/FileUploadFormSection";
import { PartnerServiceFormSection } from "../../../../partials/claim/PartnerServiceFormSection";
import { ClaimTypeField } from "../../../../partials/claim/ClaimTypeField";
import { Grid, GridColumn } from "@atlaskit/page";
import Button from "@atlaskit/button";
import { useTranslation } from "react-i18next";
import { PlantFragment } from "../../../../../api/graphql/fragments/types/PlantFragment";
import { useEditClaimContext } from "../../../../partials/claim/context/edit-claim-context";
import { CommentFormSection } from "../../../../partials/claim/CommentFormSection";
import { Abilities } from "../../../../../domain/models/abilities";
import { CardFrame } from "@atlaskit/media-ui";
import CardFrameContent from "../../../../partials/CardFrameContent";
import { ClaimHistory } from "../../../tickets/partials/ClaimHistory";
import { OrderInformationSection } from "../../../../partials/claim/OrderInformationSection";
import __ from "lodash";
import { Role } from "../../../../../api/graphql/graphql-global-types";

interface IEditClaimFormProps {
  plants: PlantFragment[];
}

export const EditClaimForm: React.FC<IEditClaimFormProps> = (props) => {
  const { plants } = props;
  const {
    claimType,
    onUpdateClaimType,
    orderSection,
    onUpdateOrderSection,
    materialSection,
    onUpdateMaterialSection,
    physicalQualitySection,
    onUpdatePhysicalQualitySection,
    productionSection,
    onUpdateProductionSection,
    chemicalQualitySection,
    onUpdateChemicalQualitySection,
    logisticQualitySection,
    onUpdateLogisticQualitySection,
    files,
    onUpdateFiles,
    existingFiles,
    partnerServiceSection,
    onUpdatePartnerServiceSection,
    hasAbility,
    comments,
    addComment,
    disabled,
    commentsLoading,
    submitLoading,
    onSubmitUpdateClaim,
    isValidUser,
    claim,
    assignedRole,
  } = useEditClaimContext();
  const { t } = useTranslation();
  const [isSubmitInvalid, setIsSubmitInvalid] = useState<boolean>(false);

  const getComments = () => {
    return comments.map((comment) => {
      return {
        uuid: comment.uuid,
        comment: comment.comment,
        authorName: comment.author.name,
        authorGroup: comment.author.group,
        createdAt: comment.createdAt,
      };
    });
  };

  const showProductionSection = () => claimType === "UNPLANNED";

  const isStoffstrom =
    hasAbility(Abilities.STOFFSTROM) ||
    hasAbility(Abilities.STOFFSTROM_MANAGER) ||
    (hasAbility(Abilities.ADMIN) && assignedRole === Role.STOFFSTROM);
  const isProduction =
    hasAbility(Abilities.LABOR) ||
    (hasAbility(Abilities.ADMIN) && assignedRole === Role.LABOR);
  // Ensure disabling only for users with ONLY partnerservice permissions
  const isPartnerService =
    hasAbility(Abilities.PARTNER_SERVICE) &&
    !hasAbility(Abilities.ADMIN) &&
    !hasAbility(Abilities.STOFFSTROM) &&
    !hasAbility(Abilities.STOFFSTROM_MANAGER);

  const handleUpdateClick = () => {
    onSubmitUpdateClaim();
  };

  useEffect(() => {
    setIsSubmitInvalid(
      disabled ||
        !isValidUser() ||
        isPartnerService ||
        !__.isNil(claim?.closedAt)
    );
  }, [setIsSubmitInvalid, disabled, isValidUser, isPartnerService, claim]);

  const isInvalidSubmit = useCallback(
    (role: Role[]) => {
      return (
        disabled ||
        (!isValidUser() &&
          !(
            hasAbility(Abilities.ADMIN) &&
            assignedRole !== undefined &&
            role.includes(assignedRole)
          )) ||
        isPartnerService ||
        !__.isNil(claim?.closedAt)
      );
    },
    [disabled, isValidUser, isPartnerService, claim, assignedRole]
  );

  return (
    <div>
      <Grid layout="max-width">
        <GridColumn medium={12}>
          <OrderInformationSection
            onChange={onUpdateOrderSection}
            section={orderSection}
          />
        </GridColumn>
        <GridColumn medium={6}>
          <ClaimTypeField
            value={claimType}
            onChange={onUpdateClaimType}
            disabled={!isStoffstrom || isInvalidSubmit([Role.STOFFSTROM])}
          />
          <MaterialFormSection
            sections={materialSection}
            onChange={onUpdateMaterialSection}
            disabled={!isStoffstrom || isInvalidSubmit([Role.STOFFSTROM])}
          />
          {showProductionSection() && (
            <ProductionFormSection
              section={productionSection}
              onChange={onUpdateProductionSection}
              plants={plants}
              disabled={
                (!isStoffstrom && !isProduction) ||
                isInvalidSubmit([Role.STOFFSTROM, Role.LABOR])
              }
            />
          )}
        </GridColumn>

        <GridColumn medium={6}>
          <PhysicalQualityFormSection
            section={physicalQualitySection}
            onChange={onUpdatePhysicalQualitySection}
            disabled={!isStoffstrom || isInvalidSubmit([Role.STOFFSTROM])}
          />
          <ChemicalQualityFormSection
            section={chemicalQualitySection}
            onChange={onUpdateChemicalQualitySection}
            disabled={!isStoffstrom || isInvalidSubmit([Role.STOFFSTROM])}
          />
          <LogisticsFormSection
            section={logisticQualitySection}
            onChange={onUpdateLogisticQualitySection}
            disabled={!isStoffstrom || isInvalidSubmit([Role.STOFFSTROM])}
          />
        </GridColumn>
        <GridColumn medium={12}>
          <FileUploadFormSection
            files={files}
            onChange={onUpdateFiles}
            existingFiles={existingFiles}
            disabled={
              (!isStoffstrom && !isProduction) ||
              isInvalidSubmit([Role.STOFFSTROM, Role.LABOR])
            }
          />
        </GridColumn>
        <GridColumn medium={12}>
          <PartnerServiceFormSection
            section={partnerServiceSection}
            onChange={onUpdatePartnerServiceSection}
            disabled={!isStoffstrom || isInvalidSubmit([Role.STOFFSTROM])}
          />
        </GridColumn>
        <GridColumn medium={12}>
          <CommentFormSection
            comments={getComments()}
            onAddComment={addComment}
            loading={commentsLoading}
            disabled={!__.isNil(claim?.closedAt)}
          />
        </GridColumn>
        <GridColumn medium={12}>
          <CardFrame text={"Verlauf"}>
            <CardFrameContent>
              <ClaimHistory claim={claim} />
            </CardFrameContent>
          </CardFrame>
        </GridColumn>

        <GridColumn medium={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "16px",
              marginBottom: "32px",
            }}
          >
            <Button
              appearance="primary"
              type="submit"
              isDisabled={isInvalidSubmit([Role.LABOR, Role.STOFFSTROM])}
              onClick={handleUpdateClick}
              isLoading={submitLoading}
            >
              {t("edit_claim.form.submit_button")}
            </Button>
          </div>
        </GridColumn>
      </Grid>
    </div>
  );
};
