import React, { FC } from "react";
import "url-search-params-polyfill";
import { Tickets_tickets } from "../../../../api/graphql/queries/types/Tickets";
import Ellipsis from "../../../partials/Ellipsis";
import {
  LaneItem,
  LaneItemBody,
  LaneItemHeader,
} from "../../../partials/lane/LaneItem";

interface ITicketLaneItemProps {
  ticket: Tickets_tickets;
}

export const TicketLaneItem: FC<ITicketLaneItemProps> = (props) => {
  const { ticket } = props;

  return (
    <LaneItem route={`/tickets/${ticket.id}`}>
      <LaneItemHeader title={`Ticket #${ticket.number}`}>
        {ticket.assignedUser && (
          <div
            style={{
              position: "absolute",
              right: "10px",
              color: "#5E6C84",
              fontSize: "0.8em",
            }}
          >
            {ticket.assignedUser.name}
          </div>
        )}
      </LaneItemHeader>

      <LaneItemBody>
        <Ellipsis>
          {ticket.general.partner ? (
            <span>
              <b>{ticket.general.partner.code}</b> {ticket.general.partner.name}
            </span>
          ) : ticket.general.partnerName ? (
            <span>
              <em>{ticket.general.partnerName}</em>
            </span>
          ) : (
            "-"
          )}
        </Ellipsis>

        <div style={{ position: "relative" }}>
          <Ellipsis style={{ maxWidth: "80%" }}>
            <strong>
              {ticket.general.oenorm ? ticket.general.oenorm.code : "-"}
            </strong>{" "}
            {ticket.general.oenorm && (
              <span>{ticket.general.oenorm.spezifikation}</span>
            )}
            &nbsp;
            {ticket.general.oenorm ? ticket.general.oenorm.title : "-"}
          </Ellipsis>
        </div>

        <Ellipsis>
          <strong>
            {ticket.general.material ? ticket.general.material.matnr : ""}{" "}
          </strong>
          {ticket.general.material ? ticket.general.material.title : "-"}
        </Ellipsis>
      </LaneItemBody>
    </LaneItem>
  );
};
