import { gql } from "apollo-boost"
import { Query } from "react-apollo"
import { Version } from "./types/Version"

export default gql`
  query Version {
    version {
      tag
      commit
      ahead
      env
    }
  }
`

export class VersionQuery extends Query<Version> {
}
