import * as React from "react"
import {IBaseProps} from "../../../../utils/BaseProps"
import {withTranslation} from "react-i18next";
import BasicStructure from "./basic-structure";
import {IInfoHandler, WasteInputType} from "../CreateTicketForm";
import {IDescriptionImageInfoHandler} from "./description-image";
import renderAdditional from "../common/additional"
import renderCompanyAndProcess from "../common/company-process"

interface ISiedlungsAbfaelleProps extends IBaseProps {
  infoHandler: IInfoHandler
}

interface ISiedlungsAbfaelleState {
  process: string
  comment: string
}

class SiedlungsAbfaelle extends React.Component<ISiedlungsAbfaelleProps, ISiedlungsAbfaelleState> {

  private descriptionImageInfoHandler: IDescriptionImageInfoHandler = {}

  constructor(props: ISiedlungsAbfaelleProps) {
    super(props);

    this.props.infoHandler.getInfo = this.getInfo

    this.state = {
      process: '',
      comment: '',
    }
  }

  componentWillReceiveProps(nextProps: Readonly<ISiedlungsAbfaelleProps>, nextContext: any): void {
    nextProps.infoHandler.getInfo = this.getInfo
  }

  private getInfo = () => {
    const descriptionImageInfo = this.descriptionImageInfoHandler.getInfo && this.descriptionImageInfoHandler.getInfo()

    if (
      !descriptionImageInfo
      || descriptionImageInfo.wasteDescription.isBlank()
      || this.state.process.isBlank()
    ) {
      console.log("getInfo validation failed", descriptionImageInfo)
      return null
    }


    return {
      inputType: WasteInputType.SIEDLUNG,
      description: descriptionImageInfo ? descriptionImageInfo.wasteDescription : '',
      photoAttachmentId: descriptionImageInfo && descriptionImageInfo.photoAttachmentId ? descriptionImageInfo.photoAttachmentId : "",

      process: this.state.process,
      comment: this.state.comment,
    }
  }

  private onProcessChanged = (e: any) => this.setState({process: e.target.value})
  private onCommentChanged = (e: any) => this.setState({comment: e.target.value})

  public render() {
    return <BasicStructure
      infoHandler={this.descriptionImageInfoHandler}
      companyAndProcess={renderCompanyAndProcess(
        this.props.t,
        null, null,
        this.onProcessChanged, this.state.process,
        null, null
      )}
      additional={renderAdditional(this.props.t, this.onCommentChanged, this.state.comment)}
      imageRequired={false}
      descriptionRequired={true}
    />
  }
}

export default withTranslation()(SiedlungsAbfaelle)
