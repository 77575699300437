import React, { useContext, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";
import PeopleGroupIcon from "@atlaskit/icon/glyph/people-group";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import { Role } from "../../api/graphql/graphql-global-types";
import { claim_claim } from "../../api/graphql/queries/types/claim";
import { ArchiveClaimDialog } from "./claim/ArchiveClaimDialog";
import __ from "lodash";
import { AuthContext } from "../../context/AuthContext";
import { Abilities } from "../../domain/models/abilities";

interface AssignRoleDropdownProps {
  title: string;
  excludedRoles?: Role[];
  onAssign: (role: Role) => void;
  isDisabled?: boolean;
  claim?: claim_claim;
}

export const AssignRoleDropdown: VFC<AssignRoleDropdownProps> = (props) => {
  const {title, onAssign, excludedRoles, isDisabled, claim} = props;
  const {t} = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { hasAbility } = useContext(AuthContext);

  const shouldHide = (role: Role): boolean =>
    !!excludedRoles && excludedRoles.includes(role);

  return (
    <>
      <DropdownMenu
        trigger={title}
        triggerType="button"
        triggerButtonProps={{
          isDisabled: isDisabled,
          iconBefore: <PeopleGroupIcon label="Gruppe"/>,
          iconAfter: isDisabled ? undefined : (
            <ChevronDownIcon label="Zuweisen"/>
          ),
        }}
        position="bottom right"
      >
        <DropdownItemGroup>
          {!shouldHide(Role.VERTRIEB) && (
            <DropdownItem onClick={() => onAssign(Role.VERTRIEB)}>
              {t("tickets.detail.assignRole.to", {
                role: t("assignee.vertrieb.title"),
              }) }
            </DropdownItem>
          )}

          {!shouldHide(Role.STOFFSTROM) && (
            <DropdownItem onClick={() => onAssign(Role.STOFFSTROM)}>
              {t("tickets.detail.assignRole.to", {
                role: t("assignee.stoffstrom.title"),
              })}
            </DropdownItem>
          )}

          {!shouldHide(Role.LABOR) && (
            <DropdownItem onClick={() => onAssign(Role.LABOR)}>
              {t("tickets.detail.assignRole.to", {
                role: claim
                  ? t("assignee.production.title")
                  : t("assignee.labor.title"),
              })}
            </DropdownItem>
          )}

          {!shouldHide(Role.PARTNER_SERVICE) && (
            <DropdownItem onClick={() => onAssign(Role.PARTNER_SERVICE)}>
              {t("tickets.detail.assignRole.to", {
                role: t("assignee.partner_service.title"),
              })}
            </DropdownItem>
          )}

          {claim && __.isNil(claim.closedAt) && hasAbility(Abilities.PARTNER_SERVICE) && !hasAbility(Abilities.ADMIN) && (
            <DropdownItem onClick={ () => setShowModal(true) }>
              { t("claims.detail.archive") }
            </DropdownItem>
          )}
        </DropdownItemGroup>
      </DropdownMenu>

      <ArchiveClaimDialog
        isOpen={showModal}
        claimId={claim ? claim.id : ""}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};
