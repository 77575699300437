import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "@atlaskit/page-header";
import { ClaimLane } from "./partials/ClaimLane";
import { Board } from "../../styled/Board";
import { useClaimsQuery } from "../../../api/graphql/queries/claims";
import Spinner from "@atlaskit/spinner";
import { Role } from "../../../api/graphql/graphql-global-types";

interface IClaimListPageProps {}

export const ClaimListPage: FC<IClaimListPageProps> = () => {
  const { t } = useTranslation();

  const { data: claimsResult, loading, error } = useClaimsQuery();

  const claims = claimsResult?.claims;
  const getFilteredClaims = (role: Role) => {
    return claims?.filter((claim) => claim.assignedRole === role) ?? [];
  };
  return (
    <div className="page-layout--wrapper">
      <PageHeader>{t("claims.title")}</PageHeader>

      <Board>
        {loading && <Spinner size="large" />}

        {error && (
          <div style={{ flex: 1, justifyContent: "center", display: "flex" }}>
            <h2>{t("components.claim_list_page.error")}</h2>
          </div>
        )}

        {!loading && !error && claims && (
          <>
            <ClaimLane
              claims={getFilteredClaims(Role.STOFFSTROM)}
              title={t("role.STOFFSTROM")}
            />

            <ClaimLane
              claims={getFilteredClaims(Role.LABOR)}
              title={t("role.LABOR")}
            />

            <ClaimLane
              claims={getFilteredClaims(Role.PARTNER_SERVICE)}
              title={t("role.PARTNER_SERVICE")}
            />
          </>
        )}
      </Board>
    </div>
  );
};
