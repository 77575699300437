import Button from "@atlaskit/button";
import AddIcon from "@atlaskit/icon/glyph/add";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Order } from "../../../../../../api/graphql/queries/orderSearch";

interface ICreateClaimButtonProps {
  order: Order;
}

export const CreateClaimButton: React.VFC<ICreateClaimButtonProps> = (
  props
) => {
  const { order } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const onClickCreateClaim = () => {
    history.push({
      pathname: `/claims/create/${order.id}`,
      state: {
        order: order,
      },
    });
  };

  return (
    <span>
      <Button
        appearance={"subtle"}
        iconBefore={<AddIcon label="Reklamation erstellen" size="small" />}
        onClick={onClickCreateClaim}
      >
        {t("create_claim.table.create_claim")}
      </Button>
    </span>
  );
};
