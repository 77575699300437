import * as React from "react"
import __ from "lodash"
import { withTranslation } from "react-i18next"
import { IBaseProps } from "../../../../utils/BaseProps"
import { Ticket_ticket as TicketType } from "../../../../api/graphql/queries/types/Ticket"
import DisposalWaste from "./specifics/DisposalWaste"
import PlasticWaste from "./specifics/PlasticWaste"
import MetalWaste from "./specifics/MetalWaste"
import PaperWaste from "./specifics/PaperWaste"
import UtilWaste from "./specifics/UtilWaste"
import HazardousWaste from "./specifics/HazardousWaste"
import HouseWaste from "./specifics/HouseWaste"
import BiogenWaste from "./specifics/BiogenWaste"
import EagWaste from "./specifics/EagWaste"
import BatteryWaste from "./specifics/BatteryWaste"
import { GridColumn } from "@atlaskit/page"

interface ITicketSpecificsProps extends IBaseProps {
  ticket: TicketType
}

interface ITicketSpecificsState {

}

class TicketSpecifics extends React.Component<ITicketSpecificsProps, ITicketSpecificsState> {
  state: ITicketSpecificsState

  constructor(props: ITicketSpecificsProps) {
    super(props)

    this.state = {
    }
  }

  public render() {
    const { ticket } = this.props
    return (
      <GridColumn medium={12}>
        {ticket.disposalWaste && <DisposalWaste specifics={ticket.disposalWaste} />}
        {ticket.plasticWaste && <PlasticWaste specifics={ticket.plasticWaste} />}
        {ticket.metalWaste && <MetalWaste specifics={ticket.metalWaste} />}
        {ticket.paperWaste && <PaperWaste specifics={ticket.paperWaste} />}
        {ticket.utilWaste && <UtilWaste specifics={ticket.utilWaste} />}
        {ticket.hazardousWaste && <HazardousWaste specifics={ticket.hazardousWaste} />}
        {ticket.houseWaste && <HouseWaste specifics={ticket.houseWaste} />}
        {ticket.biogenWaste && <BiogenWaste specifics={ticket.biogenWaste} />}
        {ticket.eagWaste && <EagWaste specifics={ticket.eagWaste} />}
        {ticket.batteryWaste && <BatteryWaste specifics={ticket.batteryWaste} />}
      </GridColumn>
    )
  }
}

export default withTranslation()(TicketSpecifics)
