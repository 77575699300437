/**
 * Remove '__typename' fields from the result of a query.
 * See <https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-1148056315>
 */
export const stripTypenames = <T>(obj: T): T => {
  const cleanPayload = JSON.parse(
    JSON.stringify(obj, (name, val) => {
      if (name === "__typename") {
        delete val[name];
      } else {
        return val;
      }
    })
  );
  return cleanPayload;
};
