import { gql } from "apollo-boost"
import { Query } from 'react-apollo';
import { ticketHistory } from './types/ticketHistory';

export default gql`
query ticketHistory($ticketId:ID!){
  ticketHistory(ticketId:$ticketId){
    user{
      name
      email
    }
    oldValue
    newValue
    entryType
    createdAt
  }
}
`

export class TicketHistoryQuery extends Query<ticketHistory> {}