import * as React from "react"
import { withTranslation } from "react-i18next"
import { IBaseProps } from "../../../../utils/BaseProps"
import TextField from "@atlaskit/textfield"
import { Field, HelperMessage } from "@atlaskit/form"

interface IEditNumberProps extends IBaseProps {
  name: string
  translation: String
  allowZero?: boolean
  required?: boolean
  value?: number | undefined
  onChange?: (name: string, value: number | undefined) => void
  disabled?: boolean
}

const toFloat = (value: any): (number | undefined) => {
  if (!value) return undefined
  return parseFloat(`${value}`.replace(",", "."))
}

const toValue = (value: any, allowZero: boolean = false): (string | undefined) => {
  if (!value && (allowZero ? value !== 0 : true)) return undefined
  return `${value}`.replace(".", ",")
}

export default withTranslation()((props: IEditNumberProps) => {
  const { t, name, translation, allowZero } = props
  const helper = t(`${translation}.helper`)
  return (
    <Field label={t(`${translation}.title`)} name={name} isRequired={!!props.required} isDisabled={!!props.disabled} >
      {({ fieldProps }: { fieldProps: any }) => (
        <React.Fragment>
          <TextField {...fieldProps} defaultValue={toValue(props.value, allowZero)} onBlur={(event: any) => props.onChange && (`${event.target.value}` !== `${props.value || ""}` || event.target.value === "") && props.onChange(name, toFloat(event.target.value)) } />
          {helper && <HelperMessage>{helper}</HelperMessage>}  
        </React.Fragment>
      )}
    </Field>
  )
})