import { gql } from "apollo-boost";
import { Mutation } from 'react-apollo';
import { createNoticeInWom, createNoticeInWomVariables } from './types/createNoticeInWom';
//import { Mutation } from "react-apollo";

export default gql` 
  mutation createNoticeInWom($ticketId: ID!) {
    createNoticeInWom(ticketId: $ticketId) {
      id
      womNoticeId
    }
  }
`

export class CreateNoticeInWomMutation extends Mutation<createNoticeInWom, createNoticeInWomVariables> {}
