import { gql } from "apollo-boost";
import { Mutation } from "react-apollo";
import { createClaim, createClaimVariables } from "./types/createClaim";

export const CREATE_CLAIM_MUTATION = gql`
  mutation createClaim($claim: CreateClaimInput!) {
    createClaim(claim: $claim) {
      id
      orderId
      number
    }
  }
`;

export type CreateClaimResult = createClaim;
export type CreateClaimVariables = createClaimVariables;

export class CreateClaimMutation extends Mutation<
  createClaim,
  createClaimVariables
> {}
