import { MaterialFragment } from "../api/graphql/fragments/types/MaterialFragment";
import { OenormFragment } from "../api/graphql/fragments/types/OenormFragment";

export const getMaterialOptions = (
  materials: MaterialFragment[],
  oenorms: OenormFragment[]
) => {
  return materials.map((m: MaterialFragment) => {
    const oenorm = oenorms.find((o: OenormFragment) => o.id === m.oenormId);
    return {
      ...m,
      label: `${m.matnr} - ${m.title}`,
      value: m.id,
      subtitle: oenorm
        ? `${oenorm.code}${
            oenorm.spezifikation ? ` ${oenorm.spezifikation}` : ""
          } - ${oenorm.title}`
        : null,
    };
  });
};
