import * as React from "react";
import {FunctionComponent, useEffect} from "react";
import useReactRouter from 'use-react-router';
import {useMutation} from "react-apollo-hooks";
import {AssignToMe, AssignToMeVariables} from "../../../api/graphql/mutations/types/AssignToMe";
import {assignToMeMutation} from "../../../api/graphql/mutations/AssignToMe";
import PageLoadingIndicator from "../../partials/PageLoadingIndicator";
import {AssignToMeResult} from "../../../api/graphql/graphql-global-types";
import {toast} from "react-toastify";
import {Redirect} from "react-router";

interface ITicketAssignToMePageProps {

}

interface IMatchProps {
  id: string
}

export const TicketAssignToMePage: FunctionComponent = (props: ITicketAssignToMePageProps) => {

  const {match} = useReactRouter<IMatchProps>()
  const ticketId = match.params.id
  const [assignMutation, {data, hasError, loading}] = useMutation<AssignToMe, AssignToMeVariables>(assignToMeMutation)

  useEffect(() => {
    assignMutation({variables: {ticketId}})
  }, [])

  console.log(data)

  if (loading) {
    return  <PageLoadingIndicator title={"Ticket wird zugewiesen..."}/>
  }
  else if (hasError || (data && data.assignToMe === AssignToMeResult.ERROR)) {
    toast.error("Beim Zuweisen ist ein Fehler aufgetreten")
    return <Redirect to={`/tickets/${ticketId}`} />
  }
  else if (data && data.assignToMe === AssignToMeResult.ALREADY_ASSIGNED) {
    toast.warn("Dieses Ticket wurde bereits zugewiesen")
    return <Redirect to={`/tickets/${ticketId}`} />
  }
  else if (data && data.assignToMe === AssignToMeResult.SUCCESSFULLY_ASSIGNED) {
    toast.success("Ticket wurde erfolgreich zugewiesen")
    return <Redirect to={`/tickets/${ticketId}`} />
  }
  else if (data && data.assignToMe === AssignToMeResult.CANNOT_ASSIGN) {
    toast.warn("Sie haben nicht die Berechtigungen, um sich dieses Ticket zuzuweisen")
    return <Redirect to={`/tickets/${ticketId}`} />
  }

  return <></>
}
