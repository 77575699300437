import * as React from "react";
import __ from "lodash";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import { IBaseProps } from "../../../../utils/BaseProps";
import { Field, HelperMessage, ErrorMessage } from "@atlaskit/form";
import Select from "@atlaskit/select";
import { ToolTipLink } from "../../../partials/ToolTipLink";

interface IEditSelectProps extends IBaseProps {
  name: string;
  translation: String;
  options: Array<IEditSelectOption>;
  selected?: IEditSelectOption | null;
  onChange?: (name: string, selected: IEditSelectOption) => void;
  required?: Boolean;
  clearable?: Boolean;
  helper?: String;
  height?: number;
  showField?: Boolean;
  disabled?: boolean;
}

export interface IEditSelectOption {
  label: string;
  title?: string | undefined | null;
  value: any;
  subtitle?: string | React.ReactNode;
  additional?: string;
  description?: string;
  prefix?: string;
  suffix?: string;
}

const Subtitle = styled.span`
  display: block;
  font-size: 13px;
  color: gray;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 99%;
`;

const Description = styled.span`
  display: block;
  font-size: 11px;
  color: gray;
`;

export const formatOptionLabel = (
  opt: IEditSelectOption,
  formatSubtitle?: (subtitle: string | React.ReactNode) => string
) => (
  <div>
    <div>
      {opt.title ? opt.title : opt.label}
      <span style={{ fontWeight: 600, float: "right", paddingLeft: "6px" }}>
        {formatSubtitle && opt.subtitle
          ? formatSubtitle(opt.subtitle)
          : opt.subtitle
          ? opt.subtitle
          : null}
      </span>
    </div>
    {opt.additional && opt.additional !== "XXXXX" ? (
      <Subtitle>
        {opt.prefix ? `${opt.prefix} ` : null}
        {opt.additional}
        {opt.suffix ? opt.suffix : null}
      </Subtitle>
    ) : null}
    {opt.description && <Description>{opt.description}</Description>}
  </div>
);

export const transformOptionLabel = (opt: IEditSelectOption) => {
  return formatOptionLabel(opt);
};

export default withTranslation()((props: IEditSelectProps) => {
  const {
    t,
    name,
    translation,
    options,
    selected,
    helper: helperOverride,
    height: heightOverride,
  } = props;
  const helper = t(`${translation}.helper`);
  const height =
    heightOverride ||
    (options && options.length !== 0
      ? options.reduce((acc, option) => {
          return Math.max(
            acc,
            30 +
              (!!option.additional ? 17 : 0) +
              (!!option.description ? 13 : 0)
          );
        }, 30)
      : 48);

  const validate = (selected: any) => {
    if (props.required && !selected && !props.selected) {
      return t("form.select.required.error");
    }
    return undefined;
  };

  const renderSelect = (rest: any, error: any, id: any) => {
    return (
      <React.Fragment>
        <ToolTipLink material={selected} />
        <Select
          {...rest}
          onChange={(selected: IEditSelectOption) => {
            if (selected && rest.onChange) rest.onChange(selected);
            if (props.onChange) props.onChange(name, selected);
          }}
          inputId={id}
          validationState={error ? "error" : "none"}
          menuPortalTarget={document.body}
          isSearchable={true}
          options={options}
          formatOptionLabel={transformOptionLabel}
          placeholder={t(`${translation}.placeholder`)}
          styles={{
            control: (styles: any) => ({
              ...styles,
              height: `${height + 5}px`,
            }),
            valueContainer: (styles: any) => ({
              ...styles,
              height: `${height}px`,
            }),
            singleValue: (styles: any) => ({
              ...styles,
              minWidth: "calc(100% - 15px)",
            }),
            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
          }}
          value={selected}
          isClearable={!!props.clearable}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {helper && <HelperMessage>{helper}</HelperMessage>}
        {helperOverride && <HelperMessage>{helperOverride}</HelperMessage>}
      </React.Fragment>
    );
  };

  return props.showField === true || props.showField === undefined ? (
    <Field
      label={t(`${translation}.title`)}
      name={name}
      isRequired={!!props.required}
      isDisabled={!!props.disabled}
      validate={validate}
    >
      {({
        fieldProps: { id, ...rest },
        error,
      }: {
        fieldProps: any;
        error: any;
      }) => renderSelect(rest, error, id)}
    </Field>
  ) : (
    renderSelect({}, null, props.name)
  );
});
