import * as React from "react";
import { gql } from "apollo-boost";
import { IEditSelectOption } from "../../../components/pages/tickets/fields/EditSelect";
import { MaterialFragment } from "./types/MaterialFragment";
import { OenormFragment } from "./types/OenormFragment";

export default gql`
  fragment MaterialFragment on Material {
    id
    matnr
    title
    unit
    oenormId
    ctrlLevel
    prdha
  }
`;

export const MaterialFragmentWithPks = gql`
  fragment MaterialFragment on Material {
    id
    matnr
    title
    unit
    oenormId
    ctrlLevel
    prdha
    pks {
      ktext
      cost
      compensation
    }
  }
`;

export const toSelectOption = (
  materials: Array<MaterialFragment>,
  oenorms: Array<OenormFragment>
): Array<IEditSelectOption> =>
  materials.map((m) => {
    const oenorm = oenorms.find((o) => o.id === m.oenormId);
    return {
      value: m.id,
      label: `${m.matnr} ${m.title} ${(oenorm && oenorm.code) || ""} ${
        (oenorm && oenorm.title) || ""
      }`,

      title: m.matnr,
      additional: m.title || undefined,
      subtitle: oenorm ? (
        <span>
          <small>ÖNORM</small> {oenorm.code}{" "}
          {oenorm.spezifikation ? oenorm.spezifikation : ""}
        </span>
      ) : undefined,

      oenorm: oenorm,
    };
  });

export const toSelectOptionWithMaterial = (
  materials: Array<MaterialFragment>,
  oenorms: Array<OenormFragment>
): Array<IEditSelectOption> =>
  materials.map((m) => {
    const oenorm = oenorms.find((o) => o.id === m.oenormId);
    return {
      value: m.id,
      label: `${m.matnr} ${m.title} ${(oenorm && oenorm.code) || ""} ${
        (oenorm && oenorm.title) || ""
      }`,

      title: m.matnr,
      additional: m.title || undefined,
      subtitle: oenorm ? (
        <span>
          <small>ÖNORM</small> {oenorm.code}{" "}
          {oenorm.spezifikation ? oenorm.spezifikation : ""}
        </span>
      ) : undefined,

      oenorm: oenorm,
      material: m,
    };
  });
