import * as React from "react"
import { withTranslation } from "react-i18next"
import { Field } from "@atlaskit/form"
import { IBaseProps } from "../../../../utils/BaseProps"
import FieldValue from "../partials/FieldValue"

interface IShowTextProps extends IBaseProps {
  name: String
  translation: String
  value: React.ReactNode | String | null
}

export default withTranslation()((props: IShowTextProps) => (
  <Field name={props.name} label={props.t(`${props.translation}.title`)} helperText={props.t(`${props.translation}.helper`)}>
    {() => (
      <FieldValue>{props.value === true ? props.t("yes") : (props.value === false ? props.t("no") : "-")}</FieldValue>
    )}
  </Field>
))