import { GridColumn } from "@atlaskit/page";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import Button from "@atlaskit/button";
import { Field } from "@atlaskit/form";
import WatchIcon from "@atlaskit/icon/glyph/watch";
import Lozenge from "@atlaskit/lozenge";
import { Link } from "react-router-dom";
import Ellipsis from "../../../partials/Ellipsis";
import { ListItem } from "../../../partials/list/ListItem";
import FieldValue from "./FieldValue";
import { Claim } from "../../../../api/graphql/queries/claims";

interface IClaimListItemProps {
  claim: Claim;
}

export const ClaimListItem: FC<IClaimListItemProps> = (props) => {
  const { t } = useTranslation();

  const { claim } = props;

  const assignedUsername =
    claim.assignedUser && claim.assignedUser.name
      ? claim.assignedUser.name
      : "";

  return (
    <ListItem
      title={t("components.claim_list_item.title", {
        claimNumber: claim.number,
        username: assignedUsername,
      })}
      variant="red"
    >
      <GridColumn medium={2}>
        <Field
          name="claimType"
          label={t("components.claim_list_item.claim_type")}
        >
          {(props: any) => (
            <FieldValue>
              <Lozenge>{t(`claim.claim_type.${claim.claimType}`)}</Lozenge>
            </FieldValue>
          )}
        </Field>
      </GridColumn>

      <GridColumn medium={2}>
        <Field name="material" label={t("components.claim_list_item.material")}>
          {(props: any) => (
            <div>
              <FieldValue>
                <strong>{claim.orderSection.materialNumber || "-"}</strong>
              </FieldValue>
              <FieldValue>
                <Ellipsis>{claim.orderSection.materialTitle || "-"}</Ellipsis>
              </FieldValue>
            </div>
          )}
        </Field>
      </GridColumn>

      <GridColumn medium={2}>
        <Field name="plant" label={t("components.claim_list_item.plant")}>
          {(props: any) => (
            <div>
              <FieldValue>
                <strong>{claim.orderSection.targetPlant || "-"}</strong>
              </FieldValue>
            </div>
          )}
        </Field>
      </GridColumn>

      <GridColumn>
        <Field name="link" label={" "}>
          {(props: any) => (
            <FieldValue>
              <div style={{ justifyContent: "flex-end", display: "flex" }}>
                <Link to={`/claims/${claim.number}`}>
                  <Button>
                    <WatchIcon
                      size={"small"}
                      label={t("components.claim_list_item.details")}
                    />
                  </Button>
                </Link>
              </div>
            </FieldValue>
          )}
        </Field>
      </GridColumn>
    </ListItem>
  );
};
