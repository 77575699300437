import { gql } from "apollo-boost";
import { QueryHookOptions, useQuery } from "react-apollo-hooks";
import { Users, UsersVariables } from "./types/Users";

const query = gql`
  query Users($ability: String) {
    users(ability: $ability) {
      id
      email
      name
      active
    }
  }
`;

export const useUsersQuery = (options?: QueryHookOptions<UsersVariables>) =>
  useQuery<Users>(query, options);
