// @ts-ignore
interface String {
  isNotBlank(): boolean;
  isBlank(): boolean;
}

String.prototype.isNotBlank = function (this : string) {
  return this.trim().length > 0
}

String.prototype.isBlank = function (this : string) {
  return this.trim().length === 0
}
