import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import { materials, materials_materials } from "./types/materials";

export const MATERIALS_QUERY = gql`
  query materials {
    materials {
      id
      matnr
      title
      unit
      oenormId
      ctrlLevel
      prdha
    }
  }
`;

export type Material = materials_materials;
export class MaterialsQuery extends Query<materials> {}
