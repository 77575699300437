import React from "react"
import styled from "styled-components"
import StarIcon from "@atlaskit/icon/glyph/star"
import Tooltip from "@atlaskit/tooltip"

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 0;
`

export const CardInfo = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(23, 43, 77);
  font-size: 13px;
  line-height: 1.33333;
  overflow: hidden;
  flex-grow: 2;
  display: flex;
  flex-direction: ${(props: any) => props.horizontal ? "row" : "column"};
  justify-content: ${(props: any) => props.horizontal ? "flex-start" : "space-around"};
  margin-top: ${(props: any) => props.marginTop ? "5px" : "0"};

  > div${(props: any) => props.horizontal ? "" : ".invalid"}:first-child {
    flex-basis: 26px;
    overflow: hidden;
    padding-right: 10px;
    flex-shrink: 0;

    svg${(props: any) => props.rotatedIcon ? "" : ".invalid"} {
      transform: rotate(90deg);
    }
  }
`

export const ID = styled.div`
  color: #D55346;
  font-size: 0.8em;

  span {
    font-size: 0.8em;
  }
`

export const Label = styled.div`
  font-size: 12px;
  color: rgb(94, 108, 132);
`

export const Board = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: stretch;
  height: 86%;
  height: calc(100% - 105px);
  overflow: auto;
`

export const StyledLane = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 4px;
  background: #F4F5F7;
  margin-right: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Sublane = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  width: 250px;
  // background-color: red;
  overflow: hidden;
  align-items: stretch;
  justify-items: stretch;
  display: flex;
  flex-direction: column;

  & + & {
    // background-color: green;
    border-left: 1px solid white;
  }
`

export const LaneHeader = styled.div`
  display: flex;
  padding: 10px 10px 5px 10px;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
`

const LaneHeaderIconWrapper = styled.div`
  padding-top: 5px;
  margin-right: 8px;
`

interface ILaneHeaderIcon {
  title: String
}

export const LaneHeaderIcon: React.FunctionComponent<ILaneHeaderIcon> = (props) => {
  return (
    <LaneHeaderIconWrapper>
      <Tooltip
        content={props.title}
        position="top"
      >
        <StarIcon
          label=""
          primaryColor="#D55346" />
      </Tooltip>
    </LaneHeaderIconWrapper>
  )
}

export const LaneHeaderContent = styled.div`
  flex-direction: column;
  flex-grow: 0;
`

export const LaneFooter = styled.div`
  flex-shrink: 0;
  padding: 5px 12px;
`

export const LaneTitle = styled.div`
  color: ${(props: any) => props.isHoliday ? "#D55346" : "rgb(9, 30, 66)"};
  font-size: 14px;
  font-weight: 500;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const LaneSubtitle = styled.div`
  color: ${(props: any) => props.isHoliday ? "#D55346" : "#5E6C84"};
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;
  line-height: 1.33333333;
  font-size: 0.85714286em;
`

export const LaneInfo = styled.div`
  font-size: 11px;
  color: rgb(107, 119, 140);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;
`

export const LaneStrip = styled.div`
  margin: 0 5px 0px 5px;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
  overflow: hidden;
  display: flex;

  &:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 5px;
    z-index: 1;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f4f5f7+0,f4f5f7+100&1+0,0+100 */
    background: -moz-linear-gradient(top, rgba(244,245,247,1) 0%, rgba(244,245,247,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(244,245,247,1) 0%,rgba(244,245,247,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(244,245,247,1) 0%,rgba(244,245,247,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f5f7', endColorstr='#00f4f5f7',GradientType=0 ); /* IE6-9 */
  }

  &:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 10px;
    z-index: 1;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f4f5f7+0,f4f5f7+100&0+0,1+100 */
    background: -moz-linear-gradient(top, rgba(244,245,247,0) 0%, rgba(244,245,247,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(244,245,247,0) 0%,rgba(244,245,247,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(244,245,247,0) 0%,rgba(244,245,247,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f4f5f7', endColorstr='#f4f5f7',GradientType=0 ); /* IE6-9 */
  }
`

export const LaneContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
`

export const Card = styled.div`
  background-color: white;
  padding:5px;
  border-radius: 2px;
  box-shadow: 0px 1px 2px 0px rgba(9, 30, 66, 0.25);
  font-size: 14px;
  position: relative;
  transition: background-color 140ms ease-in-out, border-color 75ms ease-in-out;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;
  cursor:pointer;

  &::before {
    background-color: white;
    content: " ";
    position: absolute;
    left: -1px;
    top: -1px;
    bottom: -1px;
    border-radius: 2px 0px 0px 2px;
  }

  :hover {
    background-color: #e1e5ed;
  }

  ${CardHeader} {
    margin-bottom: 0px;
  }

  ${CardInfo} {
    padding-top: 2px;
  }
`

export const CardStatus = styled.div`
  display: block;
  position: absolute;
  right: 5px;
  top: 2px;
  display: flex;
`

export const HeaderWeek = styled.div`
  display: inline;
  font-weight: 700;
  margin-left: 0.3em;
`

export const HeaderInfo = styled.div`
  color: rgb(107, 119, 140);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  font-size: 14px;
`

export const NoticeListing = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  border-radius: 4px;
  background: #F4F5F7;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 20px;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  ::before {
    z-index: 100;
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f4f5f7+0,f4f5f7+100&1+8,0+100 */
    background: ${(props: any) => props.isDraggingOver ? "-moz-linear-gradient(left, rgba(218,221,226,1) 0%, rgba(218,221,226,1) 8%, rgba(218,221,226,0) 100%); /* FF3.6-15 */" : "-moz-linear-gradient(left, rgba(244,245,247,1) 0%, rgba(244,245,247,1) 8%, rgba(244,245,247,0) 100%); /* FF3.6-15 */"}
    background: ${(props: any) => props.isDraggingOver ? "-webkit-linear-gradient(left, rgba(218,221,226,1) 0%,rgba(218,221,226,1) 8%,rgba(218,221,226,0) 100%); /* Chrome10-25,Safari5.1-6 */" : "-webkit-linear-gradient(left, rgba(244,245,247,1) 0%,rgba(244,245,247,1) 8%,rgba(244,245,247,0) 100%); /* Chrome10-25,Safari5.1-6 */"}
    background: ${(props: any) => props.isDraggingOver ? "linear-gradient(to right, rgba(218,221,226,1) 0%,rgba(218,221,226,1) 8%,rgba(218,221,226,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */" : "linear-gradient(to right, rgba(244,245,247,1) 0%,rgba(244,245,247,1) 8%,rgba(244,245,247,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */"}
    filter: ${(props: any) => props.isDraggingOver ? "progid:DXImageTransform.Microsoft.gradient( startColorstr='#dadde2', endColorstr='#00dadde2',GradientType=1 ); /* IE6-9 */" : "progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f5f7', endColorstr='#00f4f5f7',GradientType=1 ); /* IE6-9 */"}
  }

  ::after {
    z-index: 100;
    content: ' ';
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
    height: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f4f5f7+0,f4f5f7+100&0+0,1+87 */
    background: ${(props: any) => props.isDraggingOver ? "-moz-linear-gradient(left, rgba(218,221,226,0) 0%, rgba(218,221,226,1) 87%, rgba(218,221,226,1) 100%); /* FF3.6-15 */" : "-moz-linear-gradient(left, rgba(244,245,247,0) 0%, rgba(244,245,247,1) 87%, rgba(244,245,247,1) 100%); /* FF3.6-15 */"}
    background: ${(props: any) => props.isDraggingOver ? "-webkit-linear-gradient(left, rgba(218,221,226,0) 0%,rgba(218,221,226,1) 87%,rgba(218,221,226,1) 100%); /* Chrome10-25,Safari5.1-6 */" : "-webkit-linear-gradient(left, rgba(244,245,247,0) 0%,rgba(244,245,247,1) 87%,rgba(244,245,247,1) 100%); /* Chrome10-25,Safari5.1-6 */"}
    background: ${(props: any) => props.isDraggingOver ? "linear-gradient(to right, rgba(218,221,226,0) 0%,rgba(218,221,226,1) 87%,rgba(218,221,226,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */" : "linear-gradient(to right, rgba(244,245,247,0) 0%,rgba(244,245,247,1) 87%,rgba(244,245,247,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */"}
    filter: ${(props: any) => props.isDraggingOver ? "progid:DXImageTransform.Microsoft.gradient( startColorstr='#00dadde2', endColorstr='#dadde2',GradientType=1 ); /* IE6-9 */" : "progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f5f7', endColorstr='#00f4f5f7',GradientType=1 ); /* IE6-9 */"}
  }
`

export const NoticeListingHeader = styled.div`
  color: rgb(9, 30, 66);
  font-size: 14px;
  font-weight: 500;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px;
`

export const NoticeStrip = styled.div`  
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  border-radius: 4px;
  position: relative;
  padding: 0 5px 5px 5px;
  display: flex;
  flex-direction: row;
`

export const NoticeEmpty = styled.div`
  margin-left: 5px;
  margin-bottom: 5px;
`

export const SmallCard = styled.div`
  border: 0;
  border-radius: 2px;
  box-shadow: 0px 1px 2px 0px rgba(9, 30, 66, 0.25);
  background: #fff;
  color: #333;
  cursor: move;
  font-size: 14px;
  position: relative;
  transition: background-color 140ms ease-in-out, border-color 75ms ease-in-out;
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 10px 5px ${(props: any) => props.isUrgent ? "15px" : "10px"};
  align-content: stretch;
  align-items: stretch;
  overflow: hidden;

  ${CardHeader} {
    padding-right: 10px;
    border-right: 1px solid rgb(244, 245, 247);
  }

  ${CardInfo} {
    padding-left: 10px;
  }

  &::before {
    content: " ";
    background-color: #D55346;
    position: absolute;
    left: 0;
    top: 0;
    width: ${(props: any) => props.isUrgent ? "5px" : "0px"};
    height: 100%;
  }
`

export const CardWrapper = styled.div`
  padding: 5px;
  position: relative;
`

export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${(props: any) => props.spaced ? "5px" : null};
`

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
`

export const Subtitle = styled.span`
  display: block;
  font-size: 13px;
  color: gray;
`
