import * as React from "react"
import { withTranslation } from "react-i18next"
import { Field } from "@atlaskit/form"
import { IEditSelectOption } from "../fields/EditSelect"
import { IBaseProps } from "../../../../utils/BaseProps"
import FieldValue from "../partials/FieldValue"
import { Ticket_ticket_utilizations_plant as PlantType } from "../../../../api/graphql/queries/types/Ticket"
import { transformOptionLabel } from "../fields/EditSelect"

export const formatDisposalPlantOption = (plant: PlantType): IEditSelectOption => ({
  title: plant.facility,
  additional: plant.name || undefined,
  label: `${plant.facility || ""} ${plant.description || ""} ${plant.street || ""} ${plant.number || ""} ${plant.postal || ""} ${plant.city || ""}`,
  value: plant.id,
  description: `${plant.street || ""} ${plant.number || ""}${(plant.street || plant.number) && (plant.postal || plant.city) ? "," : ""} ${plant.postal || ""} ${plant.city || ""}`
})

interface IShowPlantProps extends IBaseProps {
  name: String
  translation: String
  plant?: PlantType | null
}

export default withTranslation()((props: IShowPlantProps) => (
  <Field name={props.name} label={props.t(`${props.translation}.title`)} helperText={props.t(`${props.translation}.helper`)}>
    {() => (
      <FieldValue>
        {props.plant ? transformOptionLabel(formatDisposalPlantOption(props.plant)) : "-"}
      </FieldValue>
    )}
  </Field>
))