import * as React from "react";
import __ from "lodash";
import moment from "moment";
import { CardFrame } from "@atlaskit/media-ui";
import { withTranslation } from "react-i18next";
import { Grid, GridColumn } from "@atlaskit/page";
import { Field } from "@atlaskit/form";
import Lozenge from "@atlaskit/lozenge";
import PageHeader from "@atlaskit/page-header";
import { ProgressTracker } from "@atlaskit/progress-tracker";
import QuestionCircleIcon from "@atlaskit/icon/glyph/question-circle";
import RecentIcon from "@atlaskit/icon/glyph/recent";
import ErrorIcon from "@atlaskit/icon/glyph/error";
import Banner from "@atlaskit/banner";
import { IBaseProps } from "../../../../utils/BaseProps";
import { showDateAndTime } from "../../../../utils/datetime";
import { Ticket_ticket as TicketType } from "../../../../api/graphql/queries/types/Ticket";
import { uploadAttachment_uploadAttachment as Attachment } from "../../../../api/graphql/mutations/types/uploadAttachment";
import linkAttachmentMutuation, {
  LinkAttachmentMutation,
} from "../../../../api/graphql/mutations/LinkAttachmentMutation";
import TicketSpecifics from "./TicketSpecifics";
import TicketUtilizations from "./TicketUtilizations";
import CardFrameContent from "../../../partials/CardFrameContent";
import GridSpacer from "../partials/GridSpacer";
import FieldValue from "../partials/FieldValue";
import Highlight from "../partials/Highlight";
import Attachments from "../partials/Attachments";
import {
  Role,
  TicketStatus,
} from "../../../../api/graphql/graphql-global-types";
import { TicketCommentEditor } from "../partials/CommentEditor";
import { Center } from "../../../styled/CenterDiv";
import { TicketHistory } from "../partials/TicketHistory";
import { isTicketAssignedTo } from "../../../../utils/presentation/role";
import ShowText from "../fields/ShowText";
import { ToolTipLink } from "../../../partials/ToolTipLink";
import { downloadFile } from "../../../../utils/file-helper";
import { CommentView } from "../../../partials/CommentView";

interface ITicketProps extends IBaseProps {
  ticket: TicketType;
  actions: React.ReactNode;
  onTicketChanged: () => Promise<any>;
}

interface ITicketState {
  optimisticAttachments: Array<Attachment>;
  isLinkingAttachment: boolean;
}

export const ticketToProgress = (ticket: TicketType, t: Function) => {
  let phase = 1;
  if (ticket.utilizations && ticket.utilizations.length > 0) {
    phase = 3;
  }
  if ([TicketStatus.COMPLETED, TicketStatus.CANCELED].includes(ticket.status)) {
    phase = 4;
  }

  return [
    {
      id: 1,
      label: "Erfasst",
      percentageComplete: phase >= 1 ? 100 : 0,
      status: "visited",
    },

    {
      id: 2,
      label: "In Arbeit",
      percentageComplete: phase >= 2 ? 100 : 0,
      status: phase === 2 ? "current" : phase < 2 ? "current" : "visited",
    },

    {
      id: 3,
      label: "Dokumentiert",
      percentageComplete: phase === 3 ? 0 : phase < 3 ? 0 : 100,
      status: phase === 3 ? "current" : phase < 3 ? "unvisited" : "visited",
    },

    {
      id: 4,
      label: "Abgeschlossen",
      percentageComplete: phase >= 4 ? 0 : 0,
      status: phase === 4 ? "current" : "unvisited",
    },
  ];
};

class Ticket extends React.Component<ITicketProps, ITicketState> {
  state: ITicketState;

  constructor(props: ITicketProps) {
    super(props);

    this.state = {
      optimisticAttachments: [],
      isLinkingAttachment: false,
    };
  }

  generateAttachmentAddedHandler =
    (mutation: Function) => (attachment: Attachment) => {
      console.log("handleAttachmentAdded", attachment);
      this.setState({
        optimisticAttachments:
          this.state.optimisticAttachments.concat(attachment),
      });

      mutation({
        variables: {
          id: attachment.id,
          ticketId: this.props.ticket.id,
        },
      });
    };

  handleLinkAttachmentMutationComplete = () => {
    if (this.props.onTicketChanged) this.props.onTicketChanged();
  };

  handleLinkAttachmentMutationError = () => {
    console.log("handleLinkAttachmentMutationError");
    this.setState({
      optimisticAttachments: [],
    });
  };

  handleAttachmentOpen(url: string, title: string) {
    downloadFile({ url, title });
  }

  public render() {
    const { t, ticket, actions } = this.props;
    return (
      <React.Fragment>
        <React.Fragment>
          <GridColumn medium={12}>
            <PageHeader actions={actions}>
              <div>
                {t("tickets.detail.title")}{" "}
                <Highlight>T#{ticket.number}</Highlight>
              </div>
            </PageHeader>
          </GridColumn>

          <GridColumn medium={12}>
            <Center>
              <ProgressTracker items={ticketToProgress(ticket, t)} />
            </Center>
          </GridColumn>
          <GridColumn medium={12}>
            <GridSpacer />
          </GridColumn>
          <GridColumn medium={12}>
            <CardFrame text={t("tickets.detail.sections.ticket.title")}>
              <CardFrameContent>
                <Grid layout="max-width">
                  <GridColumn medium={1}>
                    <Field
                      name="number"
                      label={t("tickets.detail.sections.ticket.no.title")}
                      helperText={t("tickets.detail.sections.ticket.no.helper")}
                    >
                      {(props: any) => <FieldValue>{ticket.number}</FieldValue>}
                    </Field>
                  </GridColumn>
                  <GridColumn medium={2}>
                    <Field
                      name="status"
                      label={t("tickets.detail.sections.ticket.status.title")}
                      helperText={t(
                        "tickets.detail.sections.ticket.status.helper"
                      )}
                    >
                      {(props: any) => (
                        <FieldValue>
                          <Lozenge>
                            {t(
                              `tickets.detail.sections.ticket.status.mapping.${ticket.status.toLowerCase()}.title`
                            )}
                          </Lozenge>
                        </FieldValue>
                      )}
                    </Field>
                  </GridColumn>
                  <GridColumn medium={3}>
                    <Field
                      name="assigned_role"
                      label={t(
                        "tickets.detail.sections.ticket.assigned_role.title"
                      )}
                      helperText={t(
                        "tickets.detail.sections.ticket.assigned_role.helper"
                      )}
                    >
                      {(props: any) => (
                        <FieldValue>
                          {t(
                            `assignee.${`${
                              ticket.assignedRole || "UNASSIGNED"
                            }`.toLowerCase()}.title`
                          )}
                        </FieldValue>
                      )}
                    </Field>
                  </GridColumn>
                  <GridColumn medium={2}>
                    <Field
                      name="assigned_user"
                      label={t(
                        "tickets.detail.sections.ticket.assigned_user.title"
                      )}
                      helperText={t(
                        "tickets.detail.sections.ticket.assigned_user.helper"
                      )}
                    >
                      {(props: any) => (
                        <FieldValue>
                          {ticket.assignedUser
                            ? ticket.assignedUser.name || "-"
                            : "-"}
                        </FieldValue>
                      )}
                    </Field>
                  </GridColumn>
                  <GridColumn medium={2}>
                    <Field
                      name="created_by"
                      label={t(
                        "tickets.detail.sections.ticket.created_by.title"
                      )}
                      helperText={t(
                        "tickets.detail.sections.ticket.created_by.helper"
                      )}
                    >
                      {(props: any) => (
                        <FieldValue>
                          {__.get(ticket, "createdBy.name", "-")}
                        </FieldValue>
                      )}
                    </Field>
                  </GridColumn>
                  <GridColumn medium={2}>
                    <Field
                      name="created_at"
                      label={t(
                        "tickets.detail.sections.ticket.created_at.title"
                      )}
                      helperText={t(
                        "tickets.detail.sections.ticket.created_at.helper"
                      )}
                    >
                      {(props: any) => (
                        <FieldValue>
                          {showDateAndTime(ticket.createdAt)}
                        </FieldValue>
                      )}
                    </Field>
                  </GridColumn>
                </Grid>

                <Grid layout="max-width">
                  <GridColumn medium={3}>
                    <Field
                      name="division"
                      label={t("tickets.detail.sections.ticket.division.title")}
                      helperText={t(
                        "tickets.detail.sections.ticket.division.helper"
                      )}
                    >
                      {(props: any) => (
                        <FieldValue>{ticket.general.division.title}</FieldValue>
                      )}
                    </Field>
                  </GridColumn>
                  <GridColumn medium={5}>
                    {ticket.sourceId && (
                      <Field
                        name="sourceId"
                        label={t(
                          "tickets.detail.sections.ticket.sourceId.title"
                        )}
                        helperText={t(
                          "tickets.detail.sections.ticket.sourceId.helper"
                        )}
                      >
                        {(props: any) => (
                          <FieldValue>{ticket.sourceId}</FieldValue>
                        )}
                      </Field>
                    )}
                  </GridColumn>
                  <GridColumn medium={2}></GridColumn>
                  <GridColumn medium={2}>
                    <Field
                      name="modified_at"
                      label={t(
                        "tickets.detail.sections.ticket.modified_at.title"
                      )}
                      helperText={t(
                        "tickets.detail.sections.ticket.modified_at.helper"
                      )}
                    >
                      {(props: any) => (
                        <FieldValue>
                          {ticket.updatedAt &&
                            showDateAndTime(ticket.updatedAt)}
                        </FieldValue>
                      )}
                    </Field>
                  </GridColumn>
                </Grid>
              </CardFrameContent>
            </CardFrame>
          </GridColumn>
          <GridColumn medium={12}>
            <GridSpacer />
          </GridColumn>

          {__.get(ticket, "hasReceivedOrder") !== null && (
            <React.Fragment>
              <GridColumn medium={12}>
                <CardFrame text={t("tickets.detail.sections.completion.title")}>
                  <CardFrameContent>
                    <Grid layout="max-width">
                      <GridColumn
                        medium={!!__.get(ticket, "explanation") ? 3 : 12}
                      >
                        <Field
                          name="has_received_order"
                          label={t(
                            "tickets.detail.sections.completion.has_received_order.title"
                          )}
                          helperText={t(
                            "tickets.detail.sections.completion.has_received_order.helper"
                          )}
                        >
                          {(props: any) => (
                            <FieldValue>
                              {__.get(ticket, "hasReceivedOrder")
                                ? t(
                                    "tickets.detail.sections.completion.has_received_order.yes"
                                  )
                                : t(
                                    "tickets.detail.sections.completion.has_received_order.no"
                                  )}
                            </FieldValue>
                          )}
                        </Field>
                      </GridColumn>
                      {!!__.get(ticket, "explanation") && (
                        <GridColumn medium={9}>
                          <Field
                            name="explanation"
                            label={t(
                              "tickets.detail.sections.completion.explanation.title"
                            )}
                            helperText={t(
                              "tickets.detail.sections.completion.explanation.helper"
                            )}
                          >
                            {(props: any) => (
                              <FieldValue>
                                {__.get(ticket, "explanation")}
                              </FieldValue>
                            )}
                          </Field>
                        </GridColumn>
                      )}
                    </Grid>
                  </CardFrameContent>
                </CardFrame>
              </GridColumn>
              <GridColumn medium={12}>
                <GridSpacer />
              </GridColumn>
            </React.Fragment>
          )}

          {__.get(ticket, "requestMaterialAnalysis") &&
            !__.get(ticket, "statusMaterialProbeAnalysis") && (
              <GridColumn medium={12}>
                <Banner
                  icon={
                    <QuestionCircleIcon
                      label="Probe angefordert"
                      secondaryColor="inherit"
                    />
                  }
                  isOpen
                >
                  Für dieses Ticket ist eine Probe für die Material Analyse
                  erforderlich. Anfrage zum Einholen einer Probe wurde am{" "}
                  {moment(__.get(ticket, "requestMaterialAnalysis")).format(
                    "LL"
                  )}{" "}
                  gestellt.
                </Banner>
                <GridSpacer />
              </GridColumn>
            )}

          {isTicketAssignedTo(Role.LABOR, ticket) &&
            __.get(ticket, "statusMaterialProbeAnalysis") && (
              <GridColumn medium={12}>
                <Banner
                  icon={
                    __.get(ticket, "statusMaterialProbeAnalysis") ===
                    "IN_TRANSIT" ? (
                      <RecentIcon
                        label="Probe wird ans Labor gesendet"
                        secondaryColor="inherit"
                      />
                    ) : (
                      <ErrorIcon
                        label="Status der Probe"
                        secondaryColor="inherit"
                      />
                    )
                  }
                  appearance={
                    __.get(ticket, "statusMaterialProbeAnalysis") ===
                    "IN_TRANSIT"
                      ? "error"
                      : "announcement"
                  }
                  isOpen
                >
                  {__.get(ticket, "statusMaterialProbeAnalysis") ===
                    "AVAILABLE" &&
                    "Für dieses Ticket ist eine Probe für die Material Analyse verfügbar."}
                  {__.get(ticket, "statusMaterialProbeAnalysis") ===
                    "IN_TRANSIT" && "Probe wird ans Labor geschickt."}
                  {__.get(ticket, "statusMaterialProbeAnalysis") ===
                    "NOT_AVAILABLE" &&
                    "Für dieses Ticket ist keine Probe für die Material Analyse verfügbar."}
                </Banner>
                <GridSpacer />
              </GridColumn>
            )}

          <Center>
            <GridColumn medium={6}>
              <CardFrame text={t("tickets.detail.sections.material.title")}>
                <CardFrameContent>
                  <Grid layout="max-width">
                    <GridColumn medium={12}>
                      <Field
                        name="oenorm"
                        label={t(
                          "tickets.detail.sections.material.oenorm.title"
                        )}
                        helperText={t(
                          "tickets.detail.sections.material.oenorm.helper"
                        )}
                      >
                        {(props: any) => (
                          <FieldValue>
                            <Highlight>
                              {__.get(ticket, "general.oenorm.code")}
                            </Highlight>{" "}
                            {__.get(ticket, "general.oenorm.spezifikation") && (
                              <span>
                                {__.get(ticket, "general.oenorm.spezifikation")}
                              </span>
                            )}{" "}
                            - {__.get(ticket, "general.oenorm.title")}
                          </FieldValue>
                        )}
                      </Field>
                    </GridColumn>
                    <GridColumn medium={12}>
                      <Field
                        name="material"
                        label={t(
                          "tickets.detail.sections.material.material.title"
                        )}
                        helperText={t(
                          "tickets.detail.sections.material.material.helper"
                        )}
                      >
                        {(props: any) => (
                          <React.Fragment>
                            <ToolTipLink material={ticket.general.material} />
                            <FieldValue>
                              <Highlight>
                                {__.get(ticket, "general.material.matnr")}
                              </Highlight>{" "}
                              -{" "}
                              {__.get(
                                ticket,
                                "general.material.title",
                                <i>
                                  {t(
                                    "tickets.detail.sections.material.material.empty"
                                  )}
                                </i>
                              )}
                            </FieldValue>
                          </React.Fragment>
                        )}
                      </Field>
                    </GridColumn>
                    {/* {(ticket.utilizations || []).length > 0 && (
                      <GridColumn medium={12}>
                        <Field name="pks" label={t('tickets.detail.sections.material.pks.title')}
                              helperText={t('tickets.detail.sections.material.pks.helper')}>
                          {(props: any) => (
                            <FieldValue>
                              {__.get(ticket, "general.material.pks")
                                ? (<div>
                                    {__.get(ticket, "general.material.pks.ktext")}
                                    {__.get(ticket, "general.material.pks.cost") && (<>
                                      <br/><Highlight>{`${__.get(ticket, "general.material.pks.cost") || ""}`.replace(".", ",")} €</Highlight>
                                    </>)}
                                  </div>)
                                : (<div>-</div>)}
                              </FieldValue>
                          )}
                        </Field>
                      </GridColumn>
                    )} */}
                    <GridColumn medium={12}>
                      <Field
                        name="ssm"
                        label={t("tickets.detail.sections.material.ssm.title")}
                        helperText={t(
                          "tickets.detail.sections.material.ssm.helper"
                        )}
                      >
                        {(props: any) => (
                          <FieldValue>
                            {__.get(ticket, "general.division.title")}
                          </FieldValue>
                        )}
                      </Field>
                    </GridColumn>
                    <GridColumn medium={12}>
                      <Grid>
                        <GridColumn medium={4}>
                          <ShowText
                            name="quantity"
                            translation="tickets.create.fields.quantity"
                            value={ticket.general.quantity}
                          />
                        </GridColumn>
                        <GridColumn medium={4}>
                          <ShowText
                            name="unit"
                            translation="tickets.create.fields.unit"
                            value={t(`units.code.${ticket.general.unit}`)}
                          />
                        </GridColumn>
                        <GridColumn medium={4}>
                          <ShowText
                            name="frequency"
                            translation="tickets.create.fields.frequency"
                            value={t(
                              `waste_frequency.code.${ticket.general.frequency}`
                            )}
                          />
                        </GridColumn>
                      </Grid>
                    </GridColumn>
                    <GridColumn medium={12}>
                      <ShowText
                        name="pack"
                        translation="tickets.create.fields.pack"
                        value={ticket.general.pack}
                      />
                    </GridColumn>
                    <GridColumn medium={12}>
                      <ShowText
                        name="transport"
                        translation="tickets.create.fields.transport"
                        value={ticket.general.transport}
                        emptyIndicator
                      />
                    </GridColumn>
                  </Grid>
                </CardFrameContent>
              </CardFrame>
            </GridColumn>
            <GridColumn medium={6}>
              <CardFrame text={t("tickets.detail.sections.partner.title")}>
                <CardFrameContent>
                  <Grid layout="max-width">
                    <GridColumn medium={6}>
                      <Field
                        name="partner_name"
                        label={t("tickets.detail.sections.partner.name.title")}
                        helperText={t(
                          "tickets.detail.sections.partner.name.helper"
                        )}
                      >
                        {(props: any) => (
                          <FieldValue>
                            <Highlight>
                              {__.get(ticket, "general.partner.code")}
                            </Highlight>{" "}
                            {__.get(
                              ticket,
                              "general.partner.name",
                              __.get(ticket, "general.partnerName")
                            )}
                          </FieldValue>
                        )}
                      </Field>
                    </GridColumn>
                    {!__.get(ticket, "general.partner") &&
                      __.get(ticket, "general.partnerCustomer") && (
                        <GridColumn medium={6}>
                          <Field
                            name="partner_customer"
                            label={t(
                              "tickets.detail.sections.partner.customer.title"
                            )}
                            helperText={t(
                              "tickets.detail.sections.partner.customer.helper"
                            )}
                          >
                            {(props: any) => (
                              <FieldValue>
                                {__.get(ticket, "general.partnerCustomer")}
                              </FieldValue>
                            )}
                          </Field>
                        </GridColumn>
                      )}
                    <GridColumn medium={4}>
                      <Field
                        name="partner_street"
                        label={t(
                          "tickets.detail.sections.partner.street.title"
                        )}
                        helperText={t(
                          "tickets.detail.sections.partner.street.helper"
                        )}
                      >
                        {(props: any) => (
                          <FieldValue>
                            {__.get(
                              ticket,
                              "general.partner.street",
                              __.get(ticket, "general.partnerStreet")
                            )}
                          </FieldValue>
                        )}
                      </Field>
                    </GridColumn>
                    <GridColumn medium={2}>
                      <Field
                        name="partner_number"
                        label={t(
                          "tickets.detail.sections.partner.number.title"
                        )}
                        helperText={t(
                          "tickets.detail.sections.partner.number.helper"
                        )}
                      >
                        {(props: any) => (
                          <FieldValue>
                            {__.get(
                              ticket,
                              "general.partner.number",
                              __.get(ticket, "general.partnerNumber")
                            )}
                          </FieldValue>
                        )}
                      </Field>
                    </GridColumn>
                    <GridColumn medium={2}>
                      <Field
                        name="partner_postal"
                        label={t(
                          "tickets.detail.sections.partner.postal.title"
                        )}
                        helperText={t(
                          "tickets.detail.sections.partner.postal.helper"
                        )}
                      >
                        {(props: any) => (
                          <FieldValue>
                            {__.get(
                              ticket,
                              "general.partner.postal",
                              __.get(ticket, "general.partnerPostal")
                            )}
                          </FieldValue>
                        )}
                      </Field>
                    </GridColumn>
                    <GridColumn medium={4}>
                      <Field
                        name="partner_city"
                        label={t("tickets.detail.sections.partner.city.title")}
                        helperText={t(
                          "tickets.detail.sections.partner.city.helper"
                        )}
                      >
                        {(props: any) => (
                          <FieldValue>
                            {__.get(
                              ticket,
                              "general.partner.city",
                              __.get(ticket, "general.partnerCity")
                            )}
                          </FieldValue>
                        )}
                      </Field>
                    </GridColumn>
                    {!__.get(ticket, "general.partner") &&
                      __.get(ticket, "general.partnerContact") && (
                        <GridColumn medium={6}>
                          <Field
                            name="partner_contact"
                            label={t(
                              "tickets.detail.sections.partner.contact.title"
                            )}
                            helperText={t(
                              "tickets.detail.sections.partner.contact.helper"
                            )}
                          >
                            {(props: any) => (
                              <FieldValue>
                                {__.get(ticket, "general.partnerContact")}
                              </FieldValue>
                            )}
                          </Field>
                        </GridColumn>
                      )}
                    {!__.get(ticket, "general.partner") &&
                      __.get(ticket, "general.partnerLocation") && (
                        <GridColumn medium={6}>
                          <Field
                            name="partner_location"
                            label={t(
                              "tickets.detail.sections.partner.location.title"
                            )}
                            helperText={t(
                              "tickets.detail.sections.partner.location.helper"
                            )}
                          >
                            {(props: any) => (
                              <FieldValue>
                                {__.get(ticket, "general.partnerLocation")}
                              </FieldValue>
                            )}
                          </Field>
                        </GridColumn>
                      )}
                  </Grid>
                </CardFrameContent>
              </CardFrame>
            </GridColumn>
          </Center>
        </React.Fragment>

        <GridColumn medium={12}>
          <GridSpacer />
        </GridColumn>

        <TicketSpecifics ticket={ticket} />
        <TicketUtilizations utilizations={ticket.utilizations || []} />

        <GridColumn medium={12}>
          <GridSpacer />
        </GridColumn>

        <React.Fragment>
          <GridColumn medium={12}>
            <CardFrame text={t("tickets.detail.sections.attachments.title")}>
              <CardFrameContent>
                <LinkAttachmentMutation
                  mutation={linkAttachmentMutuation}
                  onCompleted={this.handleLinkAttachmentMutationComplete}
                  onError={this.handleLinkAttachmentMutationError}
                >
                  {(mutation) => {
                    return (
                      <Attachments
                        onAttachmentAdded={this.generateAttachmentAddedHandler(
                          mutation
                        )}
                        onAttachmentOpen={this.handleAttachmentOpen}
                        attachments={__.uniqBy(
                          [
                            ...(this.props.ticket.attachments || []),
                            ...this.state.optimisticAttachments,
                          ],
                          "id"
                        )}
                      />
                    );
                  }}
                </LinkAttachmentMutation>
              </CardFrameContent>
            </CardFrame>
          </GridColumn>

          <GridColumn medium={12}>
            <GridSpacer />
          </GridColumn>

          <React.Fragment>
            <GridColumn medium={12}>
              <CardFrame text={t("tickets.detail.sections.comments.title")}>
                <CardFrameContent>
                  {ticket.comments &&
                    ticket.comments.map(
                      ({ comment, author, createdAt, uuid }) => (
                        <CommentView
                          key={uuid}
                          comment={comment}
                          authorName={author.name}
                          authorGroup={author.group}
                          createdAt={createdAt}
                        />
                      )
                    )}
                  <TicketCommentEditor
                    ticketId={ticket.id}
                    onDidSave={this.props.onTicketChanged}
                    saveButtonText={this.props.t("to_comment")}
                    shouldCommentOnSubmit={true}
                  />
                </CardFrameContent>
              </CardFrame>
            </GridColumn>
            <GridColumn medium={12}>
              <GridSpacer />
            </GridColumn>
            <GridColumn>
              <CardFrame text={"Verlauf"}>
                <CardFrameContent>
                  <TicketHistory ticket={ticket} />
                </CardFrameContent>
              </CardFrame>
            </GridColumn>
          </React.Fragment>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Ticket);
