import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IBaseProps } from "../../../utils/BaseProps";
import { EditClaimFormPage } from "./partials/edit-claim/EditClaimFormPage";

interface IEditClaimPageProps extends IBaseProps {}

export const EditClaimPage: VFC<IEditClaimPageProps> = (props) => {
  const { t } = useTranslation();
  const { id } = useParams() as any;
  const claimNumber = Number.parseInt(id);

  if (isNaN(claimNumber)) {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          display: "flex",
          marginTop: "4em",
        }}
      >
        <h3>{t("edit_claim.not_found")}</h3>
      </div>
    );
  }

  return <EditClaimFormPage claimNumber={claimNumber} />;
};
