import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Claim } from "../../../../api/graphql/queries/claims";
import Ellipsis from "../../../partials/Ellipsis";
import {
  LaneItem,
  LaneItemBody,
  LaneItemHeader,
} from "../../../partials/lane/LaneItem";

interface IClaimLaneItemProps {
  claim: Claim;
}

export const ClaimLaneItem: FC<IClaimLaneItemProps> = (props) => {
  const { t } = useTranslation();
  const { claim } = props;

  return (
    <LaneItem route={`/claims/${claim.number}`}>
      <LaneItemHeader
        title={t("components.claim_lane_item.title", {
          claimNumber: claim.number,
        })}
      >
        <div
          style={{
            position: "absolute",
            right: "10px",
            color: "#5E6C84",
            fontSize: "0.8em",
          }}
        >
          {<strong>{t(`claim.claim_type.${claim.claimType}`)}</strong>}
        </div>
      </LaneItemHeader>
      <LaneItemBody>
        <Ellipsis>
          {
            <span>
              <b>{t("components.claim_lane_item.material")}</b>{" "}
              {claim.orderSection.materialTitle || "-"}
            </span>
          }
        </Ellipsis>

        <Ellipsis>
          {
            <span>
              <b>{t("components.claim_lane_item.plant")}</b>{" "}
              {claim.orderSection.targetPlant || "-"}
            </span>
          }
        </Ellipsis>
        <Ellipsis>
          {
            <span>
              <b>{t("components.claim_lane_item.object_nr")}</b>{" "}
              {claim.orderSection.orderNr || "-"}
            </span>
          }
        </Ellipsis>
      </LaneItemBody>
    </LaneItem>
  );
};
