import { Role } from "../../api/graphql/graphql-global-types";
import { Ticket_ticket as TicketType } from "../../api/graphql/queries/types/Ticket";
import { Abilities, Ability } from "../../domain/models/abilities";

export const titleForRole = (
  role: Role | null,
  t: any,
  isClaim?: boolean
): string => {
  if (role == null) return "";
  switch (role) {
    case Role.VERTRIEB:
      return t("assignee.vertrieb.title");
    case Role.STOFFSTROM:
      return t("assignee.stoffstrom.title");
    case Role.LABOR:
      return isClaim
        ? t("assignee.production.title")
        : t("assignee.labor.title");
    case Role.PARTNER_SERVICE:
      return t("assignee.partner_service.title");
  }
};

export const roleToAbility = (role: Role): Ability => {
  switch (role) {
    case Role.VERTRIEB:
      return Abilities.VERTRIEB;
    case Role.STOFFSTROM:
      return Abilities.STOFFSTROM;
    case Role.LABOR:
      return Abilities.LABOR;
    case Role.PARTNER_SERVICE:
      return Abilities.PARTNER_SERVICE;
  }
};

export const isTicketAssignedTo = (role: Role, ticket: TicketType): Boolean => {
  return ticket.assignedRole === role;
};
