import { Field } from "@atlaskit/form";
import React from "react";
import FieldValue from "../pages/tickets/partials/FieldValue";

interface ICustomTextDisplayProps {
  name: string;
  label: string;
  value: any;
  helperText: string;
}

export const CustomTextDisplay: React.VFC<ICustomTextDisplayProps> = (
  props
) => {
  const { name, label, value, helperText } = props;

  return (
    <Field name={name} label={label} helperText={helperText}>
      {(props: any) => <FieldValue>{value}</FieldValue>}
    </Field>
  );
};
