import React from "react";
import store from "store";
import jwt from "jsonwebtoken";
import * as lodash from "lodash";
import { client } from "../index";
import { Abilities } from "../domain/models/abilities";

interface IAuthContextState {
  token: string | undefined;
  abilities: string[];
  userId: string | undefined;
  isAuthenticated: () => boolean;
  authin: (token: string) => void;
  logout: () => void;
  hasAbility: (ability: string) => boolean;
}

const defaultValues: IAuthContextState = {
  token: undefined,
  userId: undefined,
  authin: () => {},
  logout: () => undefined,
  isAuthenticated: () => false,
  hasAbility: () => false,
  abilities: [],
};

export const AuthContext =
  React.createContext<IAuthContextState>(defaultValues);

interface AuthProviderProps {
  token: string;
}

class AuthProvider extends React.Component<
  AuthProviderProps,
  IAuthContextState
> {
  constructor(props: AuthProviderProps) {
    super(props);

    const payload = jwt.decode(props.token);

    // @ts-ignore
    this.state = {
      token: props.token,
      // @ts-ignore
      userId: lodash.get(payload, "uid", undefined),
      abilities: lodash.get(payload, "abilities", []),
      authin: this.authin,
      logout: this.logout,
    };
  }

  private handleTokenChange = (token: string) => {
    store.set("access_token", token);
    const payload = jwt.decode(token);

    this.setState({
      token: token,
      userId: lodash.get(payload, "uid", undefined),
      abilities: payload ? lodash.get(payload, "abilities", []) : [],
    });
  };

  private logout = () => {
    store.remove("access_token");
    client.cache.reset();

    this.setState({
      token: undefined,
      abilities: [],
    });
  };

  private authin = (token: string) => {
    this.handleTokenChange(token);
  };

  public isAuthenticated = () => {
    return (
      !!this.state.token &&
      this.state.token !== "" &&
      this.state.token !== "logout" &&
      !!this.state.userId &&
      this.hasAbility(Abilities.TICKET)
    );
  };

  private hasAbility = (ability: string): boolean => {
    if (this.state.abilities.indexOf(Abilities.ADMIN) >= 0) {
      return true;
    }

    return this.state.abilities.indexOf(ability) >= 0;
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          isAuthenticated: this.isAuthenticated,
          hasAbility: this.hasAbility,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer };
