import React, { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { GridColumn } from "@atlaskit/page";
import i18next from "i18next";
import { ClaimType } from "../../../api/graphql/graphql-global-types";
import EditSelect, {
  IEditSelectOption,
} from "../../pages/tickets/fields/EditSelect";
import { FormSection } from "../FormSection";

interface IClaimTypeFieldProps {
  value: ClaimType | undefined;
  onChange: (value: ClaimType) => void;
  disabled?: boolean;
}

export const ClaimTypeField: VFC<IClaimTypeFieldProps> = (props) => {
  const { value, onChange, disabled } = props;
  const { t } = useTranslation();

  const claimTypeOptions = getClaimTypeOptions(t);
  const [selected, setSelected] = useState(
    claimTypeOptions.find(
      (option: IClaimTypeOption) => option.value === value
    ) ?? null
  );

  const onChangeClaimTypeOptions = (
    _: string,
    selected: IEditSelectOption | null
  ) => {
    const selectedType = selected
      ? {
          label: selected?.label,
          value: selected?.value,
        }
      : null;

    setSelected(selectedType);
    onChange(selected?.value || null);
  };

  return (
    <FormSection title="">
      <GridColumn medium={12}>
        <EditSelect
          name="type"
          translation="create_claim.form.claim_type"
          options={claimTypeOptions}
          onChange={onChangeClaimTypeOptions}
          selected={selected}
          disabled={disabled}
          required={true}
          clearable
        />
      </GridColumn>
    </FormSection>
  );
};

interface IClaimTypeOption {
  label: string;
  value: ClaimType;
  subtitle?: string | null;
}

const getClaimTypeOptions = (t: i18next.TFunction): IClaimTypeOption[] => {
  return [
    {
      label: t("create_claim.form.type_options.PLANNED"),
      value: ClaimType.PLANNED,
    },
    {
      label: t("create_claim.form.type_options.UNPLANNED"),
      value: ClaimType.UNPLANNED,
    },
  ];
};
