import React, { Component } from "react"
import Dropzone from "react-dropzone"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { IBaseProps } from "../../../../utils/BaseProps"
import Spinner from "@atlaskit/spinner"
import PdfDocument48Icon from "@atlaskit/icon-file-type/glyph/pdf-document/48"
import Spreadsheet48Icon from "@atlaskit/icon-file-type/glyph/spreadsheet/48"
import Generic48Icon from "@atlaskit/icon-file-type/glyph/generic/48"
import UploadIcon from "@atlaskit/icon/glyph/upload"

import uploadAttachmentMutuation, { UploadAttachmentMutation } from "../../../../api/graphql/mutations/UploadAttachmentMutation"
import { uploadAttachment_uploadAttachment as Attachment } from "../../../../api/graphql/mutations/types/uploadAttachment"
import { Constants } from "../../../../utils/constants"

const AttachmentItem = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	flex-grow: 0;
	flex-shrink: 0;
	background-color: #F4F5F7;
	width: 150px;
	padding: 5px;
	border-radius: 3px;
	font-size: 0.7em;
	margin: 5px;

	&:hover {
		background-color: #F0F0F0;
		cursor: pointer;
	}

	&> img {
		border-radius: 3px;
	}

	&> span {
		display: block;
		margin-top: 5px;
	}
`

const AttachmentZone = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding-top: 10px;
`

interface IAttachmentsProps extends IBaseProps {
	attachments: Array<Attachment>
	onAttachmentAdded?: (attachment: Attachment) => void
	onAttachmentOpen?: (url: string, title: string) => void
}

interface IAttachmentsState {
	isUploading: boolean
}

class Attachments extends Component<IAttachmentsProps, IAttachmentsState> {
	constructor(props: IAttachmentsProps) {
		super(props)

		this.state = {
			isUploading: false
		}
	}

	onDrop = (acceptedFiles: any, rejectedFiles: any, uploadAttachmentMutation: any) => {
		if (!Array.isArray(acceptedFiles) || acceptedFiles.length === 0 || acceptedFiles[0] === null) {
			return
		}
		let reader = new FileReader()
		let file = acceptedFiles[0]
		reader.onloadend = () => {
			this.handleFileLoaded(file, reader.result, uploadAttachmentMutation)
		}
		reader.readAsDataURL(file)
	}

	handleFileLoaded = (file: any, fileBase64: any, uploadAttachmentMutation: any) => {
		this.setState({ isUploading: true })
		const variables = {
			title: file.name,
			base64: fileBase64
		}
		uploadAttachmentMutation({ variables })
	}

	handleMutationComplete = (response: any) => {
		if (this.props.onAttachmentAdded) this.props.onAttachmentAdded(response.uploadAttachment)
		this.setState({ isUploading: false })
	}

	handleMutationError = () => {
		console.error("handleMutationError")
		this.setState({ isUploading: false })
	}

	handleAttachmentOpen = (event: React.MouseEvent | undefined, url: any, title: any) => {
		if (event) {
			event.stopPropagation()
			event.preventDefault()
		}
		if (this.props.onAttachmentOpen) this.props.onAttachmentOpen(url, title)
	}

	render() {
		const { t } = this.props
		return (
			<UploadAttachmentMutation mutation={uploadAttachmentMutuation} onCompleted={this.handleMutationComplete} onError={this.handleMutationError}>
				{(mutation) => {
					return (
						<div>
							{
								<Dropzone
									multiple={false}
									accept=".jpg, .jpeg, .png, .pdf, .JPG, .JPEG, .PNG, .PDF, .docx, .DOCX, .doc, .DOC, .XLS, .xls, .XLSX, .xlsx"
									onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles, mutation)}
									maxSize={Constants.MAX_FILE_SIZE_UPLOAD}
								>
									{({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
										<AttachmentZone {...getRootProps()}>
											{this.props.attachments.map(attachment => {
												if (attachment.mimeType && attachment.mimeType.includes("image")) {
													return <AttachmentItem
														onClick={(event) => this.handleAttachmentOpen(event, attachment.url, attachment.title)}
														key={attachment.id}>
														<img src={attachment.url} alt={attachment.title} />
														<span>{attachment.title}</span>
													</AttachmentItem>
												}
												if (attachment.mimeType && attachment.mimeType.includes("pdf")) {
													return (
														<AttachmentItem
															onClick={(event) => this.handleAttachmentOpen(event, attachment.url, attachment.title)}
															key={attachment.id}>
															<PdfDocument48Icon label="PDF Anhang" />
															{attachment.title}
														</AttachmentItem>
													)
												}
												if (attachment.mimeType && (attachment.mimeType.includes("xxxls") || attachment.mimeType.includes("vnd.openxmlformats-officedocument.spreadsheetml.sheet"))) {
													return (
														<AttachmentItem
															onClick={(event) => this.handleAttachmentOpen(event, attachment.url, attachment.title)}
															key={attachment.id}>
															<Spreadsheet48Icon label="Excel Anhang" />
															{attachment.title}
														</AttachmentItem>
													)
												}
												return (
													<AttachmentItem
														onClick={(event) => this.handleAttachmentOpen(event, attachment.url, attachment.title)}
														key={attachment.id}>
														<Generic48Icon label="Datei" />
														{attachment.title}
													</AttachmentItem>
												)
											})}
											<AttachmentItem>
												{this.state.isUploading
													? <Spinner />
													: <UploadIcon label={"AttachmentsLabel"} size="xlarge" />
												}
												<input {...getInputProps()} />
												<span>
													{!isDragActive && t('tickets.detail.sections.attachments.dropzone')}
													{isDragActive && !isDragReject && t('tickets.detail.sections.attachments.drophere')}
													{isDragReject && t('tickets.detail.sections.attachments.reject')}
												</span>
											</AttachmentItem>
										</AttachmentZone>
									)}
								</Dropzone>
							}
						</div>
					)
				}}
			</UploadAttachmentMutation>
		);
	}
}

export default withTranslation()(Attachments)
