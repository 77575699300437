import React, { Fragment } from "react"
import __ from "lodash"
import "url-search-params-polyfill"
import { ticketHistory_ticketHistory } from '../../../../api/graphql/queries/types/ticketHistory';
import { HistoryEntryType } from '../../../../domain/models/history-entry-type';
import lodash from "lodash"
import moment from "moment"

interface ITicketHistoryEntryProps {
    historyEntry: ticketHistory_ticketHistory | null
}

const formatHistoryEntry = (historyEntry: ticketHistory_ticketHistory) => {
    switch (historyEntry.entryType) {
        case HistoryEntryType.ADD_COMMENT:
            return historyEntry.user.name + " hat kommentiert."
        case HistoryEntryType.ASSIGN_ROLE:
            return `${historyEntry.user.name} hat die Zuweisung von ${lodash.capitalize(historyEntry.oldValue || "")} auf ${lodash.capitalize(historyEntry.newValue || "")} geändert.`
        case HistoryEntryType.ASSIGN_USER:
            return `Das Ticket wurde ${historyEntry.newValue} zugewiesen.`
        case HistoryEntryType.CREATE_TICKET:
            return `Das Ticket wurde von ${historyEntry.user.name} erstellt.`
        case HistoryEntryType.CREATE_WOM_NOTICE:
            return `${historyEntry.user.name} hat aus dem Ticket eine Bedarfsmeldung erstellt.`
        case HistoryEntryType.LINK_ATTACHMENT:
            return `${historyEntry.user.name} hat dem Ticket ein Dokument angehängt.`
        case HistoryEntryType.STATUS_UPDATE:
            {
                if (historyEntry.newValue === "CANCELED") {
                    return `Das Ticket wurde von ${historyEntry.user.name} storniert`
                } else if (historyEntry.newValue === "COMPLETED") {
                    return `Das Ticket wurde von ${historyEntry.user.name} abgeschlossen`
                }
                return `Der Status des Tickets wurde von ${historyEntry.user.name} von ${lodash.capitalize(historyEntry.oldValue || "")} auf ${lodash.capitalize(historyEntry.newValue || "")} geändert.`
            }
        case HistoryEntryType.UTILIZATION:
            return `Die Verwertung wurde von ${historyEntry.user.name} dokumentiert.`
        case HistoryEntryType.REQUEST_MATERIAL_ANALYSIS:
            return `${historyEntry.user.name} hat eine Probe für die Material Analyse angefordert.`
        default:
            return ""
    }
}

export const TicketHistoryEntry: React.FunctionComponent<ITicketHistoryEntryProps> = (props) => {
    const { historyEntry } = props

    if (historyEntry) {
        return (
            <li style={{ margin: "8px 0" }}>
                <strong>{moment(historyEntry.createdAt).format("DD.MM.YYYY HH:mm")} </strong>
                {formatHistoryEntry(historyEntry)}
            </li>
        )
    }

    return <Fragment />
}

