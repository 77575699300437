import { gql } from "apollo-boost";
import { MutationHookOptions, useMutation } from "react-apollo-hooks";
import {
  updateClaim,
  updateClaimVariables,
  updateClaim_updateClaim,
  updateClaim_updateClaim_attachments,
} from "./types/updateClaim";

const mutation = gql`
  mutation updateClaim($claimNumber: Int!, $claim: UpdateClaimInput!) {
    updateClaim(claimNumber: $claimNumber, claim: $claim) {
      id
      orderId
      claimType
      materialSection {
        materialId
        comment
        quantity
        unit
      }
      physicalQualitySection {
        deviationType
        description
        comment
      }
      chemicalQualitySection {
        deviationType
        description
        comment
      }
      logisticQualitySection {
        deviationType
        comment
      }
      attachments {
        id
        url
        title
        mimeType
      }
      productionSection {
        plantId
        repeatedError
        reason
        recommendedAction
        comment
      }
      partnerServiceSection {
        comment
        commentDeviationPosition
        deviationPosition {
          name
          isSelected
          value
        }
        invoicePrice {
          name
          isSelected
          value
        }
        missingBilledMaterial
        priceMismatch
      }
    }
  }
`;

export type UpdateClaim = updateClaim_updateClaim;
export type UpdateClaimAttachment = updateClaim_updateClaim_attachments;
type UpdateClaimResult = updateClaim;
type UpdateClaimVariables = updateClaimVariables;
export const useUpdateClaimMutation = (
  options: MutationHookOptions<UpdateClaimResult, UpdateClaimVariables>
) => useMutation(mutation, options);
