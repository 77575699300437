import { gql } from "apollo-boost";
import { QueryHookOptions, useQuery } from "react-apollo-hooks";
import { claims, claims_claims } from "./types/claims";

const query = gql`
  query claims {
    claims {
      id
      orderId
      number
      claimType
      closedAt
      orderSection {
        orderDate
        orderNr
        materialNumber
        materialTitle
        materialWeight
        materialUnit
        carrierId
        deliveryNumber
        consignmentNoteNumber
        sourcePlant
        targetPlant
      }
      materialSection {
        materialId
        material {
          id
          title
          matnr
        }
      }
      productionSection {
        plantId
        plant {
          id
          facility
        }
      }
      partnerServiceSection {
        comment
        commentDeviationPosition
        deviationPosition {
          name
          isSelected
          value
        }
        invoicePrice {
          name
          isSelected
          value
        }
        missingBilledMaterial
        priceMismatch
      }
      assignedRole
      assignedUser {
        id
        name
      }
    }
  }
`;

export type Claim = claims_claims;
type ClaimsResult = claims;
type ClaimsVariables = {};
export const useClaimsQuery = (options?: QueryHookOptions<ClaimsVariables>) =>
  useQuery<ClaimsResult, ClaimsVariables>(query, options);
