import React, { FC } from "react";
import "url-search-params-polyfill";
import Lozenge from "@atlaskit/lozenge";
import { Link } from "react-router-dom";
import { Card, CardHeader, CardWrapper, CardInfo } from "../../styled/Board";

interface ILaneItemProps {
  /** e.g. `/tickets/1` */
  route: string;
}

export const LaneItem: FC<ILaneItemProps> = (props) => {
  const { route: link, children } = props;

  return (
    <CardWrapper>
      <Link style={{ textDecoration: "none" }} to={link}>
        <Card style={{ padding: "8px" }}>{children}</Card>
      </Link>
    </CardWrapper>
  );
};

// Header.
interface LaneItemHeaderProps {
  title: string;
}

export const LaneItemHeader: FC<LaneItemHeaderProps> = (props) => {
  const { title, children } = props;
  return (
    <CardHeader>
      <div>
        <Lozenge>{title}</Lozenge>
      </div>
      {children}
    </CardHeader>
  );
};

// Body.
interface LaneItemBodyProps {}

export const LaneItemBody: FC<LaneItemBodyProps> = (props) => {
  const { children } = props;
  return <CardInfo style={{ gap: "4px" }}>{children}</CardInfo>;
};
