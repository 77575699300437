import React from "react";
import { useTranslation } from "react-i18next";
import { MaterialUnit } from "../../../../../../api/graphql/graphql-global-types";

interface IFormattedMaterialUnitProps {
  unit: MaterialUnit;
}

export const FormattedMaterialUnit: React.VFC<IFormattedMaterialUnitProps> = (
  props
) => {
  const { unit } = props;
  const { t } = useTranslation();
  switch (unit) {
    case MaterialUnit.PERCENTAGE:
      return <span>{t("create_claim.table.unit_options.percentage")}</span>;
    case MaterialUnit.KG:
      return <span>{t("create_claim.table.unit_options.kg")}</span>;
    case MaterialUnit.TO:
    default:
      return <span>{t("create_claim.table.unit_options.tons")}</span>;
  }
};
