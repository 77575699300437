import React, { VFC } from "react";
import { GridColumn } from "@atlaskit/page";
import { useTranslation } from "react-i18next";
import { FileInput } from "../../../api/graphql/graphql-global-types";
import { CustomDropzone } from "../CustomDropzone";
import { FormSection } from "../FormSection";

interface IExistingFile {
  title: string;
  url: string;
}

interface IFileUploadFormSectionProps {
  files: FileInput[];
  onChange: (files: FileInput[]) => void;
  existingFiles?: IExistingFile[];
  disabled?: boolean;
}

export const FileUploadFormSection: VFC<IFileUploadFormSectionProps> = (
  props
) => {
  const { files, onChange, existingFiles, disabled } = props;
  const { t } = useTranslation();

  return (
    <FormSection title={t("create_claim.form.file_upload_section.title")}>
      <GridColumn medium={12}>
        <CustomDropzone
          files={files}
          onChange={onChange}
          existing={existingFiles}
          loading={false}
          disabled={disabled}
        />
      </GridColumn>
    </FormSection>
  );
};
