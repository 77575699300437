import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Button from "@atlaskit/button";
import FilterIcon from "@atlaskit/icon/glyph/filter";
import { CardFrame } from "@atlaskit/media-ui";
import { Grid, GridColumn } from "@atlaskit/page";
import { PartnerFragment as IPartner } from "../../../../../../api/graphql/fragments/types/PartnerFragment";
import PartnerSelect from "../../../../tickets/fields/PartnerSelect";
import { UnwrapGrid } from "../../../../create-ticket/CreateTicketForm";
import EditSelect, {
  IEditSelectOption,
} from "../../../../tickets/fields/EditSelect";
import { PlantFragment } from "../../../../../../api/graphql/fragments/types/PlantFragment";
import { formatDisposalPlantOption } from "../../../../tickets/fields/ShowPlant";
import EditText from "../../../../tickets/fields/EditText";
import { CustomDatePicker } from "../../../../../partials/CustomDatePicker";
import { IOrderSearchParams } from "../../../types/order-search-params";
import { Carrier } from "../../../../../../api/graphql/queries/carriers";

interface IOrderSearchFormProps {
  plants: PlantFragment[];
  carriers: Carrier[];
  setSearchParams: (searchParams: IOrderSearchParams) => void;
}

const generateDateWithoutTime = () => {
  const date = new Date();
  date.setUTCHours(0, 0, 0, 0);
  return date;
};

export const OrderSearchForm: React.FunctionComponent<IOrderSearchFormProps> = (
  props
) => {
  const { plants, carriers, setSearchParams } = props;

  const [selectedPartner, setSelectedPartner] = useState<IPartner | null>(null);
  const [selectedSourcePlant, setSelectedSourcePlant] = useState<
    PlantFragment | undefined
  >(undefined);
  const [selectedTargetPlant, setSelectedTargetPlant] = useState<
    PlantFragment | undefined
  >(undefined);
  const [dateFrom, setDateFrom] = useState<Date | undefined>(
    generateDateWithoutTime()
  );
  const [dateTo, setDateTo] = useState<Date | undefined>(undefined);
  const [selectedCarrier, setSelectedCarrier] = useState<Carrier | undefined>(
    undefined
  );
  const [orderNr, setOrderNr] = useState<string | undefined>(undefined);
  const [deliveryNumber, setDeliveryNumber] = useState<string | undefined>(
    undefined
  );
  const [consignmentNoteNumber, setConsignmentNoteNumber] = useState<
    string | undefined
  >(undefined);
  const { t } = useTranslation();

  const plantSelection = useCallback(() => {
    return plants
      ? plants.map((plant: PlantFragment) => formatDisposalPlantOption(plant))
      : [];
  }, [plants]);

  const carrierSelection = useCallback(() => {
    return carriers
      ? carriers.map((carrier: Carrier) => {
          return {
            title: carrier.name,
            additional: carrier.description ?? undefined,
            label: `${carrier.id} ${carrier.name} ${carrier.description}`,
            value: carrier.id,
            description: carrier.description ?? undefined,
          };
        })
      : [];
  }, [carriers]);

  const selectPartner = (p: IPartner | null) => {
    setSelectedPartner(p);
  };

  const selectSourcePlant = (selected?: IEditSelectOption) => {
    const sourcePlant = selected
      ? plants.find((plant: PlantFragment) => plant.id === selected.value)
      : undefined;
    setSelectedSourcePlant(sourcePlant);
  };

  const selectTargetPlant = (selected?: IEditSelectOption) => {
    const targetPlant = selected
      ? plants.find((plant: PlantFragment) => plant.id === selected.value)
      : undefined;
    setSelectedTargetPlant(targetPlant);
  };

  const onOrderNrChange = (fieldname: string, value: any) => {
    if (value.length < 1) {
      setOrderNr(undefined);
    } else {
      setOrderNr(value);
    }
  };

  const selectCarrier = (selected?: IEditSelectOption) => {
    const carrier = selected
      ? carriers.find((carrier: Carrier) => carrier.id === selected.value)
      : undefined;
    setSelectedCarrier(carrier);
  };

  const onDeliveryNumberChange = (fieldname: string, value: any) => {
    if (value.length < 1) {
      setDeliveryNumber(undefined);
    } else {
      setDeliveryNumber(value);
    }
  };

  const onConsignmentNoteNumberChange = (fieldname: string, value: any) => {
    if (value.length < 1) {
      setConsignmentNoteNumber(undefined);
    } else {
      setConsignmentNoteNumber(undefined);
    }
  };

  const isDisabledSearch = useCallback(() => {
    const searchParams: IOrderSearchParams = {
      orderNr: orderNr ?? null,
      deliveryNumber: deliveryNumber ?? null,
      consignmentNoteNumber: consignmentNoteNumber ?? null,
      carrierFleetEquNr: selectedCarrier?.fleetEqunr ?? null,
      partnerId: selectedPartner?.id ?? null,
      sourcePlantId: selectedSourcePlant?.id ?? null,
      targetPlantId: selectedTargetPlant?.id ?? null,
      dateFrom: dateFrom ?? null,
      dateTo: dateTo ?? null,
    };
    const isNoSearchTermSet = Object.values(searchParams).every(
      (value) => value === null
    );
    return isNoSearchTermSet;
  }, [
    selectedPartner,
    selectedSourcePlant,
    selectedTargetPlant,
    selectedCarrier,
    orderNr,
    deliveryNumber,
    consignmentNoteNumber,
    dateFrom,
    dateTo,
  ]);

  const generateSearchTermVariables = () => {
    const searchParams: IOrderSearchParams = {
      orderNr: orderNr ?? null,
      consignmentNoteNumber: consignmentNoteNumber ?? null,
      deliveryNumber: deliveryNumber ?? null,
      carrierFleetEquNr: selectedCarrier?.fleetEqunr ?? null,
      partnerId: selectedPartner?.id ?? null,
      sourcePlantId: selectedSourcePlant?.id ?? null,
      targetPlantId: selectedTargetPlant?.id ?? null,
      dateFrom: dateFrom ?? null,
      dateTo: dateTo ?? null,
    };

    setSearchParams(searchParams);
  };

  return (
    <div>
      <GridColumn medium={12}>
        <CardFrame text={t("filter")}>
          <div style={{ margin: "0px 10px 10px 10px" }}>
            <UnwrapGrid>
              <Grid layout="max-width">
                <GridColumn medium={4}>
                  <PartnerSelect
                    value={selectedPartner}
                    onChange={(p: IPartner | null) => {
                      selectPartner(p);
                    }}
                    isClearable
                  />
                </GridColumn>
                <GridColumn medium={4}>
                  <EditSelect
                    name="source_plant"
                    translation="create_claim.search.source_plant"
                    clearable
                    options={plantSelection()}
                    onChange={(name: string, selected?: IEditSelectOption) => {
                      selectSourcePlant(selected);
                    }}
                    selected={
                      selectedSourcePlant
                        ? formatDisposalPlantOption(selectedSourcePlant)
                        : null
                    }
                  />
                </GridColumn>
                <GridColumn medium={4}>
                  <EditSelect
                    name="target_plant"
                    translation="create_claim.search.target_plant"
                    options={plantSelection()}
                    onChange={(name: string, selected?: IEditSelectOption) => {
                      selectTargetPlant(selected);
                    }}
                    clearable
                    selected={
                      selectedTargetPlant
                        ? formatDisposalPlantOption(selectedTargetPlant)
                        : null
                    }
                  />
                </GridColumn>
                <GridColumn medium={4}>
                  <EditSelect
                    name="carrier"
                    translation="create_claim.search.carrier"
                    options={carrierSelection()}
                    onChange={(name: string, selected?: IEditSelectOption) => {
                      selectCarrier(selected);
                    }}
                    clearable
                    selected={
                      selectedCarrier
                        ? {
                            title: selectedCarrier.name,
                            additional:
                              selectedCarrier.description ?? undefined,
                            label: `${selectedCarrier.id} ${selectedCarrier.name} ${selectedCarrier.description}`,
                            value: selectedCarrier.id,
                            description:
                              selectedCarrier.description ?? undefined,
                          }
                        : null
                    }
                  />
                </GridColumn>
                <GridColumn medium={4}>
                  <EditText
                    translation="create_claim.search.objectnumber"
                    name="objectnumber"
                    value={orderNr}
                    style={{ height: 45 }}
                    onChange={onOrderNrChange}
                  />
                </GridColumn>
                <GridColumn medium={4}>
                  <EditText
                    name="deliverynumber"
                    translation="create_claim.search.deliverynumber"
                    value={deliveryNumber}
                    style={{ height: 45 }}
                    onChange={onDeliveryNumberChange}
                  />
                </GridColumn>
                <GridColumn medium={4}>
                  <EditText
                    name="consignment_note_number"
                    translation="create_claim.search.consignment_note_number"
                    value={consignmentNoteNumber}
                    onChange={onConsignmentNoteNumberChange}
                  />
                </GridColumn>
                <GridColumn medium={4}>
                  <CustomDatePicker
                    label={t("create_claim.search.from_date")}
                    name="date-picker-from"
                    onChange={(value: string) => {
                      setDateFrom(value ? new Date(value) : undefined);
                    }}
                    value={dateFrom?.toISOString() || ""}
                    placeholder={" "}
                  />
                </GridColumn>
                <GridColumn medium={4}>
                  <CustomDatePicker
                    label={t("create_claim.search.to_date")}
                    name="date-picker-to"
                    onChange={(value: string) => {
                      setDateTo(value ? new Date(value) : undefined);
                    }}
                    value={dateTo?.toISOString() || ""}
                    placeholder={" "}
                  />
                </GridColumn>
                <GridColumn medium={12}>
                  <div style={{ float: "right", paddingTop: 16 }}>
                    <Button
                      appearance={"primary"}
                      iconBefore={
                        <FilterIcon label="Aufträge filter" size="medium" />
                      }
                      onClick={generateSearchTermVariables}
                      isDisabled={isDisabledSearch()}
                    >
                      {t("create_claim.search.filter")}
                    </Button>
                  </div>
                </GridColumn>
              </Grid>
            </UnwrapGrid>
          </div>
        </CardFrame>
      </GridColumn>
    </div>
  );
};
