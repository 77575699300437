import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import { oenorms, oenorms_oenorms } from "./types/oenorms";

export const OENORM_QUERY = gql`
  query oenorms {
    oenorms {
      id
      code
      title
      ctrlLevels
      spezifikation
    }
  }
`;

export type Oenorm = oenorms_oenorms;
export class OenormsQuery extends Query<oenorms> {}
