import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-apollo-hooks";
import { addCommentMutation } from "../../../../api/graphql/mutations/AddComment";
import { useUsersQuery } from "../../../../api/graphql/queries/users";
import { addComment, addCommentVariables } from "../../../../api/graphql/mutations/types/addComment";
import styled from "styled-components";
import Button, { ButtonGroup } from '@atlaskit/button';
import { Checkbox } from "@atlaskit/checkbox";
import Select from "@atlaskit/select";
import Spinner from '@atlaskit/spinner';
import { CommentInput } from "../../../partials/comment/CommentInput";

interface ITicketCommentEditorComponentProps {
  ticketId: string
  onDidSave: (shouldRequestMaterialAnalysis?: boolean, statusMaterialProbeAnalysis?: string, userId?: string) => Promise<any>
  onDidCancel?: () => void
  saveButtonText: string
  shouldCommentOnSubmit: boolean
  showMaterialAnalyis?: boolean
  showMaterialProbe?: boolean
  showUserSelection?: boolean
  isUserSelectionRequired?: boolean
  showUserAbility?: string
}

const Container = styled.div`
  margin-top: 8px;
`

const ButtonContainer = styled.div`
width: 100%;
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;

`

const CancelButton = styled.div`
margin-right: 8px;
`

/**
 * @deprecated Use the more generic CommentEditor component instead.
 */
export const TicketCommentEditor: React.FunctionComponent<ITicketCommentEditorComponentProps> = (props) => {

  const { ticketId, onDidSave, onDidCancel, shouldCommentOnSubmit } = props
  const [showError, setShowError] = useState(false)
  const [shouldRequestMaterialAnalysis, setRequestMaterialAnalysis] = useState(false)
  const [statusMaterialProbeAnalysis, setStatusMaterialProbeAnalysis] = useState<string | undefined>(undefined)
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(undefined)
  const onChangeMaterialAnalysis = (event: React.ChangeEvent<HTMLInputElement>) => setRequestMaterialAnalysis(event.target.checked)
  const { t } = useTranslation()
  const [saveCommentMutation, { loading }] = useMutation<addComment, addCommentVariables>(addCommentMutation)
  const {data: users} = useUsersQuery({
    variables: {
      ability: props.showUserAbility ? props.showUserAbility : "lab"
    }
  })
  const [commentText, setCommentText] = useState("")

  const onSave = async () => {
    setShowError(false)

    try {
      if (shouldCommentOnSubmit && commentText && `${commentText}`.length > 0) {
        const result = await saveCommentMutation({
          variables: {
            ticketId,
            comment: commentText
          }
        })

        if (result.errors && !result.data) {
          setShowError(true)
          return
        }
      }
      await onDidSave(shouldRequestMaterialAnalysis, statusMaterialProbeAnalysis, selectedUserId)
      onCancel()

    } catch (e) {
      setShowError(true)
    }

  }

  const onCancel = () => {
    setCommentText("")
    if (onDidCancel) {
      onDidCancel()
    }
  }

  return (<Container style={{ marginBottom: "20px" }}>
    <CommentInput value={commentText} onChange={setCommentText} disabled={loading} />

    {props.showMaterialAnalyis && <Checkbox
      isChecked={shouldRequestMaterialAnalysis}
      onChange={onChangeMaterialAnalysis}
      label="Probe für Labor anfordern"
      name="request_material_analysis"
    />}
    {props.showMaterialProbe && <div style={{ marginTop: "0.5em" }}>
      <Select options={[
          { label: "Probe wird ans Labor gesendet", value: "IN_TRANSIT" },
          { label: "Probe ist vorhanden", value: "AVAILABLE" },
          { label: "Keine Probe vorhanden", value: "NOT_AVAILABLE" },
        ]}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        }}
        placeholder="Ist eine Probe vorhanden?"
        onChange={(selected: { label: string, value: string }) => {
          console.log(selected)
          setStatusMaterialProbeAnalysis(selected.value)
        }}
      />
    </div>}
    {props.showUserSelection && !users && <Spinner size={"medium"} />}
    {props.showUserSelection && users && <div style={{ marginTop: "0.5em" }}>
      <Select options={users.users?.filter((user) => user?.active === true).map((user) => ({
          label: user?.name,
          value: user?.id
        }))}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        }}
        placeholder={`Ticket zuweisen an ${props.isUserSelectionRequired !== false ? "(Pflichtfeld)" : ""}...`}
        onChange={(selected: { label: string, value: string }) => {
          console.log(selected)
          setSelectedUserId(selected.value)
        }}
      />
    </div>}
    <ButtonContainer>
      <ButtonGroup>
        <CancelButton>
          <Button appearance="secondary" onClick={onCancel} isDisabled={loading}>{t('cancel')}</Button>
        </CancelButton>
        <Button
          tabIndex={-1}
          appearance="primary"
          onClick={onSave}
          isDisabled={loading || (props.showMaterialProbe && !statusMaterialProbeAnalysis)|| (props.showUserSelection && !selectedUserId && props.isUserSelectionRequired !== false)}
        >
          {props.saveButtonText}
        </Button>
      </ButtonGroup>
    </ButtonContainer>


    {
      showError ? <div>{t('tickets.detail.sections.editor.error')}</div> : undefined
    }
  </Container>
  )
}

