import Button, { ButtonGroup } from "@atlaskit/button"
import Form from "@atlaskit/form"
import { CardFrame } from "@atlaskit/media-ui"
import ModalDialog, { ModalFooter, ModalTransition } from "@atlaskit/modal-dialog"
import { Grid, GridColumn } from "@atlaskit/page"
import * as React from "react"
import __ from "lodash"
import { withTranslation } from "react-i18next"
import { Ticket_ticket as TicketType } from "../../../../api/graphql/queries/types/Ticket"
import { IBaseProps } from "../../../../utils/BaseProps"
import CardFrameContent from "../../../partials/CardFrameContent"
import EditTextArea from "../fields/EditTextArea"
import completeTicketMutuation, { CompleteTicketMutation } from "../../../../api/graphql/mutations/CompleteTicketMutation"
import { completeTicketVariables } from "../../../../api/graphql/mutations/types/completeTicket"
import GridSpacer from "../partials/GridSpacer"
import EditSelect from "../fields/EditSelect"

interface ICompleteActionProps extends IBaseProps {
  ticket: TicketType
  show: Boolean
  onClose: () => any
  onChange?: () => any
}

class CompleteAction extends React.Component<ICompleteActionProps, any> {
  state: any
  
  constructor(props: ICompleteActionProps) {
    super(props)

    this.state = {
      explanation: "Abfallübernahme nicht möglich"
    }
  }

  handleFieldChange = (fieldname: string, value: any) => {
    this.setState({
      [fieldname]: value
    })
  }

  generateFormSubmitHandler = (mutation: (options: { variables: completeTicketVariables }) => Promise<any>) => (data: Object) => {
    const variables = {
      id: this.props.ticket.id,
      hasReceivedOrder: this.state.hasReceivedOrder ? this.state.hasReceivedOrder.value === true : false, 
      explanation: this.state.hasReceivedOrder && this.state.hasReceivedOrder.value === false ? this.state.explanation : null,
    }

    mutation({ variables })
      .then(() => {
        this.props.onChange && this.props.onChange()
        this.handleClose()
      })
      .catch(() => alert("error"))
  }

  handleClose = () => this.props.onClose()
  
  public render() {
    const { t, ticket } = this.props
    const footer = (props: any) => (
      <ModalFooter showKeyline={props.showKeyline}>
        <div style={{ flexGrow: 1 }}/>
        <ButtonGroup>
          <Button onClick={this.handleClose}>{t("tickets.complete.modal.actions.cancel.title")}</Button>
          <Button appearance="primary" type="submit">{t("tickets.complete.modal.actions.complete.title")}</Button>
        </ButtonGroup>
      </ModalFooter>
    )

    return (
      <ModalTransition>
        {this.props.show && <ModalDialog
          heading={t('tickets.complete.modal.title', { ticket: `T#${ticket.number}`})}
          width="x-large"
          onClose={this.handleClose}
          components={{
            Container: ({ children, className }: {children: any, className: any}) => (
              <CompleteTicketMutation mutation={completeTicketMutuation}>
              {(mutation) => (
                <Form onSubmit={this.generateFormSubmitHandler(mutation)}>
                  {({ formProps }: { formProps: any }) => (
                    <form {...formProps} className={className}>
                      {children}
                    </form>
                  )}
                </Form>
              )}
              </CompleteTicketMutation>
            ),
            Footer: footer,
          }}
        >
          <Grid layout="max-width">
            <GridColumn medium={12}>
              {t('tickets.complete.modal.info')}
              <GridSpacer />
            </GridColumn>
            <GridColumn medium={12}>
              <CardFrame text={t('tickets.complete.modal.section.order.title')}>
                <CardFrameContent>
                <Grid layout="max-width">
                  <GridColumn medium={12}>
                    <EditSelect
                      name="hasReceivedOrder"
                      translation="tickets.complete.modal.section.order.fields.hasReceivedOrder"
                      options={[
                        { label: "Ja", value: true },
                        { label: "Nein", value: false },
                      ]}
                      required
                      onChange={this.handleFieldChange}
                      selected={this.state.hasReceivedOrder}
                    />
                  </GridColumn>
                  {this.state.hasReceivedOrder && this.state.hasReceivedOrder.value === false && (
                    <GridColumn medium={12}>
                      <EditTextArea name="explanation"
                        translation="tickets.complete.modal.section.order.fields.explanation"
                        onChange={this.handleFieldChange}
                        value={this.state.explanation}
                        required />
                    </GridColumn>
                  )}
                </Grid>
                </CardFrameContent>
              </CardFrame>
            </GridColumn>
          </Grid>
        </ModalDialog>}
      </ModalTransition>
    )
  }
}

export default withTranslation()(CompleteAction)
