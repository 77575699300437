import React, { useState, VFC } from "react";
import { GridColumn } from "@atlaskit/page";
import { useTranslation } from "react-i18next";
import { FormSection } from "../FormSection";
import { IChemicalQualitySection } from "./types/claim-types";
import EditSelect, {
  IEditSelectOption,
} from "../../pages/tickets/fields/EditSelect";
import { ChemicalQualityDeviationType } from "../../../api/graphql/graphql-global-types";
import { CustomTextArea } from "../CustomTextArea";
import { CustomTextfield } from "../CustomTextField";
import i18next from "i18next";

interface IChemicalQualityFormSectionProps {
  section: IChemicalQualitySection;
  onChange: (section: IChemicalQualitySection) => void;
  disabled?: boolean;
}

export const ChemicalQualityFormSection: VFC<
  IChemicalQualityFormSectionProps
> = (props) => {
  const { section, onChange, disabled } = props;
  const { t } = useTranslation();

  const deviationOptions: IDeviationOption[] = getDeviationOptions(t);
  const [deviationOption, setDeviationOption] = useState(
    deviationOptions.find((option) => option.value === section.deviationType) ??
      null
  );

  const onChangeDeviationType = (
    name: string,
    selected: IEditSelectOption | null
  ) => {
    const selectedDeviation = selected
      ? {
          label: selected.label,
          value: selected.value,
        }
      : null;
    setDeviationOption(selectedDeviation);

    const value = selected?.value || null;
    const changedChemicalQuality: IChemicalQualitySection = {
      ...section,
      deviationType: value,
      description: value ? section.description : "",
    };
    onChange(changedChemicalQuality);
  };

  const onChangeDescription = (event: any) => {
    const changedChemicalQuality: IChemicalQualitySection = {
      ...section,
      description: event?.target?.value ?? "",
    };
    onChange(changedChemicalQuality);
  };

  const onChangeComment = (event: any) => {
    const changedChemicalQuality: IChemicalQualitySection = {
      ...section,
      comment: event?.target?.value ?? "",
    };
    onChange(changedChemicalQuality);
  };

  const showDescription = () => {
    return !!section.deviationType;
  };

  return (
    <FormSection title={t("create_claim.form.chemical_quality_section.title")}>
      <GridColumn medium={6}>
        <EditSelect
          name="deviation_type"
          translation="create_claim.form.chemical_quality_section.deviation_type"
          options={deviationOptions}
          onChange={onChangeDeviationType}
          selected={deviationOption}
          clearable
          disabled={disabled}
        />
      </GridColumn>
      {showDescription() && (
        <GridColumn medium={6}>
          <CustomTextfield
            name="description"
            translation="create_claim.form.chemical_quality_section.description"
            value={section.description}
            onChange={onChangeDescription}
            disabled={disabled}
            required={true}
          />
        </GridColumn>
      )}
      <GridColumn medium={12}>
        <CustomTextArea
          name="comment"
          translation="create_claim.form.chemical_quality_section.comment"
          value={section.comment}
          onChange={onChangeComment}
          disabled={disabled}
        />
      </GridColumn>
    </FormSection>
  );
};

interface IDeviationOption {
  label: string;
  value: ChemicalQualityDeviationType;
  subtitle?: string | null;
}

const getDeviationOptions = (t: i18next.TFunction): IDeviationOption[] => {
  return [
    {
      label: t(
        `create_claim.form.chemical_quality_section.deviation_options.SCHWERMETALLE`
      ),
      value: ChemicalQualityDeviationType.SCHWERMETALLE,
    },
    {
      label: t(
        "create_claim.form.chemical_quality_section.deviation_options.WASSERGEHALT"
      ),
      value: ChemicalQualityDeviationType.WASSERGEHALT,
    },
    {
      label: t(
        "create_claim.form.chemical_quality_section.deviation_options.PCB"
      ),
      value: ChemicalQualityDeviationType.PCB,
    },
    {
      label: t(
        "create_claim.form.chemical_quality_section.deviation_options.HEIZWERT"
      ),
      value: ChemicalQualityDeviationType.HEIZWERT,
    },
    {
      label: t(
        "create_claim.form.chemical_quality_section.deviation_options.FLAMMPUNKT"
      ),
      value: ChemicalQualityDeviationType.FLAMMPUNKT,
    },
    {
      label: t(
        "create_claim.form.chemical_quality_section.deviation_options.ANLIEFERTEMPERATUR"
      ),
      value: ChemicalQualityDeviationType.ANLIEFERTEMPERATUR,
    },
    {
      label: t(
        "create_claim.form.chemical_quality_section.deviation_options.SEDIMENTE"
      ),
      value: ChemicalQualityDeviationType.SEDIMENTE,
    },
    {
      label: t(
        "create_claim.form.chemical_quality_section.deviation_options.VISKOSITAT"
      ),
      value: ChemicalQualityDeviationType.VISKOSITAT,
    },
    {
      label: t(
        "create_claim.form.chemical_quality_section.deviation_options.CHLOR"
      ),
      value: ChemicalQualityDeviationType.CHLOR,
    },
    {
      label: t(
        "create_claim.form.chemical_quality_section.deviation_options.HALOGENE"
      ),
      value: ChemicalQualityDeviationType.HALOGENE,
    },
    {
      label: t(
        "create_claim.form.chemical_quality_section.deviation_options.SONSTIGES"
      ),
      value: ChemicalQualityDeviationType.SONSTIGES,
    },
    {
      label: t(
        "create_claim.form.chemical_quality_section.deviation_options.LEITFAEHIGKEIT"
      ),
      value: ChemicalQualityDeviationType.LEITFAEHIGKEIT,
    },
    {
      label: t(
        "create_claim.form.chemical_quality_section.deviation_options.PHWERT"
      ),
      value: ChemicalQualityDeviationType.PHWERT,
    },
    {
      label: t(
        "create_claim.form.chemical_quality_section.deviation_options.SCHWEFEL"
      ),
      value: ChemicalQualityDeviationType.SCHWEFEL,
    },
  ];
};
