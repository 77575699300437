import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import { SearchTickets } from "./types/SearchTickets";

export default gql`
  query SearchTickets(
    $searchTerms: [String!]!
    $materialId: String
    $oenormId: String
    $partnerId: String
    $divisionId: String
  ) {
    searchTickets(
      searchTerms: $searchTerms
      materialId: $materialId
      oenormId: $oenormId
      partnerId: $partnerId
      divisionId: $divisionId
    ) {
      id
      number
      status
      assignedRole
      general {
        partnerName
        oenorm {
          id
          title
          code
        }
        material {
          id
          title
          matnr
        }
        partner {
          id
          name
        }
        division {
          id
          title
        }
      }
    }
  }
`;

export class SearchTicketsQuery extends Query<SearchTickets> {}
