import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Tickets_tickets } from "../../../../api/graphql/queries/types/Tickets";
import { List } from "../../../partials/list/List";
import { TicketListItem } from "./TicketListItem";

interface ITicketListProps {
  tickets: Tickets_tickets[];
  loading: boolean;
  error: boolean;
}

export const TicketList: FC<ITicketListProps> = (props) => {
  const { t } = useTranslation();
  const { tickets, loading, error } = props;

  const renderItem = (ticket: Tickets_tickets) => {
    return <TicketListItem ticket={ticket} key={ticket.id} />;
  };

  return (
    <List
      items={tickets}
      renderItem={renderItem}
      loading={loading}
      error={error}
      emptyText={t("tickets.search.error.no_tickets_found")}
      errorText={t("tickets.search.error.failed_to_fetch")}
    />
  );
};
