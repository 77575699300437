import {gql} from "apollo-boost";
import {Mutation} from "react-apollo";
import {uploadAttachment, uploadAttachmentVariables} from "./types/uploadAttachment";

export default gql` 
  mutation uploadAttachment($title: String!, $base64: String!) { 
    uploadAttachment(title: $title, base64: $base64) {    
      id
      url
      mimeType
      title
      createdAt
      updatedAt
    }
}
  `

export class UploadAttachmentMutation extends Mutation<uploadAttachment, uploadAttachmentVariables> {}
