import * as React from "react"
import __ from "lodash"
import { withTranslation } from "react-i18next"
import { Grid, GridColumn } from "@atlaskit/page"
import { CardFrame } from "@atlaskit/media-ui"
import CardFrameContent from "../../../../partials/CardFrameContent"
import { IBaseProps } from "../../../../../utils/BaseProps"
import { Ticket_ticket_disposalWaste as Specifics } from "../../../../../api/graphql/queries/types/Ticket"
import Headline from "../../partials/Headline"
import ShowText from "../../fields/ShowText"
import ShowBoolean from "../../fields/ShowBoolean"

interface IDisposalWasteProps extends IBaseProps {
  specifics: Specifics
}

interface IDisposalWasteState {

}

class DisposalWaste extends React.Component<IDisposalWasteProps, IDisposalWasteState> {
  state: IDisposalWasteState

  constructor(props: IDisposalWasteProps) {
    super(props)

    this.state = {
    }
  }

  private renderCompanyAndProcess() {
    const { t, specifics } = this.props
    return (
      <React.Fragment>
        <GridColumn medium={12}>
          <Headline>{t('company_process')}</Headline>
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="production" translation="tickets.fields.company_process.production" value={specifics.production} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="process" translation="tickets.fields.company_process.process" value={specifics.process} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="usage" translation="tickets.fields.company_process.usage" value={specifics.usage} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowBoolean name="sample_available" translation="tickets.fields.sample_available" value={specifics.isSampleAvailable} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowBoolean name="incinerator" translation="tickets.fields.material_description.incinerator" value={specifics.isIncinerator} />
        </GridColumn>
      </React.Fragment>
    )
  }

  private renderMaterialDescription() {
    const { t, specifics } = this.props
    return (
      <React.Fragment>
        <GridColumn medium={12}>
          <Headline>{t('material_description')}</Headline>
        </GridColumn>
        <GridColumn medium={12}>
          <ShowText name="size" translation="tickets.fields.material_description.size" value={specifics.size} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="consistency" translation="tickets.fields.material_description.consistency" value={specifics.consistency} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="consistency_additional" translation="tickets.fields.material_description.consistency_additional" value={specifics.consistencyAdditional} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowBoolean name="characterization_available" translation="tickets.fields.material_description.characterization_available" value={specifics.isCharacterizationAvailable} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowBoolean name="assassment_available" translation="tickets.fields.material_description.assassment_available" value={specifics.isAssessmentAvailable} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowBoolean name="isAnalyticsAvailable" translation="tickets.fields.material_description.analysis" value={specifics.isAnalyticsAvailable} />
        </GridColumn>
        <GridColumn medium={6}>
          <ShowText name="ph_value" translation="tickets.fields.material_description.ph_value" value={specifics.phValue} />
        </GridColumn>
      </React.Fragment>
    )
  }

  public render() {
    const { t, specifics } = this.props
    console.log(specifics)
    return (
      <CardFrame text={t('tickets.create.fields.deponierbare_abfaelle.title')}>
        <CardFrameContent>
          <Grid layout="max-width">
            <GridColumn medium={12}>
              <Headline>{t('generic')}</Headline>
            </GridColumn>
            <GridColumn medium={6}>
              <ShowText name="waste_description" translation="tickets.create.fields.waste_description" value={specifics.description} />
            </GridColumn>
            {this.renderCompanyAndProcess()}
            {this.renderMaterialDescription()}
          </Grid>
        </CardFrameContent>
      </CardFrame>
    )
  }
}

export default withTranslation()(DisposalWaste)
