import React, { FunctionComponent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Mutation } from "react-apollo";
import { toast } from "react-toastify";
import Button, { ButtonGroup } from "@atlaskit/button";
import { Ticket, Ticket_ticket } from "../../../api/graphql/queries/types/Ticket";
import {
  TicketByMaterialAndCustomer,
  TicketByMaterialAndCustomer_ticketByMaterialAndCustomer
} from "../../../api/graphql/queries/types/TicketByMaterialAndCustomer";
import { assignToMeMutation } from "../../../api/graphql/mutations/AssignToMe";
import { Role, TicketStatus } from "../../../api/graphql/graphql-global-types";
import { getLinkToWomByGuessing } from "../../../utils/getLinkToWomByGuessing";
import ShowTicket from "./show/Ticket";
import Utilization from "./actions/Utilization";
import CancelTicket from "./actions/Cancel";
import CompleteTicket from "./actions/Complete";
import { Abilities } from "../../../domain/models/abilities";
import createNoticeInWomMutation, { CreateNoticeInWomMutation } from "../../../api/graphql/mutations/CreateNoticeInWom";
import assignRoleMutation, { AssignRoleMutation } from "../../../api/graphql/mutations/AssignRoleMutation";
import { AssignRoleToTicketDialog } from "./partials/AssignRoleToTicketDialog";
import { AssignRoleDropdown } from "../../partials/AssignRoleDropdown";

interface ITicketDetailProps {
  data: Ticket | TicketByMaterialAndCustomer;
  ticket: Ticket_ticket | TicketByMaterialAndCustomer_ticketByMaterialAndCustomer;
  refetch: () => Promise<any>;
  hasAbility: Function;
  token: string;
  userId: string;
}

export const TicketDetail: FunctionComponent<ITicketDetailProps> = (props) => {
  const {data, ticket, refetch, token, userId, hasAbility} = props;
  const {t} = useTranslation();

  const [documentUtilizationId, setDocumentUtilizationId] = useState<string | null>(null);
  const [cancelTicketId, setCancelTicketId] = useState<string | null>(null);
  const [completeTicketId, setCompleteTicketId] = useState<string | null>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);

  const handleOpenWomNotice = useCallback((noticeId: string | null, token: string | undefined) => {
    const url = `/notices/${noticeId}`
    window.open(getLinkToWomByGuessing(token, "https", url) || "", "wom")
  }, [getLinkToWomByGuessing]);

  const handleCreateNoticeInWomMutationCompleted = useCallback((refetch: Function) => {
    toast.success("Die Bedarfsmeldung wurde erstellt!")
    refetch()
  }, [toast]);

  const handleCreateNoticeInWomMutationError = useCallback(() => {
    toast.error("Die Bedarfsmeldung konnte nicht erstellt werden!")
  }, [toast]);

  const handleAssignMutationComplete = useCallback((refetch: Function) => {
    toast.success("Ticket wurde zugewiesen")
    refetch()
  }, [toast]);

  const handleAssignMutationError = useCallback(() => {
    toast.error("Ticket konnte nicht zugewiesen werden!")
  }, [toast]);

  const generateRefetchHandler = useCallback(() => {
    refetch()
  }, [refetch]);

  const generateAssignRoleHandler = useCallback((mutation: Function, ticketId: string, role: Role, refetchTicket: Function) => {
    return async (shouldRequestMaterialAnalysis?: Boolean, statusMaterialProbeAnalysis?: String, userId?: String) => {
      await mutation({
        variables: {
          ticketId,
          role,
          shouldRequestMaterialAnalysis,
          statusMaterialProbeAnalysis,
          userId,
        }
      })

      await refetchTicket()
    }
  }, []);

  const handleModalClose = useCallback(() => {
    setDocumentUtilizationId(null);
    setCancelTicketId(null);
    setCompleteTicketId(null);
  }, [setDocumentUtilizationId, setCancelTicketId, setCompleteTicketId]);

  const assignToRole = useCallback((role: Role) => {
    setSelectedRole(role);
    setShowDialog(true);
  }, [setSelectedRole, setShowDialog]);

  return (
    <React.Fragment>
      <ShowTicket ticket={ticket} actions={(
        <ButtonGroup>
          {![TicketStatus.CANCELED, TicketStatus.COMPLETED].includes(ticket.status) && (
            <Button onClick={(e: any) => setCancelTicketId(ticket.id)}>Stornieren</Button>
          )}
          <AssignRoleDropdown 
            title={t(`assignee.${`${ticket.assignedRole || "UNASSIGNED"}`.toLowerCase()}.title`)} 
            onAssign={assignToRole} 
            excludedRoles={[Role.PARTNER_SERVICE]} 
          />
          {(!ticket.assignedUser || ticket.assignedUser.id !== userId) && (hasAbility(
            ticket.assignedRole === Role.VERTRIEB
              ? Abilities.VERTRIEB
              : ticket.assignedRole === Role.LABOR
                ? Abilities.LABOR
                : Abilities.STOFFSTROM_MANAGER
          )) && (
            <Mutation
              mutation={assignToMeMutation}
              onCompleted={() => handleAssignMutationComplete(refetch)}
              onError={handleAssignMutationError}
            >
              {(assignMutation: Function) => (
                <Button
                  appearance="primary"
                  onClick={(e: any) => assignMutation({
                      variables: {
                        ticketId: ticket.id,
                        allowOverride: true
                      }
                    }
                  )}
                >
                  Mir zuweisen
                </Button>
              )}
            </Mutation>
          )}
          {(![TicketStatus.CANCELED, TicketStatus.COMPLETED].includes(ticket.status)
              && hasAbility(Abilities.STOFFSTROM_MANAGER)
              && (!ticket.utilizations || ticket.utilizations.length < 1))
            && (
              <Button appearance="primary" onClick={(e: any) => setDocumentUtilizationId(ticket.id)}>Verwertung
                dokumentieren</Button>
            )}
          {ticket.utilizations && ticket.utilizations.length > 0 && ticket.status !== TicketStatus.COMPLETED && ticket.assignedRole === Role.VERTRIEB && hasAbility(Abilities.VERTRIEB) && (
            <Button appearance="primary"
                    onClick={(e: any) => setCompleteTicketId(ticket.id)}>Abschließen</Button>
          )}
          {ticket.status === TicketStatus.COMPLETED && (
            <CreateNoticeInWomMutation mutation={createNoticeInWomMutation}
                                       onCompleted={() => handleCreateNoticeInWomMutationCompleted(refetch)}
                                       onError={handleCreateNoticeInWomMutationError}
            >
              {(mutation, {loading}) => {
                if (!ticket.womNoticeId) {
                  return (
                    <Button appearance="primary"
                            onClick={() => mutation({variables: {ticketId: ticket.id}})}
                            isLoading={loading}
                    >
                      Bedarfsmeldung erstellen
                    </Button>
                  )
                }
                return (
                  <Button
                    onClick={() => handleOpenWomNotice(ticket.womNoticeId, token)}
                  >
                    Zur Bedarfsmeldung
                  </Button>
                )
              }}
            </CreateNoticeInWomMutation>
          )}
        </ButtonGroup>
      )} onTicketChanged={refetch}/>
      <Utilization
        plants={(data && data.plants) || []} ticket={ticket}
        show={!!documentUtilizationId}
        onChange={generateRefetchHandler}
        onClose={handleModalClose}
      />
      <CancelTicket
        ticket={ticket}
        show={!!cancelTicketId}
        onChange={generateRefetchHandler}
        onClose={handleModalClose}
      />
      <CompleteTicket
        ticket={ticket}
        show={!!completeTicketId}
        onChange={generateRefetchHandler}
        onClose={handleModalClose}
      />
      <AssignRoleMutation mutation={assignRoleMutation} onCompleted={refetch}>
        {(mutation, {loading}) => (
          selectedRole && <AssignRoleToTicketDialog
            assignTo={selectedRole}
            isOpen={showDialog}
            ticketId={ticket.id}
            onCloseDialog={() => setShowDialog(false)}
            onDidSave={generateAssignRoleHandler(mutation, ticket.id, selectedRole, refetch)}
          />
        )}
      </AssignRoleMutation>
    </React.Fragment>
  )
}