import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "../dialog/ConfirmDialog";
import { Typography } from "@material-ui/core";
import { useArchiveClaimMutation } from "../../../api/graphql/mutations/ArchiveClaim";
import { toast } from "react-toastify";

interface IArchiveClaimDialogProps {
  claimId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ArchiveClaimDialog: FC<IArchiveClaimDialogProps> = (props) => {
  const { t } = useTranslation();
  const { claimId, isOpen, onClose } = props;

  const [archiveClaimMutation, { loading, error }] = useArchiveClaimMutation({
    update: (_, mutationResult)=> {
      if (mutationResult.data?.archiveClaim) {
        toast.success(t("edit_claim.archive.success"));
        onClose();
      }
    },
    rethrow: false,
  })

  const onSubmit = async () => {
    await archiveClaimMutation({
      variables: {
        claimId,
      }
    });
  };

  useEffect(() => {
    if (error) {
      toast.error(t("edit_claim.archive.error"));
    }
  }, [error]);

  return(
    <ConfirmDialog
      title={t("claim.dialog.title")}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={loading}
      confirmText={t("claim.dialog.confirm_btn")}
    >
      <Typography>{t("claim.dialog.content")}</Typography>
    </ConfirmDialog>
  )
}
