import React, { FunctionComponent, Fragment, useState } from "react"
import __ from "lodash"
import { Section, Wrapper, Background, Header, Card } from "../../styled/login"
import { AuthConsumer } from '../../../context/AuthContext';
import lodash from 'lodash'
import { GridColumn } from '@atlaskit/page'
import { CardFrame } from '@atlaskit/media-ui';
import CardFrameContent from '../../partials/CardFrameContent';
import { useTranslation } from 'react-i18next';
import { FieldTextStateless } from '@atlaskit/field-text';
import { useEmail } from '../../../custom-hooks/emailHook';
import PageHeader from "@atlaskit/page-header"
import Button from "@atlaskit/button"
import Flag from "@atlaskit/flag"
import Info from '@atlaskit/icon/glyph/editor/info';
import Error from '@atlaskit/icon/glyph/editor/error';
import { successColor, errorColor } from '../../../intrusive/customColors';
import sendPasswordChangeMail from '../../../api/graphql/mutations/SendPasswordChangeMail';
import { Mutation } from 'react-apollo';
import useReactRouter from 'use-react-router'

interface ICustomWrapperProps {
    isLoggedIn: Boolean
}

const CustomWrapper: FunctionComponent<ICustomWrapperProps> = (props) => {
    const { isLoggedIn, children } = props
    const { t } = useTranslation()

    if (!isLoggedIn) {
        return (
            <Background>
                <Wrapper>
                    <Header>
                        <img src="./logo.png" alt={t("global.title")} />
                        <h1>{t("global.company")} <em>{t("global.product")}</em></h1>
                    </Header>
                    <Section>
                        <Card>
                            {children}
                        </Card>
                    </Section>
                </Wrapper>
            </Background>
        )
    }

    return (
        <Fragment>
            <PageHeader>{t('password_change.title')} </PageHeader>
            <GridColumn medium={12}>
                <CardFrame text={t('password_change.title')}>
                    <CardFrameContent>
                        {children}
                    </CardFrameContent>
                </CardFrame>
            </GridColumn>
        </Fragment>
    )

}

interface IRequestPasswordChangePageProps {

}

export const RequestPasswordChangePage: FunctionComponent<IRequestPasswordChangePageProps> = (props) => {
    const { email, isEmailValid, onEmailChange } = useEmail("")
    const { t } = useTranslation()
    const { history } = useReactRouter()

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const handleClick = (sendPasswordChangeMail: Function) => {
        const variables = { email: email }
        sendPasswordChangeMail({ variables })
        setIsLoading(true)
    }

    const handleMutationComplete = () => {
        setError(false)
        setSuccess(true)
        setIsLoading(false)
    }
    const handleMutationError = () => {
        setError(true)
        setSuccess(false)
        setIsLoading(false)
    }

    return (
        <AuthConsumer>
            {({ userId, isAuthenticated }) => (
                <Mutation
                    mutation={sendPasswordChangeMail}
                    onCompleted={handleMutationComplete}
                    onError={handleMutationError}
                >
                    {(sendPasswordChangeMail: Function) => {
                        const isLoggedIn = !lodash.isNil(userId)
                        return (
                            <CustomWrapper isLoggedIn={isLoggedIn}>
                                {!success &&
                                    <Fragment>
                                        <FieldTextStateless
                                            label={t("login.email.label")}
                                            isInvalid={!(isEmailValid || email === "")}
                                            name="email"
                                            shouldFitContainer
                                            disabled={isLoading}
                                            onChange={(event: any) => onEmailChange(event.target.value)}
                                            value={email}
                                        />
                                        <br />
                                        <Button
                                            appearance="primary"
                                            type="submit"
                                            onClick={() => handleClick(sendPasswordChangeMail)}
                                            isDisabled={!isEmailValid}
                                            isLoading={false}
                                            shouldFitContainer
                                        >
                                            {t('password_change.request_change')}
                                        </Button>
                                    </Fragment>
                                }
                                {error &&
                                    <div style={{ marginBottom: "1.5em" }}>
                                        <br />
                                        <Flag
                                            appearance="error"
                                            title="Ohje, das war unser Fehler!"
                                            icon={<Error label="Error icon" secondaryColor={errorColor} />}
                                            isDismissAllowed={true}
                                        />
                                    </div>
                                }
                                {success &&
                                    <div style={{ marginBottom: "1.5em" }}>
                                        <br />
                                        <Flag
                                            appearance="success"
                                            title="Überprüfen sie ihren E-Mail Posteingang"
                                            icon={<Info label="Info" secondaryColor={successColor} />}
                                            isDismissAllowed={true}
                                        />
                                    </div>

                                }
                                {!isAuthenticated() &&
                                    <Fragment>
                                        <br />
                                        <Button
                                            appearance="secondary"
                                            type="submit"
                                            onClick={() => history.push('/login')}
                                            isLoading={false}
                                            shouldFitContainer
                                        >
                                            {t('password_change.redirect_login')}
                                        </Button>
                                    </Fragment>
                                }
                            </CustomWrapper>

                        )
                    }}
                </Mutation>
            )}
        </AuthConsumer>

    )

}
