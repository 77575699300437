import * as React from "react"
import { withTranslation } from "react-i18next"
import { Field } from "@atlaskit/form"
import { IBaseProps } from "../../../../utils/BaseProps"
import FieldValue from "../partials/FieldValue"
import FieldPostfix from "../partials/FieldPostfix"

interface IShowNumberProps extends IBaseProps {
  name: String
  postfix?: String
  precision?: number
  translation: String
  value: React.ReactNode | String | null
}

export default withTranslation()((props: IShowNumberProps) => (
  <Field name={props.name} label={props.t(`${props.translation}.title`)} helperText={props.t(`${props.translation}.helper`)}>
    {() => (
      <>
        <FieldValue>{`${props.value && Number.isFinite(Number.parseFloat(`${props.value}`)) ? Number.parseFloat(`${props.value}`).toFixed(props.precision || 2) : "-"}`.replace(".", ",")}{props.value && props.postfix && <FieldPostfix>{props.postfix}</FieldPostfix>}</FieldValue>
      </>
    )}
  </Field>
))