import { DatePicker } from "@atlaskit/datetime-picker";
import { Field } from "@atlaskit/form";
import { FunctionComponent } from "react";

interface ICustomDatePickerProps {
  onChange: (value: string) => void;
  value: string;
  name: string;
  label: string;
  placeholder?: string;
}

export const CustomDatePicker: FunctionComponent<ICustomDatePickerProps> = (
  props
) => {
  const { label, name, placeholder, onChange, value } = props;

  return (
    <Field name={name} label={label}>
      {({
        fieldProps: { id, ...rest },
        error,
      }: {
        fieldProps: any;
        error: any;
      }) => (
        <DatePicker
          locale="de"
          weekStartDay={1}
          placeholder={placeholder || undefined}
          onChange={onChange}
          value={value}
        />
      )}
    </Field>
  );
};
