import { GridColumn } from "@atlaskit/page";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlantFragment } from "../../../../../api/graphql/fragments/types/PlantFragment";
import { Carrier } from "../../../../../api/graphql/queries/carriers";
import { IOrderSearchParams } from "../../types/order-search-params";
import { OrderSearchForm } from "../order-search/form/OrderSearchForm";
import { OrderView } from "../order-search/OrderView";

interface IOrderViewPageProps {
  plants: PlantFragment[];
  carriers: Carrier[];
}

export const OrderViewPage: React.FC<IOrderViewPageProps> = (props) => {
  const { plants, carriers } = props;
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useState<
    IOrderSearchParams | undefined
  >(undefined);

  return (
    <div>
      <OrderSearchForm
        plants={plants}
        carriers={carriers}
        setSearchParams={setSearchParams}
      />
      {searchParams && (
        <div style={{ paddingTop: 24 }}>
          <OrderView searchParams={searchParams} />
        </div>
      )}
      {!searchParams && (
        <div style={{ paddingTop: 24, textAlign: "center" }}>
          <GridColumn>
            <h2>{t("create_claim.no_filter_selected")}</h2>
          </GridColumn>
        </div>
      )}
    </div>
  );
};
