import styled from "styled-components"

export const Center = styled.div`  
  display: flex;
  justify-content: center;
  align-content: stretch;

  &> div {
      flex-grow: 1;
  }
`

export const CenterPage = styled.div`  
  display: flex;
  justify-content: center;
  align-content: stretch;
  height: 100vh;

  &> div {
      flex-grow: 1;
  }
`