import React from "react";
import Textfield from "@atlaskit/textfield";
import { Field, HelperMessage } from "@atlaskit/form";
import { useTranslation } from "react-i18next";

interface ICustomTextfieldProps {
  name: string;
  translation: string;
  value: string;
  onChange: (event: any) => void;
  required?: boolean;
  disabled?: boolean;
}

export const CustomTextfield: React.VFC<ICustomTextfieldProps> = (props) => {
  const { name, value, translation, onChange, disabled, required } = props;
  const { t } = useTranslation();
  const helper = t(`${translation}.helper`);

  return (
    <Field label={t(`${translation}.title`)} name={name} isRequired={required}>
      {({ fieldProps }: { fieldProps: any }) => (
        <React.Fragment>
          <Textfield
            {...fieldProps}
            defaultValue={value}
            onChange={onChange}
            isDisabled={disabled}
          />
          {helper && <HelperMessage>{helper}</HelperMessage>}
        </React.Fragment>
      )}
    </Field>
  );
};
